/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BookBorrowSettingDto,
    BookBorrowSettingDtoFromJSON,
    BookBorrowSettingDtoToJSON,
    SystemSetting,
    SystemSettingFromJSON,
    SystemSettingToJSON,
} from '../models';

export interface GetOnlineReadSametimeUsingGETRequest {
    productId: number;
}

export interface UpdateBookBorrowSettingUsingPOSTRequest {
    name?: string;
    bookBorrowSettingDto?: BookBorrowSettingDto;
}

/**
 * 
 */
export class SystemSettingControllerApi extends runtime.BaseAPI {

    /**
     * getBookBorrowSetting
     */
    async getBookBorrowSettingUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SystemSetting>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/systemSetting/get/book_borrow_setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingFromJSON(jsonValue));
    }

    /**
     * getBookBorrowSetting
     */
    async getBookBorrowSettingUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SystemSetting> {
        const response = await this.getBookBorrowSettingUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getOnlineReadSametime
     */
    async getOnlineReadSametimeUsingGETRaw(requestParameters: GetOnlineReadSametimeUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getOnlineReadSametimeUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/systemSetting/get/onlineReadSametime/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * getOnlineReadSametime
     */
    async getOnlineReadSametimeUsingGET(requestParameters: GetOnlineReadSametimeUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.getOnlineReadSametimeUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateBookBorrowSetting
     */
    async updateBookBorrowSettingUsingPOSTRaw(requestParameters: UpdateBookBorrowSettingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SystemSetting>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/systemSetting/update/book_borrow_setting`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookBorrowSettingDtoToJSON(requestParameters.bookBorrowSettingDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingFromJSON(jsonValue));
    }

    /**
     * updateBookBorrowSetting
     */
    async updateBookBorrowSettingUsingPOST(requestParameters: UpdateBookBorrowSettingUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SystemSetting> {
        const response = await this.updateBookBorrowSettingUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
