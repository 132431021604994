import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../assets/svg/back.svg";
import Button from "../../components/Button/Button";
import BookShelfItem from "../../components/CardView/BookShelf/BookShelfItem";
import {
  BorrowControllerApi,
  GetMyBookshelfUsingPOSTRequest,
} from "../../fetcher";
import { myBorrowBookList } from "../../redux/actions/myBorrowBook/myBorrowBookList";
import * as PATH from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import style from "./MyBookShelf.module.css";

function MyBookShelf(props: any) {
  const [borrowedBBook, setBorrowedBook] = useState([]);
  const navigate = useNavigate();

  function getApi() {
    let member = JSON.parse(localStorage.getItem(PATH.USER_INFO)!!);
    new BorrowControllerApi(genApiConfig())
      .getMyBookshelfUsingPOST({
        memberId: member.member.memberId,
      } as GetMyBookshelfUsingPOSTRequest)
      .then((res: any) => {
        setBorrowedBook(res.borrow);
        console.log("12341234")
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (localStorage.getItem(PATH.USER_INFO) !== null) {
      getApi()
    }
  }, []);

  return (
    <>
      <div className={style.Header}>
        <div className={style.backHomeButtonContainer}>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={(value) => navigate(-1)}
            >
              <img src={arrowBack} />
              <Trans>PreviousPage</Trans>
            </Button>
          </div>
        </div>
      </div>
      <div className={style.ContentLayout}>
        <div className={style.title}>
          {/* <h1>
            <Trans>Search</Trans>
            {`『${
              result && result.keyword === undefined ? "" : result.keyword
            }』`}{" "}
            <Trans>SearchResult</Trans>
          </h1> */}
        </div>
        <div className={style.marginTOP_10}>
          {/* <h4>共{displayData && displayData.length}個相關結果。</h4> */}
        </div>

        <div className={style.ContentItem_container}>
          {borrowedBBook &&
            borrowedBBook.map((obj: any, idx: number) => {
              return (
                <BookShelfItem
                  it={obj}
                  key={idx}
                  callBack={() => getApi()}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  // console.log("state", state);
  return {
    borrowBookList: state.myBorrowBookList,
  };
};
const actionCreator = { myBorrowBookList };
export default connect(mapStateToProps, actionCreator)(MyBookShelf);
