import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { handleLogin } from "../../redux/actions/login/login";
import { myBookMarkList } from "../../redux/actions/myBookMark/myBookMark";
import { myBorrowBookList } from "../../redux/actions/myBorrowBook/myBorrowBookList";
import { myReserveBookList } from "../../redux/actions/myReserverBook/myReserveBook";
import { Trans, useTranslation } from "react-i18next";
import SwiperCore, {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import DialogBox from "../../components/DialogBox/DialogBox";
import ReadSettingSheet from "../../components/Sheet/ReadSetting/ReadSettingSheet";
import { STYLETYPE_SECOND } from "../../utils/Contants";
import useWindowSize from "../../utils/useWindowSize";
import HonrizontalList from "../HonrizontalList/HonrizontalList";
import ReturnBook from "../ReturnBook/ReturnBook";
import TreeMeun from "../TreeMeun/TreeMeun";
import style from "./Home.module.css";

// import Timer from "../Timer/Timer";

import "../../css/navigation.min.css";
import "../../css/pagination.min.css";
import "../../css/scrollbar.min.css";
import "../../css/swiper.min.css";

import {
  BookBorrowStatusDto,
  BorrowControllerApi,
  Category,
  CategoryDto,
  DashboardControllerApi,
  GetMarketingEventByMarketingEventIdUsingGETRequest,
  GetMemberFavouritesByMemberIdUsingGETRequest,
  IntegerListRequestModel,
  ListAllMyBorrowUsingPOSTRequest,
  ListAllMyReserveBookUsingPOSTRequest,
  MarketingEventBannerDto,
  MarketingEventControllerApi,
  MarketingEventDto,
  MemberControllerApi,
  ProductBorrowStatusListUsingPOSTRequest,
  ProductDto,
} from "../../fetcher";
import * as PATH from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import { groupBy } from "../../utils/GlobalFunction";
import PageLoading from "../loading/PageLoading";
import SelectOrgs from "../SelectOrgs/SelectOrgs";
import RecommendBook from "../ReturnBook/RecommendBook";
import { Link, useNavigate } from "react-router-dom";
import Marquee from "./Marquee";

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

interface IBannerDto extends MarketingEventBannerDto {
  bannerPosition?: number;
  bannerLinkTo?: string;
}

// export default
function Home(props: any) {
  const { i18n } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [apiData, setApiData] = useState<any>([]);
  const [displayCateData, setDisplayCateData] = useState<any>([]);
  const [recommendations, setRecommendations] = useState<any>([]);
  const [returnBook, setReturnBook] = useState<any>([]);
  const [topTenBook, setTopTenBook] = useState<any>([]);
  const [randBook, setRandBook] = useState<any>([]);
  const [leftBanner, setLeftBanner] = useState<Array<IBannerDto>>();
  const [rightBanner, setRightBanner] = useState<Array<IBannerDto>>();
  const [eventData, setEventData] = useState<Array<MarketingEventDto>>();
  const { width, height } = useWindowSize();
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoading_Banner, setIsLoading_Banner] = useState(true);
  const [isLoading_Category, setIsLoading_Category] = useState(true);
  const [isLoading_Recommendations, setIsLoading_Recommendations] =
    useState(true);
  const [isLoading_ReturnBook, setIsLoading_ReturnBook] = useState(true);
  const [isLoading_TopTenBorrow, setIsLoading_TopTenBorrow] = useState(true);
  const [isLoading_RandBook, setIsLoading_RandBook] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // if (
  //   //   localStorage.getItem("savedUrl") !== null &&
  //   //   localStorage.getItem("savedUrl") != "null" &&
  //   //   localStorage.getItem("savedUrl") !== window.location.href
  //   // ) {
  //   //   window.location.href = localStorage.getItem("savedUrl") as string;
  //   // }
  //   if (isLoading) {

  //   }
  // }, [isLoading]);

  useEffect(() => {
    let loginRedirect = localStorage.getItem(PATH.LOGIN_REDIRECT);
    // console.log("loginRedirectloginRedirectloginRedirect", loginRedirect)
    localStorage.removeItem(PATH.LOGIN_REDIRECT)
    const userInfo = localStorage.getItem("userInfo");
    const userInfoObj = JSON.parse(userInfo!!);
    if (userInfoObj !== null && loginRedirect) {
      // console.log("userInfoObj !== null && loginRedirect")
      navigate(loginRedirect);
    }
    if (
      process.env.REACT_APP_WEB_NAME === "sueplibrary" &&
      !(
        window.location.host === "sueplib.octopus-tech.com" ||
        window.location.host.includes("hkpl") ||
        process.env.REACT_APP_CLIENT_ID === "hkpl"
      )
    ) {
      let selectedSchool = localStorage.getItem("savedSchool");
      if (selectedSchool === null) {
        if (!process.env.REACT_APP_DEBUG) {
          window.location.href = "/selectOrg";
        }
      }
    }
    if (localStorage.getItem(PATH.USER_INFO) !== null) {
      let member = JSON.parse(localStorage.getItem(PATH.USER_INFO)!!);

      if (!member.isAdmin) {
        new BorrowControllerApi(genApiConfig())
          .listAllMyReserveBookUsingPOST({
            memberId: member.member.memberId,
          } as ListAllMyReserveBookUsingPOSTRequest)
          .then((data: any) => {
            props.myReserveBookList(data);
          });
        new BorrowControllerApi(genApiConfig())
          .listAllMyBorrowUsingPOST({
            memberId: member.member.memberId,
            pageNo: 0,
            pageSize: 50,
          } as ListAllMyBorrowUsingPOSTRequest)
          .then((data: any) => {
            props.myBorrowBookList(data.content);
          });
        //console.log("getMemberFavouritesByMemberIdUsingGET.before");
        new MemberControllerApi(genApiConfig())
          .getMemberFavouritesByMemberIdUsingGET({
            memberId: member.member.memberId,
          } as GetMemberFavouritesByMemberIdUsingGETRequest)
          .then((data: Array<ProductDto>) => {
            console.log("getMemberFavouritesByMemberIdUsingGET", data);
            props.myBookMarkList(data);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (isLoading_Banner) {
      new DashboardControllerApi(genApiConfig())
        .getDashboardMarketingEventUsingGET()
        .then(async (eventResponse: Array<MarketingEventDto>) => {
          setEventData(eventResponse);
          return await eventResponse;
        })
        .then((eventResponse) => {
          new DashboardControllerApi(genApiConfig())
            .getDashboardBannerUsingGET()
            .then(async (bannerResponse: Array<MarketingEventBannerDto>) => {
              const groupedEvent = groupBy(eventResponse, "bannerPosition");

              for (const key in groupedEvent) {
                let eventList = groupedEvent[key] as MarketingEventDto[];

                if (key === "1") {
                  let leftBannerList = [] as IBannerDto[];
                  eventList.map((event) => {
                    let newObj = {} as IBannerDto;
                    let bannerObj = bannerResponse.find(
                      (banner: any) =>
                        banner.marketingEventId === event.marketingEventId
                    );

                    newObj.bannerId = bannerObj?.bannerId;
                    newObj.bannerLinkTo = event.bannerLinkTo;
                    newObj.bannerPosition = event.bannerPosition;
                    newObj.content = bannerObj?.content;
                    newObj.marketingEventId = event.marketingEventId;
                    newObj.marketingEventName = event.marketingEventName;
                    newObj.mobileContent = bannerObj?.mobileContent;
                    newObj.position = bannerObj?.position;
                    leftBannerList.push(newObj);
                  });
                  await setLeftBanner(leftBannerList);
                }

                if (key === "2") {
                  let rightBannerList = [] as IBannerDto[];
                  eventList.map((event) => {
                    let newObj = {} as IBannerDto;
                    let bannerObj = bannerResponse.find(
                      (banner: any) =>
                        banner.marketingEventId === event.marketingEventId
                    );
                    newObj.bannerId = bannerObj?.bannerId;
                    newObj.bannerLinkTo = event.bannerLinkTo;
                    newObj.bannerPosition = event.bannerPosition;
                    newObj.content = bannerObj?.content;
                    newObj.marketingEventId = event.marketingEventId;
                    newObj.marketingEventName = event.marketingEventName;
                    newObj.mobileContent = bannerObj?.mobileContent;
                    newObj.position = bannerObj?.position;
                    rightBannerList.push(newObj);
                  });
                  await setRightBanner(rightBannerList);
                }
              }
            });
        });
    }
    setIsLoading_Banner(false);
  }, [isLoading_Banner]);

  useEffect(() => {
    if (isLoading_Category) {
      new DashboardControllerApi(genApiConfig())
        .getDashboardSortUsingGET()
        //.getDashboardCategoryUsingGET()
        .then((res) => {
          // console.log("category", res);
          setDisplayCateData(res);
        });
    }
    setIsLoading_Category(false);
  }, [isLoading_Category]);

  useEffect(() => {
    if (isLoading_Recommendations) {
      new DashboardControllerApi(genApiConfig())
        .getDashboardRecommendCategoryUsingGET()
        .then((res) => {
          var productIds: Array<any> = [];
          var tempRecommendations = res;
          res.map((value: any) =>
            value.products?.map((value2: any) =>
              productIds.push(value2.productId)
            )
          );
          var productIdList: IntegerListRequestModel = { integers: productIds };
          var req: ProductBorrowStatusListUsingPOSTRequest = {
            integerListRequestModel: productIdList,
          };
          new BorrowControllerApi(genApiConfig())
            .productBorrowStatusListUsingPOST(req)
            .then((res: Array<BookBorrowStatusDto>) => {
              const newRecommendation = tempRecommendations.map(
                (recommendObj: any) => {
                  const newProductList = recommendObj.products.map(
                    (productItem: any) => {
                      const tempData = res.find(
                        (el) => el.productId === productItem.productId
                      );
                      if (tempData !== undefined) {
                        productItem["borrowStatus"] = tempData;
                      } else {
                        productItem["borrowStatus"] = {};
                      }
                      return productItem;
                    }
                  );
                  Object.assign({ ...recommendObj, products: newProductList });
                  return recommendObj;
                }
              );
              setRecommendations(newRecommendation);
            });
        });
    }
    setIsLoading_Recommendations(false);
  }, [isLoading_Recommendations]);

  useEffect(() => {
    if (isLoading_ReturnBook) {
      new DashboardControllerApi(genApiConfig())
        .getDashboardReturnBookUsingGET()
        .then((res) => {
          setReturnBook(res);
        });
    }
    setIsLoading_ReturnBook(false);
  }, [isLoading_ReturnBook]);

  useEffect(() => {
    if (isLoading_TopTenBorrow) {
      new DashboardControllerApi(genApiConfig())
        .getDashboardTopTenBorrowBookUsingGET()
        .then((res) => {
          setTopTenBook(res);
        });
    }
    setIsLoading_TopTenBorrow(false);
  }, [isLoading_TopTenBorrow]);

  useEffect(() => {
    if (isLoading_RandBook) {
      new DashboardControllerApi(genApiConfig())
        .getDashboardRandBookUsingGET()
        .then((res) => {
          setRandBook(res);
        });
    }
    setIsLoading_RandBook(false);
  }, [isLoading_RandBook]);

  const handleImageLinkTo = (bannerItem: MarketingEventBannerDto) => {
    new MarketingEventControllerApi(genApiConfig())
      .getMarketingEventByMarketingEventIdUsingGET({
        marketingEventId: bannerItem.marketingEventId,
      } as GetMarketingEventByMarketingEventIdUsingGETRequest)
      .then((res: MarketingEventDto) => {
        if (res.bannerLinkTo !== "") {
          if (res.bannerLinkTo?.substring(0, 4) === "http") {
            window.open(res.bannerLinkTo, "_blank");
          } else {
            //alert("地址無效或不存在");
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {
        // window.location.host.split(".").length <= 1? <SelectOrgs /> : <>
        window.location.host.split(".").length <= 1 &&
          // (localStorage.getItem("savedUrl") === null ||
          //   localStorage.getItem("savedUrl") === "null") &&
          localStorage.getItem("isPhone") === "true" ? (
          <SelectOrgs />
        ) : (
          <>
            <Marquee />
            <div className={style.homeContainer}>
              {/* {isLoading ? (
                <>
                  <PageLoading />
                </>
              ) : ( */}
              {/* <> */}
              <div className={style.homeInnerContainer}>
                {isLoading_Banner ? (
                  <PageLoading />
                ) : (
                  <div className={style.bannerContainer}>
                    <div className={style.mainBannerContainer}>
                      <Swiper
                        className={style.swiper_banner_part}
                        direction={"horizontal"}
                        loop={true}
                        pagination={{
                          clickable: true,
                        }}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: false,
                        }}
                      >
                        {leftBanner?.map((t) => (
                          <SwiperSlide key={"banner-" + t.bannerId}>
                            <div
                              className={[
                                style.swiper_banner_container,
                                style.no_hand,
                              ].join(" ")}
                            >
                              {t.content !== "" ? (
                                <>
                                  <img
                                    className={style.swiper_image}
                                    src={`${process.env.REACT_APP_BACKEND}/file/${t.content}`}
                                    onClick={() => handleImageLinkTo(t)}
                                  />
                                </>
                              ) : t.mobileContent !== "" ? (
                                <>
                                  <img
                                    className={style.swiper_image}
                                    src={`${process.env.REACT_APP_BACKEND}/file/${t.mobileContent}`}
                                    onClick={() => handleImageLinkTo(t)}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    {isLoading_Recommendations ? (
                      <>
                        <PageLoading />
                      </>
                    ) : (
                      <div
                        className={
                          width < 768
                            ? style.recommendationContainer2
                            : style.recommendationContainer
                        }
                      >
                        {recommendations &&
                          recommendations.map((books: any, idx: number) => (
                            <HonrizontalList props={books} key={idx} />
                          ))}
                      </div>
                    )}
                  </div>
                )}

                {/* {isLoading ? (
                  <></>
                ) : (
                  <> */}
                <div
                  className={
                    width < 768 ? style.homeContent2 : style.homeContent
                  }
                >
                  {rightBanner ? (
                    <div className={style.secondBannerContainer}>
                      <Swiper
                        className={style.swiper_banner_part}
                        direction={"horizontal"}
                        loop={true}
                        pagination={{
                          clickable: true,
                        }}
                        autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }}
                      >
                        {rightBanner?.map((t) => (
                          <SwiperSlide key={"banner-" + t.marketingEventId}>
                            <div
                              className={[
                                style.swiper_banner_container,
                                style.no_hand,
                              ].join(" ")}
                            >
                              {t.content !== "" ? (
                                <>
                                  <img
                                    className={style.swiper_image}
                                    src={`${process.env.REACT_APP_BACKEND}/file/${t.content}`}
                                    onClick={() => handleImageLinkTo(t)}
                                  />
                                </>
                              ) : t.mobileContent !== "" ? (
                                <div>
                                  <img
                                    className={style.swiper_image}
                                    src={`${process.env.REACT_APP_BACKEND}/file/${t.mobileContent}`}
                                    onClick={() => handleImageLinkTo(t)}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className={style.sideNavbar}>
                    {/* 熱門借閱 */}
                    {isLoading_TopTenBorrow ? (
                      <>
                        <PageLoading />
                      </>
                    ) : (
                      <div
                        className={
                          rightBanner
                            ? style.TreeMeunContainer
                            : style.TreeMeunContainer2
                        }
                      >
                        {topTenBook.length > 0 ? (
                          <>
                            <div className={style.header}>
                              <h3>
                                <Trans>PopularBorrow</Trans>
                              </h3>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {topTenBook.map((item: any, idx: number) => (
                          <RecommendBook props={item} idx={idx} key={idx} />
                        ))}
                      </div>
                    )}

                    {/* 隨機小驚喜 */}
                    {isLoading_RandBook ? (
                      <>
                        <PageLoading />
                      </>
                    ) : (
                      <div className={style.TreeMeunContainer}>
                        {randBook.length > 0 ? (
                          <>
                            <div className={style.header}>
                              <h3>
                                <Trans>RandomSurprise</Trans>
                              </h3>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {randBook.map((item: any, idx: number) => (
                          <RecommendBook props={item} idx={idx} key={idx} />
                        ))}
                      </div>
                    )}

                    {/* 最近歸還 */}
                    {isLoading_ReturnBook ? (
                      <>
                        <PageLoading />
                      </>
                    ) : (
                      <div className={style.TreeMeunContainer}>
                        {returnBook.length > 0 ? (
                          <>
                            <div className={style.header}>
                              <h3>
                                <Trans>ReturnBook</Trans>
                              </h3>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {returnBook.map((item: any, idx: number) => (
                          <ReturnBook props={item} idx={idx} key={idx} />
                        ))}
                      </div>
                    )}

                    {/* 書本分類 */}
                    {isLoading_Category ? (
                      <>
                        <PageLoading />
                      </>
                    ) : (
                      <div className={style.TreeMeunContainer}>
                        <div className={style.header}>
                          {displayCateData.length > 0 ? (
                            <>
                              <h3>
                                <Trans>Bookcategory</Trans>
                              </h3>
                              <Link to={"/search/result?inputOption=all"}>
                                <div style={{ marginRight: "1rem" }}>
                                  <Trans>all</Trans>
                                </div>
                              </Link>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        {displayCateData &&
                          displayCateData.map((item: any, idx: any) => (
                            <TreeMeun props={item} key={idx} />
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                {/* </>
                )} */}
              </div>
              {/* </> */}
              {/* )} */}
            </div>

            <DialogBox
              styleType={STYLETYPE_SECOND}
              showDialog={showDeleteDialog}
              title={"6565465465"}
            >
              <ReadSettingSheet
                callBack={() => console.log(1)}
              ></ReadSettingSheet>
            </DialogBox>
          </>
        )
      }
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    borrowBookList: state.myBorrowBookList,
    reserveBookList: state.myReserveBookList,
    auth: state.login.auth,
    bookMarkList: state.myBookMarkList,
  };
};
const actionCreator = {
  myBorrowBookList,
  handleLogin,
  myReserveBookList,
  myBookMarkList,
};

export default connect(mapStateToProps, actionCreator)(Home);
