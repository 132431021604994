import { Fragment, useEffect, useState } from "react";
// import { withRouter } from "react-router-dom";
import BackButton from "../../../components/Button/BackButton/BackButton";
// import { OrganizationApi, RoleApi } from "../../../../fetcher";
// import UserApi from "../../../../fetcher/api/UserApi";
// import OrganizationIdDto from "../../../../fetcher/model/OrganizationIdDto";
// import UserDto from "../../../../fetcher/model/UserDto";
// import { handleUserInfo } from "../../../../redux/actions";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import listViewIcon from "../../../assets/svg/list_view.svg";
import titleViewIcon from "../../../assets/svg/title_view.svg";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import Input from "../../../components/Input/Input";
import UserSheet from "../../../components/Sheet/User/UserSheet";
import UserTableView from "../../../components/TableListView/User/UserTableView";
import { MemberControllerApi, MemberDto } from "../../../fetcher";
import {
    ADD,
    ALL_BATCH,
    CHECKBOX,
    CODE_USER_ADD,
    DELETE,
    DIALOG_CANCEL,
    DIALOG_COMPLETE,
    DIALOG_DELETE,
    EDIT,
    LOGIN_PATH,
    STYLETYPE_DEFAULT,
    STYLETYPE_FIFTH,
    STYLETYPE_IMG,
    STYLETYPE_MAIN,
    STYLETYPE_SECOND,
    STYLE_CARD,
    STYLE_LIST,
} from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import PageLoading from "../../loading/PageLoading";
import style from "./UsersView.module.css";
import * as Storage from "../../../utils/local-storage";
export interface IUserDto extends MemberDto {
    isChecked: boolean;
    isSearch: boolean;
    password?: string;
}

const UsersView = (props: any) => {
    const [displayData, setDisplayData] = useState([]);
    const [styleType, setStyleType] = useState(STYLE_LIST);
    const [searchText, setSearchText] = useState("");

    const [deleteAll, setDeleteAll] = useState(false);

    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [userData, setUserData] = useState<Array<IUserDto>>();
    const [currentSelectedUser, setCurrentSelectedUser] = useState<IUserDto>();

    const navigate = useNavigate();

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(LOGIN_PATH)}</>;
        }
        getApiData();
    }, []);

    const getApiData = () => {
        setLoadingData(true);
        new MemberControllerApi(genApiConfig())
            .getAllMemberUsingGET()
            .then((res: MemberDto[]) => {
                let newRes = res.map((user) => {
                    return Object.assign({
                        ...user,
                        isChecked: false,
                        isSearch: true,
                    });
                });
                setUserData(newRes);
            })
            .catch((err) => console.log("err", err));
        setLoadingData(false);
    };

    //Select All Radio
    const tableHeaderCheck = (value: any) => {
        let newUserData = userData?.map((user) => {
            user.isChecked = value;
            return user;
        });
        setUserData(newUserData);
    };

    const handleDeleteClick = (value: any) => {};

    const handleBatchClick = (value: any) => {
        navigate(ALL_BATCH);
    };

    const handleAddUserClick = (value: any) => {
        setShowAddUserDialog(value);
    };

    const handleListViewClick = (value: any) => {
        setStyleType(STYLE_LIST);
    };

    const handleCardViewClick = (value: any) => {
        setStyleType(STYLE_CARD);
    };

    const tableItemAction = (action: string, obj: IUserDto) => {
        switch (action) {
            case CHECKBOX:
                let newUserData = userData?.map((user) => {
                    if (user.memberId === obj.memberId) {
                        user.isChecked = !user.isChecked;
                    }
                    return user;
                });
                setUserData(newUserData);
                break;
            case EDIT:
                setCurrentSelectedUser(obj);
                setShowEditDialog(true);
                break;
            case DELETE:
                setCurrentSelectedUser(obj);
                setShowDeleteDialog(true);
                break;
        }
    };

    //Search
    const handleSearchClick = (value: any) => {
        let newUserData = null;

        if (searchText === "") {
            newUserData = userData?.map((user) => {
                user.isSearch = true;
                return user;
            });
        } else {
            newUserData = userData?.map((user) => {
                if (user.nameChi?.includes(searchText) || user.nameEng?.toUpperCase().includes(searchText.toUpperCase())) {
                    user.isSearch = true;
                } else {
                    user.isSearch = false;
                }
                return user;
            });
        }
        setUserData(newUserData);
        // setDisplayData(users.filter((userObj) => userObj.name.toUpperCase().includes(searchText.toUpperCase())));
    };

    const handleDialogCallBack = (value: any) => {
        if (value.action == DIALOG_COMPLETE) {
        }

        // if (value.action == DIALOG_DELETE) deleteUserAction();

        setCurrentSelectedUser({} as IUserDto);
        setShowAddUserDialog(false);
        setShowEditDialog(false);
        setShowDeleteDialog(false);
        setDeleteAll(false);
        getApiData();
    };

    const deleteUserAction = () => {
        setLoading(true);
        if (currentSelectedUser && currentSelectedUser.memberId)
            new MemberControllerApi(genApiConfig())
                .updateMemberUsingPATCH1({ memberId: currentSelectedUser.memberId, expireDate: "", dbDelete: true })
                .then((res) => {
                    if (res.success) {
                        alert("刪除成功");
                        handleDialogCallBack({ action: DIALOG_DELETE });
                    } else {
                        alert("刪除失敗");
                    }
                })
                .catch((err: any) => {
                    console.log("err", err);
                });
        setLoading(false);
    };

    const onChangeRadio = (elem: any) => {};

    return (
        <Fragment>
            <div className={style.backbtn}>
                <BackButton />
            </div>

            <div className={style.usersViewContainer}>
                <div className={style.usersVC_header}>
                    <h4
                        style={{
                            display: "flex",
                        }}
                    >
                        <span
                            style={{
                                flex: "0 0 auto",
                            }}
                        >
                            用戶管理
                        </span>
                    </h4>
                    <div className={style.usersViewHeader}>
                        <div className={style.usersViewRow}>
                            <Input type="text" placeholder={"輸入名稱"} value={searchText} styleType={STYLETYPE_FIFTH} callBack={(value) => setSearchText(value)} />
                            <div className={style.usersViewRowBtn}>
                                <Button styleType={STYLETYPE_MAIN} callBack={(value) => handleSearchClick(value)}>
                                    搜尋
                                </Button>
                            </div>
                        </div>

                        <div className={style.usersViewRowSpan}>
                            {/* <div className={style.usersViewRowSpanItem}>
                <Button
                  styleType={STYLETYPE_IMG}
                  callBack={(value) => handleListViewClick(value)}
                >
                  <img src={listViewIcon} />
                </Button>
              </div>
              <div className={style.usersViewRowSpanItem}>
                <Button
                  styleType={STYLETYPE_IMG}
                  callBack={(value) => handleCardViewClick(value)}
                >
                  <img src={titleViewIcon} />
                </Button>
              </div>

              <div className={style.border_line}></div> */}

                            <div className={style.usersViewRowSpanItem}>
                                <Button
                                    styleType={STYLETYPE_SECOND}
                                    code={CODE_USER_ADD}
                                    //hidden={showType === INTERNAL ? false : true}
                                    callBack={(value) => handleBatchClick(value)}
                                >
                                    <Trans>batchupload</Trans>
                                </Button>
                            </div>
                            <div className={style.border_line}></div>
                            <div className={style.usersViewRowSpanItem}>
                                <Button
                                    styleType={STYLETYPE_SECOND}
                                    code={CODE_USER_ADD}
                                    //hidden={showType === INTERNAL ? false : true}
                                    callBack={(value) => handleAddUserClick(value)}
                                >
                                    新增用戶
                                </Button>
                            </div>
                            {/* <div className={style.border_line} hidden={displayData && !displayData.filter((it) => it.isChecked).length > 0}></div>
                            {displayData.filter((it) => it.isChecked).length > 0 ? (
                                <div className={style.usersViewRowSpanItem}>
                                    <Button styleType={STYLETYPE_MAIN} code={CODE_USER_DELETE} callBack={(value) => handleDeleteClick(value)}>
                                        刪除用戶
                                    </Button>
                                </div>
                            ) : (
                                <></>
                            )} */}
                        </div>
                    </div>

                    {/* Table Header */}
                    {styleType === STYLE_LIST ? (
                        <>
                            <div className={style.tableHeader}>
                                <div className={style.tableHeaderItemCol1}>
                                    <Input
                                        type="checkbox"
                                        styleType={STYLETYPE_DEFAULT}
                                        checked={userData?.filter((user) => user.isChecked).length === userData?.length}
                                        callBack={(value) => tableHeaderCheck(value)}
                                    />
                                </div>
                                <div className={style.tableHeaderItemCol2}>
                                    <h4>英文名稱</h4>
                                </div>
                                <div className={style.tableHeaderItem}>
                                    <h4>中文名稱</h4>
                                </div>
                                <div className={style.tableHeaderItem}>
                                    <h4>班級</h4>
                                </div>
                                <div className={style.tableHeaderItem}>
                                    <h4>班號</h4>
                                </div>
                                <div className={style.tableHeaderItem}>
                                    <h4>操作</h4>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            {/* Table Content */}
            {loadingData ? (
                <>
                    <PageLoading />
                </>
            ) : userData?.length === 0 || userData?.filter((user) => !user.isSearch).length === userData?.length ? (
                <>沒有記錄</>
            ) : (
                <>
                    <div style={{ padding: "8px" }}>
                        {styleType === STYLE_LIST ? (
                            <>
                                <div className={style.dataContentView}>
                                    {userData
                                        ?.filter((user) => user.isSearch)
                                        .map((user) => {
                                            return (
                                                <Fragment key={user.memberId}>
                                                    <UserTableView
                                                        showType="LIST"
                                                        content={user}
                                                        checked={user.isChecked}
                                                        callBack={(obj, action) => tableItemAction(action, obj)}
                                                    />
                                                </Fragment>
                                            );
                                        })}
                                </div>
                            </>
                        ) : (
                            <div className={style.cardViewContainer}>
                                {userData
                                    ?.filter((user) => user.isSearch)
                                    .map((user) => {
                                        return (
                                            <Fragment key={user?.memberId}>
                                                <UserTableView showType="CARD" content={user} checked={user?.isChecked} callBack={(obj, action) => tableItemAction(action, obj)} />
                                            </Fragment>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                </>
            )}

            {/* Add User*/}
            <DialogBox styleType={STYLETYPE_MAIN} showDialog={showAddUserDialog} title={"新增用戶資料"}>
                <UserSheet content={{} as IUserDto} actionType={ADD} callBack={(value) => handleDialogCallBack(value)} />
            </DialogBox>

            {/* Edit */}
            <DialogBox styleType={STYLETYPE_MAIN} showDialog={showEditDialog} title={"更新用戶資料"}>
                <UserSheet content={currentSelectedUser} actionType={EDIT} callBack={(value) => handleDialogCallBack(value)} />
            </DialogBox>

            {/* Delete */}
            <DialogBox styleType={STYLETYPE_MAIN} showDialog={showDeleteDialog} title={"刪除用戶資料"}>
                <div>
                    <label>是否刪除以下用戶資料?</label>
                    <div style={{ position: "absolute", bottom: "1rem", right: "1rem", display: "flex", width: "50%" }}>
                        <Button callBack={() => handleDialogCallBack({ action: DIALOG_CANCEL })} styleType={STYLETYPE_MAIN}>
                            取消
                        </Button>
                        <Button callBack={() => deleteUserAction()} styleType={STYLETYPE_MAIN}>
                            確定
                        </Button>
                    </div>
                    <br />
                </div>
            </DialogBox>
        </Fragment>
    );
};

export default UsersView;
