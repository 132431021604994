export const HOME_PATH = "/";
export const Test_PATH = "/test";
export const LOGIN_PATH = "/auth/login";
export const FRIST_PATH = "/frist";
export const SECOND_PATH = "/second";
export const THRID_PATH = "/third";
export const APP_PATH = "/app";
export const USAGE_PATH = "/usage";
export const LANGUAGE_PATH = "/language";
export const SEARCH_PATH = "/search";
export const SEARCH_RESULT_PATH = "/search/result";
export const FORGET = "/forget";
export const ADMIN = "/admin";
export const PLATFORM = "/admin/platform";
export const STUDENT = "/profile";
export const MYBOOKSHELF = "/profile/mybookshelf";
export const PURCHASED = "/admin/purchasedbook";
export const BOOKLIST = "/admin/booklist";
export const USERSETTING = "/admin/usersetting";
export const PAGE_SETTING = "/admin/pagesetting";
export const LENDSETTING = "/admin/lendsetting";
export const ACTIVITYRECORD = "/admin/activityrecord";
export const BANNER_MANAGEMENT = "/admin/banner/management";
export const BANNER = "/admin/banner";
export const EDITBANNER = "/admin/edit_banner";
export const ADDBANNER = "/admin/add_banner";
export const MARKETING_EVENT_MANAGEMENT = "/admin/event/management";
export const MARKETING_EVENT = "/admin/event";
export const ALL_BATCH = "/batch";
export const ADD_BATCH = "/batch/add";
export const UPDATE_BATCH = "/batch/update";
export const DELETE_BATCH = "/batch/delete";
export const BATCH_DETAIL = "/batch/detail";
export const ERROR_PAGE_PATH = "/errorPage";

export const THEMATIC = "/info/thematic";
export const NEWS = "/info/news";
export const APPDOWNLOAD = "/info/app_download";
export const MANUAL = "/info/manual";
export const ACTIVITY = "/info/activity";
export const APPNEWS = "/info/app_news";
export const ABOUTS = "/info/abouts";
export const MEDIA = "/info/media";
export const FBCLUB = "/info/fbclub";
export const INFORMATION = "/info/information";
export const POLICY = "/info/policy";
export const READREPORT = "/report/read";
export const BORROWREPORT = "/report/borrow";
export const BOOKREPORT = "/report/book";

//#region Contorl
export const ADD = "add";
export const UPDATE = "update";
export const DELETE = "delete";
export const EDIT = "edit";
export const SELECT = "select";
export const COPY = "copy";
export const CHECKBOX = "checkbox";
export const CHANGE = "change";
export const CANCEL = "cancel";
export const SAVE = "save";
export const ALL = "all";
//#endregion

//#region session storage
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const USER_INFO = "userInfo";
export const CLIENT_INFO = "clientInfo";
//#endregion

//#region Display Style
export const STYLE_LIST = "list_style";
export const STYLE_CARD = "list_card";

//#region AuthApi
export const EMAIL_SENT = "Email Sent";
export const EMAIL_CHECK = "Please Check email";
//#endregion

//#region Component
export const CARDVIEW = "Card View";
export const LISTVIEW = "List View";
//#endregion

//#region Button / INPUT / SELECT OPTION
export const STYLETYPE_DEFAULT = "default";
export const STYLETYPE_MAIN = "Main";
export const STYLETYPE_SECOND = "Second";
export const STYLETYPE_THIRD = "Third";
export const STYLETYPE_FOUR = "Four";
export const STYLETYPE_FIFTH = "Fifth";
export const STYLETYPE_SIX = "Six";
export const STYLETYPE_SEVEN = "Seven";
export const STYLETYPE_EIGHT = "Eight";
export const STYLETYPE_NINE = "Nine";
export const STYLETYPE_IMG = "Img";
export const STYLETYPE_CUSTOM = "custom";
export const STYLETYPE_SELECT_FILE = "file";
export const STYLETYPE_DATALIST = "datalist";
export const STYLETYPE_SEARCH_INPUT = "search_input";
export const STYLETYPE_SEARCH_INPUT_OPTION = "search_input_option";
//#endregion

export const TEMP = "TEMP";
export const SUBMITTED = "SUBMITTED";
export const APPROVED = "APPROVED";
export const REJECTED = "REJECTED";
export const AVAILABLE = "AVAILABLE";
export const UNAVAILABLE = "UNAVAILABLE";

// #region Date Pattern
export const FULLDATE = "YYYY-MM-DD";
export const YEARMONTH = "YYYY-MM";
export const DATETIME = "YYYY-MM-DD HH:mm";
export const FULLDATETIME = "YYYY-MM-DD HH:mm:ss";
// #endregion

//#region Dialog Action
export const DIALOG_CANCEL = "Cancel";
export const DIALOG_COMPLETE = "Complete";
export const DIALOG_DELETE = "Delete";
export const DIALOG_EDIT = "Edit";
//#endregion

//#region Permission Code
export const CODE_ADD_USER = "ADD_USER";
export const CODE_DELETE_USER = "DELETE_USER";
export const CODE_UPDATE_USER = "UPDATE_USER";

//#region File
export const CHUNK_SIZE = 10 * 1024 * 1024;
export const MAX_REQUEST_NUM = 6;
export const MAX_RETRY_NUM = 3;
export const TEL = 35686915;
//#endregion

//#region localStorage
export const AES_KEY = "16GIdoWGkFXKv7bKGmC06lOQXP6xcpxd";
export const AES_SPACE =
  "jADKYDpblHBspyJcR2XBw3e8HosyomLZitWBAZe3ciRweOjO3qkhiDFYTSl2bxgzUJySwl5oBb0tjzGy9ZTVM56ui8YKVh2pQ6yYAVCP0mEJxFqCidanu5vSqXJ6sUGy";
export const LAST_MSG = "lastMsg";
export const SSO_INFO = "ssoInfo";
export const ADMIN_MEMBER = "adminMember";
export const MEMBER = "member";
//#endregion

//#region Permission Code
export const CODE_USER_LIST = "USER_LIST";
export const CODE_USER_ADD = "USER_ADD";
export const CODE_USER_UPDATE = "USER_UPDATE";
export const CODE_USER_DELETE = "USER_DELETE";
export const CODE_NO_NEED_AUTH = "VALID";

// export const HKPL_SSO_PATH = `https://uatepas.hkpl.gov.hk/sso/oauth2/authorize?response_type=code%20token%20id_token&client_id=EV-Ebook-660&realm=%2F&scope=openid%20profile&redirect_uri=https://hkpl.nblib.com&nonce=n-0S6_WzA2Mj&acr_values=epas&state=epasslogin`;
export const HKPL_SSO_PATH = `http://8.134.95.213:8182/porsso-a2/realms/hkpl/protocol/openid-connect/auth?client_id=EV-Ebook-660&redirect_uri=https://uatlib.nblib.com&response_type=code%20token%20id_token&nonce=n-0S6_WzA2Mj`;
export const EPUB = "epub";
export const PDF = "pdf";
export const MP3 = "mp3";
export const MP4 = "mp4";
export const SRT = "srt";
export const LOGIN_REDIRECT = "loginRedirect";
