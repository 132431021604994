/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface GetBirthdayDiscountUsingGETRequest {
    name?: string;
}

export interface GetBusinessReportUsingGETRequest {
    name?: string;
}

export interface GetCashCouponUsingGETRequest {
    name?: string;
}

export interface GetCodeCouponUsingGETRequest {
    name?: string;
}

export interface GetConsumptionExcelUsingGETRequest {
    name?: string;
}

export interface GetInventoryBalanceExcelFileUsingGETRequest {
    name?: string;
}

export interface GetMarcExcelFileUsingGETRequest {
    name?: string;
}

export interface GetMemberFileUsingGETRequest {
    name?: string;
}

export interface GetMemberPointExcelUsingGETRequest {
    name?: string;
}

export interface GetMemberUpgradeFileBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetNewMemberExcelFileBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetPointGiftExcelUsingGETRequest {
    name?: string;
}

export interface GetPointToCashUsingGETRequest {
    name?: string;
}

export interface GetPointToGiftBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetProductInventoryExcelFileBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetProductSalesBetweenDate2UsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetProductSalesBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

/**
 * 
 */
export class ExcelControllerApi extends runtime.BaseAPI {

    /**
     * getBirthdayDiscount
     */
    async getBirthdayDiscountUsingGETRaw(requestParameters: GetBirthdayDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadBirthdayDiscountExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getBirthdayDiscount
     */
    async getBirthdayDiscountUsingGET(requestParameters: GetBirthdayDiscountUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getBirthdayDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBusinessReport
     */
    async getBusinessReportUsingGETRaw(requestParameters: GetBusinessReportUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadBusinessExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getBusinessReport
     */
    async getBusinessReportUsingGET(requestParameters: GetBusinessReportUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getBusinessReportUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCashCoupon
     */
    async getCashCouponUsingGETRaw(requestParameters: GetCashCouponUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadCashCouponExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getCashCoupon
     */
    async getCashCouponUsingGET(requestParameters: GetCashCouponUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getCashCouponUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCodeCoupon
     */
    async getCodeCouponUsingGETRaw(requestParameters: GetCodeCouponUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadCodeCouponExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getCodeCoupon
     */
    async getCodeCouponUsingGET(requestParameters: GetCodeCouponUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getCodeCouponUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getConsumptionExcel
     */
    async getConsumptionExcelUsingGETRaw(requestParameters: GetConsumptionExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadConsumptionExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getConsumptionExcel
     */
    async getConsumptionExcelUsingGET(requestParameters: GetConsumptionExcelUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getConsumptionExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInventoryBalanceExcelFile
     */
    async getInventoryBalanceExcelFileUsingGETRaw(requestParameters: GetInventoryBalanceExcelFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadInventoryBalanceExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getInventoryBalanceExcelFile
     */
    async getInventoryBalanceExcelFileUsingGET(requestParameters: GetInventoryBalanceExcelFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getInventoryBalanceExcelFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMarcExcelFile
     */
    async getMarcExcelFileUsingGETRaw(requestParameters: GetMarcExcelFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadMarcExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMarcExcelFile
     */
    async getMarcExcelFileUsingGET(requestParameters: GetMarcExcelFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMarcExcelFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberFile
     */
    async getMemberFileUsingGETRaw(requestParameters: GetMemberFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadMemberExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMemberFile
     */
    async getMemberFileUsingGET(requestParameters: GetMemberFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMemberFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberPointExcel
     */
    async getMemberPointExcelUsingGETRaw(requestParameters: GetMemberPointExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadMemberPointsExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMemberPointExcel
     */
    async getMemberPointExcelUsingGET(requestParameters: GetMemberPointExcelUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMemberPointExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberUpgradeFileBetweenDate
     */
    async getMemberUpgradeFileBetweenDateUsingGETRaw(requestParameters: GetMemberUpgradeFileBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getMemberUpgradeFileBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getMemberUpgradeFileBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadMemberUpgradeExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMemberUpgradeFileBetweenDate
     */
    async getMemberUpgradeFileBetweenDateUsingGET(requestParameters: GetMemberUpgradeFileBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMemberUpgradeFileBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getNewMemberExcelFileBetweenDate
     */
    async getNewMemberExcelFileBetweenDateUsingGETRaw(requestParameters: GetNewMemberExcelFileBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getNewMemberExcelFileBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getNewMemberExcelFileBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadNewMemberExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getNewMemberExcelFileBetweenDate
     */
    async getNewMemberExcelFileBetweenDateUsingGET(requestParameters: GetNewMemberExcelFileBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getNewMemberExcelFileBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPointGiftExcel
     */
    async getPointGiftExcelUsingGETRaw(requestParameters: GetPointGiftExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadPointGiftExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getPointGiftExcel
     */
    async getPointGiftExcelUsingGET(requestParameters: GetPointGiftExcelUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getPointGiftExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPointToCash
     */
    async getPointToCashUsingGETRaw(requestParameters: GetPointToCashUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadPointToCashExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getPointToCash
     */
    async getPointToCashUsingGET(requestParameters: GetPointToCashUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getPointToCashUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPointToGiftBetweenDate
     */
    async getPointToGiftBetweenDateUsingGETRaw(requestParameters: GetPointToGiftBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getPointToGiftBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getPointToGiftBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadPointToGiftExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getPointToGiftBetweenDate
     */
    async getPointToGiftBetweenDateUsingGET(requestParameters: GetPointToGiftBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getPointToGiftBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductInventoryExcelFileBetweenDate
     */
    async getProductInventoryExcelFileBetweenDateUsingGETRaw(requestParameters: GetProductInventoryExcelFileBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getProductInventoryExcelFileBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getProductInventoryExcelFileBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadProductInventoryExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getProductInventoryExcelFileBetweenDate
     */
    async getProductInventoryExcelFileBetweenDateUsingGET(requestParameters: GetProductInventoryExcelFileBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getProductInventoryExcelFileBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductSalesBetweenDate2
     */
    async getProductSalesBetweenDate2UsingGETRaw(requestParameters: GetProductSalesBetweenDate2UsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getProductSalesBetweenDate2UsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getProductSalesBetweenDate2UsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadProductSalesExcel2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getProductSalesBetweenDate2
     */
    async getProductSalesBetweenDate2UsingGET(requestParameters: GetProductSalesBetweenDate2UsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getProductSalesBetweenDate2UsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductSalesBetweenDate
     */
    async getProductSalesBetweenDateUsingGETRaw(requestParameters: GetProductSalesBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getProductSalesBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getProductSalesBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadProductSalesExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getProductSalesBetweenDate
     */
    async getProductSalesBetweenDateUsingGET(requestParameters: GetProductSalesBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getProductSalesBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
