/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookReportRequestModel
 */
export interface BookReportRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof BookReportRequestModel
     */
    bookName?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BookReportRequestModel
     */
    bookType?: BookReportRequestModelBookTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BookReportRequestModel
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof BookReportRequestModel
     */
    from?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookReportRequestModel
     */
    isbn?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BookReportRequestModel
     */
    limit?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookReportRequestModel
     */
    reportTypes?: Array<BookReportRequestModelReportTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof BookReportRequestModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BookReportRequestModel
     */
    topReportType?: BookReportRequestModelTopReportTypeEnum;
}


/**
 * @export
 */
export const BookReportRequestModelBookTypeEnum = {
    All: 'ALL',
    Audio: 'AUDIO',
    Ebook: 'EBOOK',
    Magazine: 'MAGAZINE'
} as const;
export type BookReportRequestModelBookTypeEnum = typeof BookReportRequestModelBookTypeEnum[keyof typeof BookReportRequestModelBookTypeEnum];

/**
 * @export
 */
export const BookReportRequestModelReportTypesEnum = {
    BorrowTime: 'BORROW_TIME',
    CantBorrow: 'CANT_BORROW',
    Chapter: 'CHAPTER',
    Content: 'CONTENT',
    MobileBorrow: 'MOBILE_BORROW',
    Preview: 'PREVIEW',
    ReadOnline: 'READ_ONLINE',
    WebBorrow: 'WEB_BORROW'
} as const;
export type BookReportRequestModelReportTypesEnum = typeof BookReportRequestModelReportTypesEnum[keyof typeof BookReportRequestModelReportTypesEnum];

/**
 * @export
 */
export const BookReportRequestModelTopReportTypeEnum = {
    Top10Month: 'TOP10_MONTH'
} as const;
export type BookReportRequestModelTopReportTypeEnum = typeof BookReportRequestModelTopReportTypeEnum[keyof typeof BookReportRequestModelTopReportTypeEnum];


export function BookReportRequestModelFromJSON(json: any): BookReportRequestModel {
    return BookReportRequestModelFromJSONTyped(json, false);
}

export function BookReportRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookReportRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookName': !exists(json, 'bookName') ? undefined : json['bookName'],
        'bookType': !exists(json, 'bookType') ? undefined : json['bookType'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'isbn': !exists(json, 'isbn') ? undefined : json['isbn'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'reportTypes': !exists(json, 'reportTypes') ? undefined : json['reportTypes'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'topReportType': !exists(json, 'topReportType') ? undefined : json['topReportType'],
    };
}

export function BookReportRequestModelToJSON(value?: BookReportRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookName': value.bookName,
        'bookType': value.bookType,
        'endDate': value.endDate,
        'from': value.from,
        'isbn': value.isbn,
        'limit': value.limit,
        'reportTypes': value.reportTypes,
        'startDate': value.startDate,
        'topReportType': value.topReportType,
    };
}

