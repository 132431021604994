import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { dateToString } from "../../../utils/GlobalFunction";
import { fetchFileFromURLToFilePost } from "../../../utils/reader/fileUtils/fileUtil";
import style from "./BorrowReport.module.css";

import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import {
    BorrowReportBetweenDateUsingPOSTRequest,
    BorrowViewRecordReportRequestModel,
    BorrowViewRecordReportRequestModelReportTypesEnum,
    BorrowViewRecordReportResponseModel,
    ReportControllerApi,
    ReportRecordControllerApi,
} from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import useWindowSize from "../../../utils/useWindowSize";
import * as Storage from "../../../utils/local-storage";
export default function BorrowReport() {
    const [displayData, setDisplayData] = useState<BorrowViewRecordReportResponseModel>();

    const [yearArr, setYearArr] = useState<any>([]);
    const [monthArr, setMonthArr] = useState<any>([]);
    const [startYear, setSartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [startMonth, setSartMonth] = useState("");
    const [endMonth, setEndMonth] = useState("");
    const [bookType, setBookType] = useState("ALL");
    const [type, setType] = useState<{ id: string; name: string; isChecked: boolean }[]>([
        { id: "WEB_BORROW", name: "網頁借閱", isChecked: true },
        { id: "MOBILE_BORROW", name: "APP借閱", isChecked: true },
        { id: "READ_ONLINE", name: "線上全本瀏覽", isChecked: true },
        { id: "PREVIEW", name: "試閱", isChecked: true },
        { id: "BORROW_TIME", name: "預約次數", isChecked: true },
        { id: "CHAPTER", name: "章節", isChecked: true },
        { id: "CANT_BORROW", name: "無法借閱", isChecked: true },
        { id: "CONTENT", name: "書目次數", isChecked: true },
    ]);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [isbn, setIsbn] = useState([]);
    const [userName, setUserName] = useState([]);
    const [grade, setGrade] = useState([]);
    const [className, setClassName] = useState([]);
    const [groupBy, setGroupBy] = useState(true); //true: Student OR false: Grade & ClassName
    const [lastdate, setLastDate] = useState("");
    const [InputyearDate, setInputyearDate] = useState<number>();
    const [isChecked, setIsChecked] = useState(false);
    const [year, setyear] = useState<number>(0);

    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const navigate = useNavigate();

    const handleType = (e: any) => {
        const result = type.map((obj: { id: string; name: string; isChecked: boolean }) => {
            if (obj.id === e.target.id) {
                return { ...obj, isChecked: !obj.isChecked };
            }
            return obj;
        });
        setType(result);
    };

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        var now = new Date();
        let yearArr = [];
        for (var i = 2000; i <= now.getFullYear(); i++) {
            yearArr.push({ id: i, name: i.toString() });
        }
        setYearArr(yearArr.reverse());
        setInputyearDate(now.getFullYear());

        let monthArr = [];
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                monthArr.push({ id: i, name: `0${i}` });
            } else {
                monthArr.push({ id: i, name: i.toString() });
            }
        }
        setMonthArr(monthArr);

        var nextyear = new Date(new Date().getFullYear(), 11, 31);
        const currentDayOfMonth = nextyear.getDate();
        const currentMonth = nextyear.getMonth(); // Be careful! January is 0, not 1
        const currentYear = nextyear.getFullYear();
        const dateString = currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth;
        setLastDate(dateString);
    }, []);

    const handleSubmit = () => {
        if (start === "" || end === "") {
            return alert(`請輸入有效日期`);
        }
        if (new Date(start) > new Date(end)) {
            return alert(`開始日期必須小於最後日期`);
        }

        if (isChecked && (year == 0 || year < 2019)) {
            return alert(`請輸入有效學年`);
        }

        let a = {
            borrowViewRecordReportRequestModel: {
                bookType: bookType,
                reportTypes: type
                    .filter((it) => it.isChecked)
                    .map((type) => type.id as BorrowViewRecordReportRequestModelReportTypesEnum) as BorrowViewRecordReportRequestModelReportTypesEnum[],
                startDate: start,
                endDate: end,
                isbn: isbn.length === 1 && isbn[0] === "" ? [] : isbn,
                userName: userName,
                grade: grade,
                className: className,
                queryGroupBy: groupBy,
                termYear: isChecked ? year : null,
            } as BorrowViewRecordReportRequestModel,
        } as BorrowReportBetweenDateUsingPOSTRequest;

        new ReportControllerApi(genApiConfig())
            .borrowReportBetweenDateUsingPOST(a)
            .then((res) => {
                if (res !== undefined) {
                    console.log(res);
                    setDisplayData(res);
                }
            })
            .catch((err) => console.log("err", err));
    };

    const handleIsbn = (e: any) => {
        const isbnResult = e.target.value.replaceAll(" ", "").split(",");
        setIsbn(isbnResult);
    };

    const handleUserName = (e: any) => {
        const userNameResult = e.target.value.split(",");
        setUserName(userNameResult);
    };

    const handleGrade = (e: any) => {
        const gradeResult = e.target.value.split(",");
        setGrade(gradeResult);
    };

    const handleClassName = (e: any) => {
        const classNameResult = e.target.value.split(",");
        setClassName(classNameResult);
    };

    const downloadBorrowViewRecord = () => {
        // fetchFileFromURLToFile(
        //     `${process.env.REACT_APP_BACKEND}/reportRecord/export-BorrowViewRecord-report`,
        //     `ReadDate_${dateToString(new Date(), "YYYYMMDDHHMMSS")}.xlsx` //,
        //     //displayData
        // );

        if (start === "" || end === "") {
            return alert(`請輸入有效日期`);
        }
        if (new Date(start) > new Date(end)) {
            return alert(`開始日期必須小於最後日期`);
        }

        if (isChecked && (year == 0 || year < 2019)) {
            return alert(`請輸入有效學年`);
        }

        // console.log("bookType", bookType);
        // console.log(
        //     "type",
        //     type.filter((it) => it.isChecked).map((type) => type.id)
        // );

        let b = {
            bookType: bookType,
            reportTypes: type
                .filter((it) => it.isChecked)
                .map((type) => type.id as BorrowViewRecordReportRequestModelReportTypesEnum) as BorrowViewRecordReportRequestModelReportTypesEnum[],
            startDate: start,
            endDate: end,
            isbn: isbn.length === 1 && isbn[0] === "" ? [] : isbn,
            userName: userName,
            className: className,
            grade: grade,
            queryGroupBy: groupBy,
            termYear: isChecked ? year : null,
        } as BorrowViewRecordReportRequestModel;

        fetchFileFromURLToFilePost(
            `${process.env.REACT_APP_BACKEND}/reportRecord/export-BorrowViewRecord-report`,
            `BorrowViewRecord_${dateToString(new Date(), "YYYYMMDDHHMMSS")}.xlsx`,
            b
        );
    };

    const handletoggle = (value: any) => {};

    return (
        <>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.toggleGroupContainer}>
                <div className={style.toggleGroup}>
                    {/* <div className={style.toggleSilder}>123</div> */}
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.READREPORT)}>
                        {/* <Trans>CateList</Trans> */}閱讀數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButtonCurrent"} callBack={(value) => handletoggle(value)}>
                        {/* <Trans>PurchasedBook</Trans> */}借閱及瀏覽紀錄報告
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BOOKREPORT)}>
                        {/* <Trans>PurchasedBook</Trans> */}書籍數據統計
                    </Button>
                </div>
            </div>
            <div className={style.counterReport}>
                <h2>借閱及瀏覽紀錄報告</h2>
                <div>
                    <div>
                        <p>選擇統計類型:</p>
                        <input
                            type="radio"
                            id="all"
                            value="ALL"
                            name="bookType"
                            checked={bookType === "ALL" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="ALL">全部</label>
                        <input
                            type="radio"
                            id="ebook"
                            value="EBOOK"
                            name="bookType"
                            checked={bookType === "EBOOK" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="EBOOK">電子書</label>
                        <input
                            type="radio"
                            id="magazine"
                            value="MAGAZINE"
                            name="bookType"
                            checked={bookType === "MAGAZINE" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="MAGAZINE">電子雜誌</label>
                        <input
                            type="radio"
                            id="video"
                            value="AUDIO"
                            name="bookType"
                            checked={bookType === "AUDIO" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="AUDIO">影音</label>
                    </div>

                    <div>
                        <p>選擇統計類型:</p>
                        {type.map((it: { id: string; name: string; isChecked: boolean }) => (
                            <>
                                <input type="checkbox" id={it.id} value={it.id} checked={it.isChecked} onClick={(e) => handleType(e)} />
                                <label htmlFor={it.id}>{it.name}</label>
                            </>
                        ))}
                    </div>
                    <div className={style.datePickerContainer}>
                        <p>時間:</p>
                        <label htmlFor="start"></label>
                        <input type="date" id="start" name="trip-start" min="2018-01-01" max={lastdate} value={start} onChange={(e) => setStart(e.target.value)} />
                        <label htmlFor="end"> ~ </label>
                        <input type="date" id="end" name="trip-end" min="2018-01-01" max={lastdate} value={end} onChange={(e) => setEnd(e.target.value)} />
                        {/* <p>(提供查詢5年資料，查詢區間限定1個月內。)</p> */}
                    </div>
                    <label style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                        <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                        <h3>按學年排名</h3>
                        <input type="number" placeholder="Year" min="2019" max={InputyearDate} onChange={(e: any) => setyear(e.target.value)} />
                    </label>
                    <div>
                        <h3>ISBN: (請用逗號區隔，例如: 1234,123,3456)</h3>
                        <textarea onChange={(e: any) => handleIsbn(e)} className={style.textarea} />
                    </div>
                    <div>
                        <h3>讀者帳號:</h3>
                        <input type="text" onChange={(e: any) => handleUserName(e)} className={style.userName} />
                    </div>
                    <div className={style.dualChoice}>
                        <div>
                            <h3>年級:</h3>
                            <input type="text" onChange={(e: any) => handleGrade(e)} className={style.gradeAndClass} />
                        </div>
                        <div>
                            <h3>班別:</h3>
                            <input type="text" onChange={(e: any) => handleClassName(e)} className={style.gradeAndClass} />
                        </div>
                    </div>
                    <div className={style.dualChoice}>
                        <label style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" checked={groupBy} onChange={() => setGroupBy(true)} />
                            <h3>按讀者排名</h3>
                        </label>
                        <h3>或</h3>
                        <label style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" checked={!groupBy} onChange={() => setGroupBy(false)} />
                            <h3>按年級及班別排名</h3>
                        </label>
                    </div>
                </div>
                <Button styleType={"submitButton"} callBack={() => handleSubmit()}>
                    查詢
                </Button>
                <Button styleType={"submitButton"} callBack={() => downloadBorrowViewRecord()}>
                    匯出EXCEL
                </Button>
                <hr className={style.line}></hr>
                <tr className={style.Tabletr}>
                    <th>排名</th>
                    {displayData && !displayData.queryGroupBy ? (
                        <></>
                    ) : (
                        <>
                            <th>帳號</th>
                            <th>姓名</th>
                        </>
                    )}
                    <th>年級</th>
                    <th>班別</th>
                    <th>網頁借閱</th>
                    <th>APP借閱</th>
                    <th>線上全本瀏覽</th>
                    <th>試閱</th>
                    <th>預約次數</th>
                    <th>章節</th>
                    <th>無法借閱</th>
                    <th>書目次數</th>
                    <th>總次數</th>
                    <th>閱讀總時數</th>
                </tr>

                {displayData?.data?.map((val) => {
                    let hours = Math.floor(val?.readTimeCount!! / 3600);
                    let totalTime = val?.readTimeCount!! % 3600;
                    let minutes = Math.floor(totalTime / 60);
                    let seconds = totalTime % 60;
                    return (
                        <tr>
                            <th>{val.seq!! + 1}</th>
                            {displayData.queryGroupBy ? (
                                <>
                                    <th>{val.username}</th>
                                    <th>{val.name}</th>
                                </>
                            ) : (
                                <></>
                            )}

                            <th>{val.grade}</th>
                            <th>{val.className}</th>
                            <th>{val.webBorrow!!}</th>
                            <th>{val.mobileBorrow!!}</th>
                            <th>{val.readOnline!!}</th>
                            <th>{val.preview!!}</th>
                            <th>{val.borrowTime!!}</th>
                            <th>{val.chapter!!}</th>
                            <th>{val.cantBorrow!!}</th>
                            <th>{val.contentCount!!}</th>

                            <th>
                                {val.borrowTime!! + val.cantBorrow!! + val.chapter!! + val.contentCount!! + val.preview!! + val.mobileBorrow!! + val.readOnline!! + val.webBorrow!!}
                            </th>
                            <th>
                                {hours}h{minutes}m{seconds}s
                            </th>
                        </tr>
                    );
                })}

                {displayData ? (
                    <tr>
                        <th colSpan={displayData.queryGroupBy ? 5 : 3}>累計總次數 :</th>

                        <th>{displayData.webBorrowTotal}</th>
                        <th>{displayData.mobileBorrowTotal}</th>
                        <th>{displayData.readOnlineTotal}</th>
                        <th>{displayData.previewTotal}</th>
                        <th>{displayData.borrowTimeTotal}</th>
                        <th>{displayData.chapterTotal}</th>
                        <th>{displayData.cantBorrowTotal}</th>
                        <th>{displayData.contentTotal}</th>
                    </tr>
                ) : (
                    <></>
                )}
                <tr></tr>
            </div>
        </>
    );
}
