/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationContext,
    ApplicationContextFromJSON,
    ApplicationContextFromJSONTyped,
    ApplicationContextToJSON,
} from './ApplicationContext';

/**
 * 
 * @export
 * @interface RedirectView
 */
export interface RedirectView {
    /**
     * 
     * @type {ApplicationContext}
     * @memberof RedirectView
     */
    applicationContext?: ApplicationContext;
    /**
     * 
     * @type {object}
     * @memberof RedirectView
     */
    attributesMap?: object;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    beanName?: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    contentType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    exposePathVariables?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RedirectView
     */
    hosts?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    propagateQueryProperties?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    redirectView?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    requestContextAttribute?: string;
    /**
     * 
     * @type {object}
     * @memberof RedirectView
     */
    staticAttributes?: object;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    url?: string;
}

export function RedirectViewFromJSON(json: any): RedirectView {
    return RedirectViewFromJSONTyped(json, false);
}

export function RedirectViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedirectView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationContext': !exists(json, 'applicationContext') ? undefined : ApplicationContextFromJSON(json['applicationContext']),
        'attributesMap': !exists(json, 'attributesMap') ? undefined : json['attributesMap'],
        'beanName': !exists(json, 'beanName') ? undefined : json['beanName'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'exposePathVariables': !exists(json, 'exposePathVariables') ? undefined : json['exposePathVariables'],
        'hosts': !exists(json, 'hosts') ? undefined : json['hosts'],
        'propagateQueryProperties': !exists(json, 'propagateQueryProperties') ? undefined : json['propagateQueryProperties'],
        'redirectView': !exists(json, 'redirectView') ? undefined : json['redirectView'],
        'requestContextAttribute': !exists(json, 'requestContextAttribute') ? undefined : json['requestContextAttribute'],
        'staticAttributes': !exists(json, 'staticAttributes') ? undefined : json['staticAttributes'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function RedirectViewToJSON(value?: RedirectView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationContext': ApplicationContextToJSON(value.applicationContext),
        'attributesMap': value.attributesMap,
        'beanName': value.beanName,
        'contentType': value.contentType,
        'exposePathVariables': value.exposePathVariables,
        'hosts': value.hosts,
        'propagateQueryProperties': value.propagateQueryProperties,
        'redirectView': value.redirectView,
        'requestContextAttribute': value.requestContextAttribute,
        'staticAttributes': value.staticAttributes,
        'url': value.url,
    };
}

