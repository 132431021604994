/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassLoader,
    ClassLoaderFromJSON,
    ClassLoaderFromJSONTyped,
    ClassLoaderToJSON,
} from './ClassLoader';
import {
    ModuleDescriptor,
    ModuleDescriptorFromJSON,
    ModuleDescriptorFromJSONTyped,
    ModuleDescriptorToJSON,
} from './ModuleDescriptor';

/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {Array<object>}
     * @memberof Module
     */
    annotations?: Array<object>;
    /**
     * 
     * @type {ClassLoader}
     * @memberof Module
     */
    classLoader?: ClassLoader;
    /**
     * 
     * @type {Array<object>}
     * @memberof Module
     */
    declaredAnnotations?: Array<object>;
    /**
     * 
     * @type {ModuleDescriptor}
     * @memberof Module
     */
    descriptor?: ModuleDescriptor;
    /**
     * 
     * @type {object}
     * @memberof Module
     */
    layer?: object;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Module
     */
    named?: boolean;
    /**
     * 
     * @type {Set<string>}
     * @memberof Module
     */
    packages?: Set<string>;
}

export function ModuleFromJSON(json: any): Module {
    return ModuleFromJSONTyped(json, false);
}

export function ModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Module {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'classLoader': !exists(json, 'classLoader') ? undefined : ClassLoaderFromJSON(json['classLoader']),
        'declaredAnnotations': !exists(json, 'declaredAnnotations') ? undefined : json['declaredAnnotations'],
        'descriptor': !exists(json, 'descriptor') ? undefined : ModuleDescriptorFromJSON(json['descriptor']),
        'layer': !exists(json, 'layer') ? undefined : json['layer'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'named': !exists(json, 'named') ? undefined : json['named'],
        'packages': !exists(json, 'packages') ? undefined : json['packages'],
    };
}

export function ModuleToJSON(value?: Module | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'classLoader': ClassLoaderToJSON(value.classLoader),
        'declaredAnnotations': value.declaredAnnotations,
        'descriptor': ModuleDescriptorToJSON(value.descriptor),
        'layer': value.layer,
        'name': value.name,
        'named': value.named,
        'packages': value.packages,
    };
}

