import logo_w from "../../assets/logo_w.png";
import lib_suep from "../../assets/lib_suep.jpg";
import { TbTrash } from "react-icons/tb";
// import { IoClose } from "react-icons/io5";
import { useEffect, useState } from "react";
import Input from "../../components/Input/Input";
import {
  ACCESS_TOKEN,
  LAST_MSG,
  REFRESH_TOKEN,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  USER_INFO,
} from "../../utils/Contants";
import React from "react";
import Footer from "../../components/Footer/Footer";
import style from "./SelectOrgs.module.css";
import Home from "../Home/Home";

type School = {
  chiName: String;
  engName: String;
  type: String;
  id: String;
  url: string;
};

export default function SelectOrgs() {
  const [selectedOrgs, setSelectedOrgs] = useState<School>();
  const [tempSelectedOrgs, setTempSelectedOrgs] = useState<School>();
  const [orgs, setOrgs] = useState<Array<School>>();
  const orgsRef = React.useRef(orgs);
  const [displayOrgs, setDisplayOrgs] = useState<Array<School>>();
  const [searchOrgValue, setSearchOrgValue] = useState<string>("");
  const [showSelectDialog, setSelectDialog] = useState<boolean>(false);
  const [selectedSchoolType, setSelectedSchoolType] =
    useState<String>("PriSchool");

  // useEffect(() => {
  //     let savedSchool = localStorage.getItem("savedSchool");
  //     if (savedSchool) {
  //         setSelectedOrgs(JSON.parse(savedSchool) as School);
  //     }

  //     console.log("useEffect");
  //     const searchOrgInputText = document.getElementById("searchOrgInput");

  //     if (searchOrgInputText !== null) {
  //         searchOrgInputText.removeEventListener("keydown", handleSearchOrgListener);
  //         searchOrgInputText.addEventListener("keydown", handleSearchOrgListener);
  //     }

  //     setOrgs(require("./school.json"));
  //     orgsRef.current = require("./school.json");
  //     setDisplayOrgs(require("./school.json"));
  // }, []);

  useEffect(() => {
    localStorage.removeItem(LAST_MSG);
    localStorage.removeItem(USER_INFO);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    let savedSchool = localStorage.getItem("savedSchool");
    if (savedSchool) {
      setSelectedOrgs(JSON.parse(savedSchool) as School);
    }

    console.log("useEffect");
    const searchOrgInputText = document.getElementById("searchOrgInput");

    if (searchOrgInputText !== null) {
      searchOrgInputText.removeEventListener(
        "keydown",
        handleSearchOrgListener
      );
      searchOrgInputText.addEventListener("keydown", handleSearchOrgListener);
    }

    fetch("https://image.nblib.com/js/custom_js/school.json")
      .then((response) => response.json())
      .then((data) => {
        setOrgs(data);
        orgsRef.current = data;
        setDisplayOrgs(data);
      })
      .catch((err) => {
        console.log("catch", err);
      });
  }, []);

  useEffect(() => {
    handleSearchOrgEnter();
  }, [searchOrgValue]);

  const handleSearchOrgListener = (e: { code: string }) => {
    if (e.code == "NumpadEnter" || e.code == "Enter") {
      handleSearchOrgEnter();
    }
  };

  const handleSearchOrgEnter = () => {
    const searchOrgInputText = (
      document.getElementById("searchOrgInput") as HTMLInputElement
    ).value;
    setDisplayOrgs(
      orgsRef.current?.filter(
        (orgObj: School) =>
          orgObj.chiName.includes(searchOrgInputText) ||
          orgObj.engName
            .toUpperCase()
            .includes(searchOrgInputText.toUpperCase()) ||
          orgObj.id.toUpperCase().includes(searchOrgInputText.toUpperCase())
      )
    );
  };

  const handleSearchOrgs = () => {
    console.log("handleSearchOrgs");
    setDisplayOrgs(
      orgs?.filter(
        (orgObj: School) =>
          orgObj.chiName.includes(searchOrgValue) ||
          orgObj.engName.includes(searchOrgValue) ||
          orgObj.id.toUpperCase().includes(searchOrgValue.toUpperCase())
      )
    );
  };

  return (
    <>
      <div className={style.base}>
        <header>
          <div className={style.header}>
            <img className={style.headerLogo} alt="logo_w" src={logo_w} />
          </div>
        </header>
        <div className={style.content}>
          <img className={style.contentLogo} alt="lib_suep" src={lib_suep} />
          <div className={style.select_area}>
            選擇學校/機構
            <div className={style.select_field}>
              <h4 className={style.orgsName}>{selectedOrgs?.chiName}</h4>
              {selectedOrgs !== undefined ? (
                <button
                  className={style.select_controll}
                  onClick={() => {
                    setTempSelectedOrgs(undefined);
                    setSelectedOrgs(undefined);

                    let savedSchool = localStorage.getItem("savedSchool");
                    if (savedSchool) {
                      localStorage.removeItem("savedSchool");
                    }
                  }}
                >
                  <TbTrash size={30} />
                </button>
              ) : (
                <button
                  className={style.select_controll}
                  onClick={() => {
                    setSelectDialog(true);
                  }}
                >
                  <h2>顯示全部</h2>
                </button>
              )}
            </div>
          </div>
          {selectedOrgs ? (
            <button
              className={style.submit}
              // onClick={() => {
              //     localStorage.setItem("savedSchool", JSON.stringify(selectedOrgs))
              //     window.location.href = (window.location.protocol + "//" + selectedOrgs.id + "." + window.location.host + "/")
              // }}    //Uncomment This For testing
              onClick={() => {
                localStorage.setItem(
                  "savedSchool",
                  JSON.stringify(selectedOrgs)
                );
                window.location.href = selectedOrgs.url;
              }} //Comment This For testing
            >
              {" "}
              <h3>下一步</h3>{" "}
            </button>
          ) : (
            <button
              className={style.submitDisabled}
              onClick={() => console.log("window.location", window.location)}
            >
              {" "}
              <h3>下一步</h3>{" "}
            </button>
          )}
        </div>
        <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
          <Footer />
        </div>
      </div>

      <div className={style.dialogCover} hidden={!showSelectDialog}>
        <div className={style.dialog}>
          <ul className={style.MainSectionUl}>
            <li
              className={
                selectedSchoolType === "PriSchool"
                  ? style.MainSectionLiSelected
                  : style.MainSectionLi
              }
              onClick={() => {
                setSelectedSchoolType("PriSchool");
              }}
            >
              <a>小學</a>
            </li>
            <li
              className={
                selectedSchoolType === "SecSchool"
                  ? style.MainSectionLiSelected
                  : style.MainSectionLi
              }
              onClick={() => {
                setSelectedSchoolType("SecSchool");
              }}
            >
              <a>中學</a>
            </li>
            <li
              className={
                selectedSchoolType === "UniSchool"
                  ? style.MainSectionLiSelected
                  : style.MainSectionLi
              }
              onClick={() => {
                setSelectedSchoolType("UniSchool");
              }}
            >
              <a>大專或其他</a>
            </li>
          </ul>
          {/* <div className={style.dialogHeader}>
                        <div className={style.dialogHeaderNav}>
                            <button
                                className={selectedSchoolType === "PriSchool" ? style.dialogNavbtnSelected : style.dialogNavbtn}
                                onClick={() => {
                                    setSelectedSchoolType("PriSchool");
                                }}
                            >
                                <h1>小學</h1>
                            </button>
                            <button
                                className={selectedSchoolType === "SecSchool" ? style.dialogNavbtnSelected : style.dialogNavbtn}
                                onClick={() => {
                                    setSelectedSchoolType("SecSchool");
                                }}
                            >
                                <h1>中學</h1>
                            </button>
                            <button
                                className={selectedSchoolType === "UniSchool" ? style.dialogNavbtnSelected : style.dialogNavbtn}
                                onClick={() => {
                                    setSelectedSchoolType("UniSchool");
                                }}
                            >
                                <h1>大專或其他</h1>
                            </button>
                        </div>
                         <button className={style.dialogClose} onClick={() => { setSelectDialog(false); }}>
                            <IoClose size={30} />
                        </button>
                    </div> */}
          <div className={style.dialogContent}>
            <div className={style.searchField}>
              <Input
                id="searchOrgInput"
                type="text"
                placeholder={"輸入關鍵字"}
                value={searchOrgValue}
                callBack={(value) => setSearchOrgValue(value)}
                styleType={STYLETYPE_MAIN}
              />
              <button
                className={style.submit}
                style={{ width: "auto", minWidth: "5rem", marginLeft: "1rem" }}
                onClick={handleSearchOrgs}
              >
                {" "}
                <h3>搜尋</h3>{" "}
              </button>
            </div>
            <div className={style.orgList}>
              <div className={style.orgTitle}>學校</div>
              <div>
                {displayOrgs?.map((obj) =>
                  obj.type === selectedSchoolType ? (
                    <div
                      className={style.orgItem}
                      onClick={() => setTempSelectedOrgs(obj)}
                    >
                      <input
                        type="checkbox"
                        className={style.orgItemCheck}
                        checked={obj === tempSelectedOrgs}
                        readOnly
                      />
                      <p className={style.orgItemName}>{obj.chiName}</p>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
            </div>
          </div>
          <div className={style.dialogReturn}>
            <button
              className={style.cancel}
              onClick={() => {
                setTempSelectedOrgs(undefined);
                setSelectDialog(false);
              }}
            >
              {" "}
              <h3>取消</h3>{" "}
            </button>

            <button
              className={style.submit}
              onClick={
                tempSelectedOrgs
                  ? () => {
                      setSelectedOrgs(tempSelectedOrgs);
                      setSelectDialog(false);
                    }
                  : () => {}
              }
            >
              {" "}
              <h3>選擇學校</h3>{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
