import { isElectron } from "react-device-detect";
import { CouponToJSON } from "../../../fetcher";
import StorageUtil from "./storageUtil";

export const openExternalUrl = (url) => {
  console.log("openExternalUrl", url);
  console.log("openExternalUrl", isElectron);
  // isElectron ? (
  //   StorageUtil.getReaderConfig("isUseBuiltIn") === "yes" ? (
  //     window.open(url)
  //   ) : (
  //     window.require("electron").shell.openExternal(url)
  //   )
  // ) : (
  //   <></>
  // );
  window.open(url);
};
