import Button from "../../../components/Button/Button";
import arrowBack from "../../../assets/svg/back.svg";
import style from "./MarqueeSetting.module.css";
import { useNavigate } from "react-router-dom";
import * as PATH from "../../../utils/Contants";
import { Trans } from "react-i18next";
import { useEffect, useState } from "react";
import Input from "../../../components/Input/Input";
import {
  ManageMessageUsingPOSTRequest,
  PageSetting,
  PageSettingControllerApi,
} from "../../../fetcher";
import { genApiConfig } from "../../../utils/fetch-caller";
import { dateToString } from "../../../utils/GlobalFunction";

export function MarqueeSetting() {
  const navigate = useNavigate();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [enable, setEnable] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    new PageSettingControllerApi(genApiConfig())
      .getMessageUsingGET()
      .then((res: PageSetting) => {
        // let tempStartDate, tempEndTime: Date
        if (res.startTime && res.startTime !== undefined) {
          console.log(
            "setStart(dateToString(res.startTime))",
            dateToString(res.startTime)
          );
          setStart(dateToString(res.startTime));
        }
        if (res.endTime && res.endTime !== undefined) {
          console.log(
            "setEnd(dateToString(res.endTime))",
            dateToString(res.endTime)
          );
          setEnd(dateToString(res.endTime));
        }
        if (res.enable && res.enable !== undefined) {
          console.log("setEnable(res.enable)", res.enable);
          setEnable(res.enable);
        }
        if (res.content && res.content !== undefined) {
          console.log("setContent(res.content)", res.content);
          setContent(res.content);
        }
      });
  }, []);

  const submit = () => {
    let pageSetting: ManageMessageUsingPOSTRequest = {
      message: content,
      enable: enable,
      startDate: `${start} 00:00:00`,
      endDate: `${end} 23:59:59`,
    };
    new PageSettingControllerApi(genApiConfig())
      .manageMessageUsingPOST(pageSetting)
      .then((res: PageSetting) => {
        if (res.pageSettingId !== null) alert("更新成功！");
      });
  };

  return (
    <div>
      <div className={style.Header}>
        <div className={style.backHomeButtonContainer}>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={(value) => navigate(PATH.ADMIN)}
            >
              <img src={arrowBack} />
              <Trans>PreviousPage</Trans>
            </Button>
          </div>
        </div>
      </div>
      <div className={style.container}>
        <textarea
          className={style.textarea}
          onChange={(e) =>
            e.target.value.length > 300
              ? alert("跑馬燈文字長度不得多於300字！")
              : setContent(e.target.value)
          }
          value={content}
          placeholder="Enter something here..."
        />
        <div className={style.settingRow}>
          <div className={style.settingRowItem}>
            <h4>生效時間:</h4>
            <div>
              <label htmlFor="start"></label>
              <input
                type="date"
                id="start"
                name="trip-start"
                /*min="2018-01-01" max="2018-01-01"*/ value={start}
                onChange={(e) => setStart(e.target.value)}
              />
              <label htmlFor="end"> ~ </label>
              <input
                type="date"
                id="end"
                name="trip-end"
                /*min="2018-01-01" max="2018-01-01"*/ value={end}
                onChange={(e) => setEnd(e.target.value)}
              />
            </div>
          </div>
          <div className={style.settingRowItem}>
            <h4>有效:</h4>
            <Input
              type="checkbox"
              callBack={(value) => setEnable(value)}
              styleType=""
              checked={enable}
            />
          </div>
          <div className={style.submitContainer}>
            <Button styleType={"submitButton"} callBack={() => submit()}>
              儲存
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
