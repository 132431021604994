import { Component } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import {
  BorrowControllerApi,
  ListAllMyReserveBookUsingPOSTRequest,
  ListReserveByBookUsingGETRequest,
  ReserveBorrow,
  ReserveBorrowDto,
} from "../../../fetcher";
import { handleUserInfo } from "../../../redux/actions/userInfo";
import { genApiConfig } from "../../../utils/fetch-caller";
import Button from "../../Button/Button";
import DialogBox from "../../DialogBox/DialogBox";
import style from "./ReservationItem.module.css";
import * as PATH from "../../../utils/Contants";
import api from "../../../api/api";

interface IProps {
  title?: string;
  content?: string;
  value?: string;
  callBack: (value: any, value2?: any) => void;
  styleType?: string;
  children?: any;
  item?: any;
  navigate?: any;
  userInfo?: any;
  reserveList?: Array<ReserveBorrow>;
}

class ReservationItem extends Component<IProps> {
  state = {
    content: {},
    title: "",
    showError: false,
    percent: 10,
    reserveList: [],
    showConfirmDialog: false,
    dialogMsg: "",
    borrowData: {} as ReserveBorrowDto,
    //adminMember: JSON.parse(localStorage.getItem("adminMember")!) as any,
    //member: JSON.parse(localStorage.getItem("member")!) as any,
    // data: this.props.item,
  };

  componentDidMount() {
    this.getRerserveList();
  }

  // componentDidMount() {
  //   let adminUserInfo = JSON.parse(
  //     localStorage.getItem("adminMember")!!
  //   ) as AdminMemberDto;
  //   let memberUserInfo = JSON.parse(
  //     localStorage.getItem("member")!!
  //   ) as MemberDto;

  //   if (adminUserInfo !== null && adminUserInfo !== undefined) {
  //     this.setState((state: any) => ({
  //       adminMember: adminUserInfo.adminMemberId,
  //     }));
  //   } else if (memberUserInfo !== null && memberUserInfo !== undefined) {
  //     this.setState((state: any) => ({ member: memberUserInfo.memberId }));
  //   } else {
  //     return;
  //   }
  // }

  getRerserveList = () => {
    new BorrowControllerApi(genApiConfig())
      .listReserveByBookUsingGET({
        productId: this.props.item.productId,
      } as ListReserveByBookUsingGETRequest)
      .then((data: Array<ReserveBorrow>) => {
        console.log("listReserveByBookUsingGET", data);
        this.setState({ ...this.state, reserveList: data });
      });
  };

  handleCancelReserveBook = () => {
    console.log("this", this.props);
    new BorrowControllerApi(genApiConfig())
      .cancelReservedBookUsingDELETE({
        // memberId: this.state.adminMember
        //   ? this.state.adminMember
        //   : this.state.member,
        memberId: this.props.userInfo?.userInfo.member.memberId,
        productId: this.props.item.productId,
      })
      .then((data: ReserveBorrowDto) => {
        if (data.status !== undefined) {
          this.setState({
            ...this.state,
            showConfirmDialog: true,
            dialogMsg: `書本 ${this.props.item.bookname} 的預約己取消`,
            borrowData: data,
          });
        }
      });
  };

  handleCallBack = () => {};

  render() {
    const { content, showError, percent } = this.state;
    return (
      <>
        <div className={style.Container}>
          <div
            className={style.CardView_IMG}
            onClick={() =>
              this.props.navigate(`/product/${this.props.item.productId}`)
            }
          >
            <img
              style={{ width: "150px", height: "200px" }}
              src={api.getFileUrl(this.props.item.sapArticleNumber, "cover", this.props.item.bookCover, "x-oss-process=image/resize,h_20")}
            />
          </div>
          <div className={style.Content}>
            <div>
              <h5>{this.props.item.bookname && this.props.item.bookname}</h5>
            </div>
            <div style={{ fontSize: "10px" }}>
              {this.props.item.author && this.props.item.author}
            </div>
            {/* <Button
              styleType={"upperNavbarButton"}
              callBack={() => this.handleCancelReserveBook()}
            >
              取消預約
            </Button> */}
            {/* <div className={style.ContentDate}>
              預期
              {dateToString(this.props.item.estimateBorrowDate, "DD/MM/YYYY")}
            </div> */}
          </div>
          <>
            <Trans>YourBookingSeq</Trans>
            {this.state.reserveList.map((value, index) => {
              console.log("value", value);
              console.log("index", index);
              let v = value as ReserveBorrow;
              if (v.memberId == this.props.userInfo?.userInfo.member.memberId) {
                return index + 1;
              }
            })}
          </>
          <div className={style.Reservationbtn}>
            <Button
              styleType={"submitButton"}
              callBack={() => this.handleCancelReserveBook()}
            >
              <Trans>CancelBooking</Trans>
            </Button>
          </div>
        </div>

        <DialogBox
          styleType={PATH.STYLETYPE_SECOND}
          showDialog={this.state.showConfirmDialog}
          p1={this.state.dialogMsg}
          title={<Trans>SystemMessage</Trans>}
          scroll={false}
        >
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"submitButton"}
              callBack={() => {
                this.setState(
                  { ...this.state, showConfirmDialog: false, dialogMsg: "" },
                  () => {
                    new BorrowControllerApi(genApiConfig())
                      .listAllMyReserveBookUsingPOST({
                        // memberId: this.state.adminMember
                        //   ? this.state.adminMember
                        //   : this.state.member,
                        memberId: this.props.userInfo?.userInfo.member.memberId,
                      } as ListAllMyReserveBookUsingPOSTRequest)
                      .then((data: Array<ReserveBorrowDto>) => {
                        this.props.callBack(data);
                      });
                  }
                );
              }}
            >
              <Trans>Confirm</Trans>
            </Button>
          </div>
        </DialogBox>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = {
  handleUserInfo,
};
export default connect(mapStateToProps, actionCreator)(ReservationItem);
