/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassLoader,
    ClassLoaderFromJSON,
    ClassLoaderFromJSONTyped,
    ClassLoaderToJSON,
} from './ClassLoader';
import {
    Environment,
    EnvironmentFromJSON,
    EnvironmentFromJSONTyped,
    EnvironmentToJSON,
} from './Environment';

/**
 * 
 * @export
 * @interface ApplicationContext
 */
export interface ApplicationContext {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    applicationName?: string;
    /**
     * 
     * @type {object}
     * @memberof ApplicationContext
     */
    autowireCapableBeanFactory?: object;
    /**
     * 
     * @type {number}
     * @memberof ApplicationContext
     */
    beanDefinitionCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationContext
     */
    beanDefinitionNames?: Array<string>;
    /**
     * 
     * @type {ClassLoader}
     * @memberof ApplicationContext
     */
    classLoader?: ClassLoader;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    displayName?: string;
    /**
     * 
     * @type {Environment}
     * @memberof ApplicationContext
     */
    environment?: Environment;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    id?: string;
    /**
     * 
     * @type {ApplicationContext}
     * @memberof ApplicationContext
     */
    parent?: ApplicationContext;
    /**
     * 
     * @type {object}
     * @memberof ApplicationContext
     */
    parentBeanFactory?: object;
    /**
     * 
     * @type {number}
     * @memberof ApplicationContext
     */
    startupDate?: number;
}

export function ApplicationContextFromJSON(json: any): ApplicationContext {
    return ApplicationContextFromJSONTyped(json, false);
}

export function ApplicationContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationContext {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'autowireCapableBeanFactory': !exists(json, 'autowireCapableBeanFactory') ? undefined : json['autowireCapableBeanFactory'],
        'beanDefinitionCount': !exists(json, 'beanDefinitionCount') ? undefined : json['beanDefinitionCount'],
        'beanDefinitionNames': !exists(json, 'beanDefinitionNames') ? undefined : json['beanDefinitionNames'],
        'classLoader': !exists(json, 'classLoader') ? undefined : ClassLoaderFromJSON(json['classLoader']),
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'environment': !exists(json, 'environment') ? undefined : EnvironmentFromJSON(json['environment']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parent': !exists(json, 'parent') ? undefined : ApplicationContextFromJSON(json['parent']),
        'parentBeanFactory': !exists(json, 'parentBeanFactory') ? undefined : json['parentBeanFactory'],
        'startupDate': !exists(json, 'startupDate') ? undefined : json['startupDate'],
    };
}

export function ApplicationContextToJSON(value?: ApplicationContext | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationName': value.applicationName,
        'autowireCapableBeanFactory': value.autowireCapableBeanFactory,
        'beanDefinitionCount': value.beanDefinitionCount,
        'beanDefinitionNames': value.beanDefinitionNames,
        'classLoader': ClassLoaderToJSON(value.classLoader),
        'displayName': value.displayName,
        'environment': EnvironmentToJSON(value.environment),
        'id': value.id,
        'parent': ApplicationContextToJSON(value.parent),
        'parentBeanFactory': value.parentBeanFactory,
        'startupDate': value.startupDate,
    };
}

