import { Component, Fragment } from "react";
import { DIALOG_CANCEL, DIALOG_COMPLETE, DIALOG_DELETE } from "../../../utils/Contants";
import style from "./BookMarkComponents.module.css";
import ReservationItem from "./BookMarkItem";

import { connect } from "react-redux";
import { GetMemberFavouritesByMemberIdUsingGETRequest, MemberControllerApi, ProductDto } from "../../../fetcher";
import { myBookMarkList } from "../../../redux/actions/myBookMark/myBookMark";
import { handleUserInfo } from "../../../redux/actions/userInfo";
import { genApiConfig } from "../../../utils/fetch-caller";
import BookMarkItem from "./BookMarkItem";
import { Trans, useTranslation } from "react-i18next";

interface IProps {
    title?: string;
    content?: string;
    value?: string;
    callBack: (value: any, value2?: any) => void;
    styleType?: string;
    children?: any;
    item?: any;
    navigate: any;
    userInfo?: any;
    myBookMarkList?: any;
}

class BookMarkComponents extends Component<IProps> {
    state = {
        content: {},
        title: "",
        showError: false,
        loading: false,
        bookMarkList: [] as Array<ProductDto>,
    };

    componentDidMount() {
        console.log("BookMarkComponents", this.props);
        this.getApiData();
    }
    handleCancel = () => {
        this.props.callBack({ action: DIALOG_CANCEL });
    };

    handleSubmit = () => {
        this.props.callBack({ action: DIALOG_COMPLETE });
    };

    handlecallback = (value: any) => {
        console.log(value);
        // if (value !== null && value !== undefined) {
        //     switch (value.action) {
        //         case DIALOG_DELETE:
        //             this.props.callBack({ action: DIALOG_DELETE });
        //             this.getApiData();
        //             break;
        //     }
        // }
        this.props.callBack({ action: DIALOG_DELETE });
        this.getApiData();
    };

    getApiData() {
        if (Object.keys(this.props.userInfo).length > 0) {
            new MemberControllerApi(genApiConfig())
                .getMemberFavouritesByMemberIdUsingGET({
                    memberId: this.props.userInfo?.userInfo.member.memberId,
                } as GetMemberFavouritesByMemberIdUsingGETRequest)
                .then((data: Array<ProductDto>) => {
                    //this.props.myBookMarkList(data);
                    this.setState({ ...this.state, bookMarkList: data });
                    //this.setState({ ...this.state, reserveBookData: res });
                    this.props.callBack?.(this.state.bookMarkList);
                });
        }
    }

    render() {
        const { content, showError, loading } = this.state;
        return (
            <Fragment>
                <div style={{ marginTop: "2em" }} className={style.tableItemListContainer}>
                    <div className={style.tableListItemGridView}>
                        <div className={style.topRow}></div>

                        <div className={style.content}>
                            <div className={style.padding}>
                                <div className={style.title}>
                                    <h3>
                                        <Trans>MyBookmark</Trans>
                                    </h3>
                                </div>
                                <div className={style.scrollingWrapper}>
                                    {this.state.bookMarkList &&
                                        this.state.bookMarkList.map((it: any) => {
                                            return (
                                                <BookMarkItem
                                                    navigate={this.props.navigate}
                                                    //callBack={(value) => this.props.callBack(value)}
                                                    callBack={(value) => this.handlecallback(value)}
                                                    item={it}
                                                    userInfo={this.props.userInfo}
                                                ></BookMarkItem>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        userInfo: state.userInfo,
        bookMarkList: state.myBookMarkList,
    };
};
const actionCreator = {
    handleUserInfo,
    myBookMarkList,
};

export default connect(mapStateToProps, actionCreator)(BookMarkComponents);
