/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BorrowViewRecordReportData,
    BorrowViewRecordReportDataFromJSON,
    BorrowViewRecordReportDataFromJSONTyped,
    BorrowViewRecordReportDataToJSON,
} from './BorrowViewRecordReportData';

/**
 * 
 * @export
 * @interface BorrowViewRecordReportResponseModel
 */
export interface BorrowViewRecordReportResponseModel {
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportResponseModel
     */
    borrowTimeTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportResponseModel
     */
    cantBorrowTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportResponseModel
     */
    chapterTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportResponseModel
     */
    contentTotal?: number;
    /**
     * 
     * @type {Array<BorrowViewRecordReportData>}
     * @memberof BorrowViewRecordReportResponseModel
     */
    data?: Array<BorrowViewRecordReportData>;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportResponseModel
     */
    mobileBorrowTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportResponseModel
     */
    previewTotal?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BorrowViewRecordReportResponseModel
     */
    queryGroupBy?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportResponseModel
     */
    readOnlineTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportResponseModel
     */
    webBorrowTotal?: number;
}

export function BorrowViewRecordReportResponseModelFromJSON(json: any): BorrowViewRecordReportResponseModel {
    return BorrowViewRecordReportResponseModelFromJSONTyped(json, false);
}

export function BorrowViewRecordReportResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorrowViewRecordReportResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borrowTimeTotal': !exists(json, 'borrowTimeTotal') ? undefined : json['borrowTimeTotal'],
        'cantBorrowTotal': !exists(json, 'cantBorrowTotal') ? undefined : json['cantBorrowTotal'],
        'chapterTotal': !exists(json, 'chapterTotal') ? undefined : json['chapterTotal'],
        'contentTotal': !exists(json, 'contentTotal') ? undefined : json['contentTotal'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(BorrowViewRecordReportDataFromJSON)),
        'mobileBorrowTotal': !exists(json, 'mobileBorrowTotal') ? undefined : json['mobileBorrowTotal'],
        'previewTotal': !exists(json, 'previewTotal') ? undefined : json['previewTotal'],
        'queryGroupBy': !exists(json, 'queryGroupBy') ? undefined : json['queryGroupBy'],
        'readOnlineTotal': !exists(json, 'readOnlineTotal') ? undefined : json['readOnlineTotal'],
        'webBorrowTotal': !exists(json, 'webBorrowTotal') ? undefined : json['webBorrowTotal'],
    };
}

export function BorrowViewRecordReportResponseModelToJSON(value?: BorrowViewRecordReportResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'borrowTimeTotal': value.borrowTimeTotal,
        'cantBorrowTotal': value.cantBorrowTotal,
        'chapterTotal': value.chapterTotal,
        'contentTotal': value.contentTotal,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(BorrowViewRecordReportDataToJSON)),
        'mobileBorrowTotal': value.mobileBorrowTotal,
        'previewTotal': value.previewTotal,
        'queryGroupBy': value.queryGroupBy,
        'readOnlineTotal': value.readOnlineTotal,
        'webBorrowTotal': value.webBorrowTotal,
    };
}

