import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import arrowBack from "../../assets/svg/back.svg";
import Button from "../../components/Button/Button";
import DialogBox from "../../components/DialogBox/DialogBox";
import SelectOption from "../../components/SelectOption/SelectOption";
import SearchResultCardView from "../../components/TableCardView/Search/SearchResultCardView";
import { CategoryControllerApi, CategoryDto, GetPagedProductBySearchingQueryExtraUsingGETRequest, OrganizationControllerApi, ProductControllerApi } from "../../fetcher";
import { STYLETYPE_FOUR, STYLETYPE_MAIN } from "../../utils/Contants"; //"../../global/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import { getArgsQuery } from "../../utils/GlobalFunction";
import useWindowSize from "../../utils/useWindowSize";
import style from "./SearchResult.module.css";

export default function SearchResult() {
    const { i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [apiData, setApiData] = useState<any>([]);
    const [displayData, setDisplayData] = useState([]);
    const [publishHouse, setPublishHouse] = useState<any>([]);
    const [resultPublicHouse, setResultPublicHouse] = useState<any>([]);
    const [category, setCategory] = useState<Array<CategoryDto>>();
    const params = useParams();
    let result = getArgsQuery();
    const rootElement = document.getElementById("root");
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [bookCount, setBookCount] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        rootElement && rootElement.scrollTo(0, 0);
        //console.log("query", result);
        const { keyword, inputOption, fromYear, toYear, organizationId, categoryId } = result;
        let params = [];

        var p: GetPagedProductBySearchingQueryExtraUsingGETRequest = {
            page: currentPage,
            size: 30,
        }

        if (keyword && inputOption) {
            if (inputOption === "all") {
                p = { ...p, bookName: keyword, author: keyword, bookDescription: keyword }
                params.push("bookName=" + keyword);
                params.push("author=" + keyword);
                params.push("bookDescription=" + keyword);
            } else if (inputOption === "bookName") {
                p = { ...p, bookName: keyword }
                // params.push(inputOption + "=" + keyword);
            } else if (inputOption === "author") {
                p = { ...p, author: keyword }
                // params.push(inputOption + "=" + keyword);
            } else if (inputOption === "bookDescription") {
                p = { ...p, bookDescription: keyword }
                // params.push(inputOption + "=" + keyword);
            } else {
                // params.push(inputOption + "=" + keyword);
            }
        }
        if (fromYear && fromYear.length > 0) {
            // params.push("fromYear=" + fromYear);
            p = { ...p, fromYear: +fromYear }
        }
        if (toYear && toYear.length > 0) {
            // params.push("toYear=" + toYear);
            p = { ...p, toYear: +toYear }
        }
        if (organizationId && organizationId.length > 0) {
            // console.log(publisher);
            // params.push("organizationId=" + organizationId);
            p = { ...p, organizationId: +organizationId }
        }
        if (categoryId && categoryId.length > 0) {
            // params.push("categoryId=" + categoryId);
            p = {...p, categoryId: categoryId}
        }
        new ProductControllerApi(genApiConfig())
            .getPagedProductBySearchingQueryExtraUsingGET(p).then((data: any) => {
                setApiData(data)
                setPageCount(data.pagenum);
                setBookCount(data.bookCount)
            });
        new OrganizationControllerApi(genApiConfig()).getAllOrganizationUsingGET().then((data: any) => {
            setPublishHouse(
                [].concat.apply(
                    [],
                    data.map((org: any) => org.subOrganization)
                )
            );
        });
        new CategoryControllerApi(genApiConfig()).getAllCategoryUsingGET().then((data: CategoryDto[]) => {
            setCategory(
                [].concat.apply(
                    [],
                    data.map((x: any) => [].concat.apply(x, x.subCategories))
                )
            );
        });
        console.log("publishHouse", publishHouse);
    }, [window.location.search, currentPage]);

    useEffect(() => {
        setDisplayData(apiData.list);
        setResultPublicHouse(publishHouse.filter((it: any) => it.organizationId == result.organizationId));
    }, [apiData, publishHouse]);

    const categortResult = () => {
        if (result.categoryId === undefined) return;

        let catIdArray = result.categoryId.split(",");
        if (catIdArray.length <= 0) return;
        // TODO : any to Class
        let cat = category?.filter((it: any) => catIdArray.includes(it.categoryId.toString()));
        return cat;
    };

    console.log(result.organizationId);

    const handlepagecount = () => {
        let pageArr = [];
        for (var i = 1; i <= pageCount; i++) {
            pageArr.push({
                id: i,
                name: i + "",
            });
        }

        return pageArr;
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };
    const handleLastPage = () => {
        setCurrentPage(pageCount);
    };
    const handlePrevPage = () => {
        if (currentPage !== 1 && currentPage !== 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleNextPage = () => {
        if (currentPage !== pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleSelectPage = (value: any) => {
        setCurrentPage(parseInt(value));
    };

    return (
        <>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.ContentLayout}>
                <div className={style.title}>
                    <h1>
                        <Trans>Search</Trans>
                        {`${result && (result.keyword === undefined || result.keyword == "undefined") ? "" : `『${result.keyword}』`}` +
                            `${result && result.year === undefined ? "" : `『${result.year}』`}` +
                            `${(result && result.organizationId === undefined) || resultPublicHouse[0] === undefined
                                ? ""
                                : `『${resultPublicHouse[0] && resultPublicHouse[0].name}』`
                            }` +
                            `${result && result.categoryId === undefined
                                ? ""
                                : categortResult() === undefined
                                    ? "『』"
                                    : categortResult()?.map((it: any) => `『${it.categoryName}』`)
                            }`}
                        <Trans>SearchResult</Trans>
                    </h1>
                </div>
                <div className={style.marginTOP_10}>
                    <h4>
                        <Trans>Total</Trans>&nbsp;{bookCount}&nbsp;
                        <Trans>results</Trans>
                    </h4>
                </div>

                <div className={style.ContentItem_container}>{displayData && displayData.map((it, idx) => <SearchResultCardView props={it} key={idx} />)}</div>
                {displayData && displayData.length > 0 ? (
                    <div className={style.page_row}>
                        <div className={style.page_item} onClick={() => handleFirstPage()}>
                            首頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handlePrevPage()}>
                            上一頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page}>
                            <SelectOption
                                styleType={STYLETYPE_FOUR}
                                value={currentPage.toString()}
                                firstValue={""}
                                items={handlepagecount()}
                                callBack={(value) => {
                                    // console.log("pagevalue:", value)
                                    handleSelectPage(value.name)
                                }}
                            ></SelectOption>
                            <div style={{ marginLeft: "7px" }}>/ {pageCount}</div>
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handleNextPage()}>
                            下一頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handleLastPage()}>
                            尾頁
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <DialogBox styleType={STYLETYPE_MAIN} showDialog={showDeleteDialog} title={"刪除書本資料"}></DialogBox>
        </>
    );
}
