/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SuccessResponseModel,
    SuccessResponseModelFromJSON,
    SuccessResponseModelToJSON,
    WhiteListIP,
    WhiteListIPFromJSON,
    WhiteListIPToJSON,
} from '../models';

export interface AddIpAddressUsingPOSTRequest {
    ipaddress?: string;
}

export interface DeleteIpAddressUsingDELETERequest {
    ipAddress?: string;
}

export interface TestIpAddressUsingGETRequest {
    ipAddress?: string;
}

/**
 * 
 */
export class WhiteListIpControllerApi extends runtime.BaseAPI {

    /**
     * addIpAddress
     */
    async addIpAddressUsingPOSTRaw(requestParameters: AddIpAddressUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WhiteListIP>> {
        const queryParameters: any = {};

        if (requestParameters.ipaddress !== undefined) {
            queryParameters['ipaddress'] = requestParameters.ipaddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/whiteListIP/addIpAddress`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WhiteListIPFromJSON(jsonValue));
    }

    /**
     * addIpAddress
     */
    async addIpAddressUsingPOST(requestParameters: AddIpAddressUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WhiteListIP> {
        const response = await this.addIpAddressUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteIpAddress
     */
    async deleteIpAddressUsingDELETERaw(requestParameters: DeleteIpAddressUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.ipAddress !== undefined) {
            queryParameters['ipAddress'] = requestParameters.ipAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/whiteListIP/deleteIpAddress`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * deleteIpAddress
     */
    async deleteIpAddressUsingDELETE(requestParameters: DeleteIpAddressUsingDELETERequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.deleteIpAddressUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * listAllIpAddress
     */
    async listAllIpAddressUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<WhiteListIP>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/whiteListIP/listAllIpAddress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WhiteListIPFromJSON));
    }

    /**
     * listAllIpAddress
     */
    async listAllIpAddressUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<WhiteListIP>> {
        const response = await this.listAllIpAddressUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * testIpAddress
     */
    async testIpAddressUsingGETRaw(requestParameters: TestIpAddressUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.ipAddress !== undefined) {
            queryParameters['ipAddress'] = requestParameters.ipAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/whiteListIP/testIpAddress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * testIpAddress
     */
    async testIpAddressUsingGET(requestParameters: TestIpAddressUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.testIpAddressUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
