/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CKEditorResponseDTO,
    CKEditorResponseDTOFromJSON,
    CKEditorResponseDTOToJSON,
    FileUploadResponseModel,
    FileUploadResponseModelFromJSON,
    FileUploadResponseModelToJSON,
    RedirectView,
    RedirectViewFromJSON,
    RedirectViewToJSON,
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface CkeditorUploadUsingPOSTRequest {
    ckCsrfToken: string;
    type: string;
    body?: string;
}

export interface FileEncryptionTxtForAndroidAppUsingGETRequest {
    filename: string;
}

export interface FileEncryptionTxtForAppUsingGETRequest {
    filename: string;
}

export interface FileEncryptionTxtUsingGETRequest {
    filename: string;
}

export interface FileEncryptionTxtUsingGET0Request {
    filename: string;
}

export interface FileEncryptionUsingGETRequest {
    filename: string;
}

export interface FileForAppUsingGETRequest {
    filename: string;
}

export interface FileuploadfileUsingPOSTRequest {
    body?: Blob;
}

export interface FileuploadfileUsingPOST1Request {
    body?: Blob;
}

export interface GetFileUsingGETRequest {
    filename: string;
}

/**
 * 
 */
export class FileHandlingControllerApi extends runtime.BaseAPI {

    /**
     * ckeditorUpload
     */
    async ckeditorUploadUsingPOSTRaw(requestParameters: CkeditorUploadUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CKEditorResponseDTO>> {
        if (requestParameters.ckCsrfToken === null || requestParameters.ckCsrfToken === undefined) {
            throw new runtime.RequiredError('ckCsrfToken','Required parameter requestParameters.ckCsrfToken was null or undefined when calling ckeditorUploadUsingPOST.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling ckeditorUploadUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.ckCsrfToken !== undefined) {
            queryParameters['ckCsrfToken'] = requestParameters.ckCsrfToken;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/fileupload/ckeditor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CKEditorResponseDTOFromJSON(jsonValue));
    }

    /**
     * ckeditorUpload
     */
    async ckeditorUploadUsingPOST(requestParameters: CkeditorUploadUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CKEditorResponseDTO> {
        const response = await this.ckeditorUploadUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileEncryptionTxtForAndroidApp
     */
    async fileEncryptionTxtForAndroidAppUsingGETRaw(requestParameters: FileEncryptionTxtForAndroidAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling fileEncryptionTxtForAndroidAppUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/txt/app/android/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * fileEncryptionTxtForAndroidApp
     */
    async fileEncryptionTxtForAndroidAppUsingGET(requestParameters: FileEncryptionTxtForAndroidAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.fileEncryptionTxtForAndroidAppUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * FileEncryptionTxtForApp
     */
    async fileEncryptionTxtForAppUsingGETRaw(requestParameters: FileEncryptionTxtForAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling fileEncryptionTxtForAppUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/txt/app/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * FileEncryptionTxtForApp
     */
    async fileEncryptionTxtForAppUsingGET(requestParameters: FileEncryptionTxtForAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.fileEncryptionTxtForAppUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * FileEncryptionTxt
     */
    async fileEncryptionTxtUsingGETRaw(requestParameters: FileEncryptionTxtUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling fileEncryptionTxtUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/txt/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * FileEncryptionTxt
     */
    async fileEncryptionTxtUsingGET(requestParameters: FileEncryptionTxtUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.fileEncryptionTxtUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileEncryptionTxt
     */
    async fileEncryptionTxtUsingGET_1Raw(requestParameters: FileEncryptionTxtUsingGET0Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RedirectView>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling fileEncryptionTxtUsingGET_1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/fileEncryptionTxt/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectViewFromJSON(jsonValue));
    }

    /**
     * fileEncryptionTxt
     */
    async fileEncryptionTxtUsingGET_1(requestParameters: FileEncryptionTxtUsingGET0Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RedirectView> {
        const response = await this.fileEncryptionTxtUsingGET_1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * FileEncryption
     */
    async fileEncryptionUsingGETRaw(requestParameters: FileEncryptionUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling fileEncryptionUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * FileEncryption
     */
    async fileEncryptionUsingGET(requestParameters: FileEncryptionUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.fileEncryptionUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * FileForApp
     */
    async fileForAppUsingGETRaw(requestParameters: FileForAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling fileForAppUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/app/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * FileForApp
     */
    async fileForAppUsingGET(requestParameters: FileForAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.fileForAppUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileuploadfile
     */
    async fileuploadfileUsingPOSTRaw(requestParameters: FileuploadfileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileUploadResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/fileupload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileUploadResponseModelFromJSON(jsonValue));
    }

    /**
     * fileuploadfile
     */
    async fileuploadfileUsingPOST(requestParameters: FileuploadfileUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileUploadResponseModel> {
        const response = await this.fileuploadfileUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileuploadfile
     */
    async fileuploadfileUsingPOST1Raw(requestParameters: FileuploadfileUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/fileupload/image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * fileuploadfile
     */
    async fileuploadfileUsingPOST1(requestParameters: FileuploadfileUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.fileuploadfileUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFile
     */
    async getFileUsingGETRaw(requestParameters: GetFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling getFileUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/file/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getFile
     */
    async getFileUsingGET(requestParameters: GetFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
