import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import { MarketingEventControllerApi, MarketingEventDto } from "../../../fetcher";

import * as PATH from "../../../utils/Contants";
import { BANNER, STYLETYPE_FOUR } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import style from "./BannerManagementView.module.css";
import * as Storage from "../../../utils/local-storage";
export default function BannerManagementView() {
    const navigate = useNavigate();

    const [eventData, setEventData] = useState<Array<MarketingEventDto>>();

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        getApiData();
    }, []);

    const getApiData = () => {
        new MarketingEventControllerApi(genApiConfig())
            .getAllMarketingEventUsingGET()
            .then((res: MarketingEventDto[]) => setEventData(res))
            .catch((err) => console.log(err));
    };

    return (
        <>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(PATH.ADMIN)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>

            <div className={style.RecordContainer}>
                <>
                    <div className={style.container}>
                        <div className={style.tableHeader}>
                            <h4>Banner管理</h4>

                            {/* <Button styleType={STYLETYPE_SECOND} callBack={() => navigate(PATH.MARKETING_EVENT)}>
                                新增
                            </Button> */}
                        </div>
                        <div style={{ padding: "8px" }}>
                            <div className={style.tableHeader}>
                                <div className={style.tableHeaderItemCol1}>
                                    <h6>Banner名稱</h6>
                                </div>

                                <div className={style.tableHeaderItem}>
                                    <h6>
                                        <Trans>動作</Trans>
                                    </h6>
                                </div>
                            </div>

                            <div>
                                {eventData?.map((eventObj) => {
                                    return (
                                        <div className={style.banner_contain}>
                                            {/* ***banner_row  1*/}
                                            <div className={style.banner_row}>
                                                <div className={style.Row}>
                                                    <div className={style.banner_row_text}>{eventObj.marketingEventName}</div>
                                                    <div className={style.banner_row_btn}>
                                                        <Button
                                                            styleType={STYLETYPE_FOUR}
                                                            callBack={() =>
                                                                navigate(BANNER, {
                                                                    state: eventObj,
                                                                })
                                                            }
                                                        >
                                                            <Trans>Edit</Trans>
                                                        </Button>

                                                        {/* <Button
                styleType={STYLETYPE_IMG}
                callBack={() => ""}
              >
                <img src={trashIcon} />
              </Button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                </>
            </div>
        </>
    );
}
