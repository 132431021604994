/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Environment
 */
export interface Environment {
    /**
     * 
     * @type {Array<string>}
     * @memberof Environment
     */
    activeProfiles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Environment
     */
    defaultProfiles?: Array<string>;
}

export function EnvironmentFromJSON(json: any): Environment {
    return EnvironmentFromJSONTyped(json, false);
}

export function EnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Environment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeProfiles': !exists(json, 'activeProfiles') ? undefined : json['activeProfiles'],
        'defaultProfiles': !exists(json, 'defaultProfiles') ? undefined : json['defaultProfiles'],
    };
}

export function EnvironmentToJSON(value?: Environment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeProfiles': value.activeProfiles,
        'defaultProfiles': value.defaultProfiles,
    };
}

