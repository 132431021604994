/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Module,
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import {
    Package,
    PackageFromJSON,
    PackageFromJSONTyped,
    PackageToJSON,
} from './Package';

/**
 * 
 * @export
 * @interface ClassLoader
 */
export interface ClassLoader {
    /**
     * 
     * @type {Array<Package>}
     * @memberof ClassLoader
     */
    definedPackages?: Array<Package>;
    /**
     * 
     * @type {string}
     * @memberof ClassLoader
     */
    name?: string;
    /**
     * 
     * @type {ClassLoader}
     * @memberof ClassLoader
     */
    parent?: ClassLoader;
    /**
     * 
     * @type {boolean}
     * @memberof ClassLoader
     */
    registeredAsParallelCapable?: boolean;
    /**
     * 
     * @type {Module}
     * @memberof ClassLoader
     */
    unnamedModule?: Module;
}

export function ClassLoaderFromJSON(json: any): ClassLoader {
    return ClassLoaderFromJSONTyped(json, false);
}

export function ClassLoaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassLoader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definedPackages': !exists(json, 'definedPackages') ? undefined : ((json['definedPackages'] as Array<any>).map(PackageFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parent': !exists(json, 'parent') ? undefined : ClassLoaderFromJSON(json['parent']),
        'registeredAsParallelCapable': !exists(json, 'registeredAsParallelCapable') ? undefined : json['registeredAsParallelCapable'],
        'unnamedModule': !exists(json, 'unnamedModule') ? undefined : ModuleFromJSON(json['unnamedModule']),
    };
}

export function ClassLoaderToJSON(value?: ClassLoader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definedPackages': value.definedPackages === undefined ? undefined : ((value.definedPackages as Array<any>).map(PackageToJSON)),
        'name': value.name,
        'parent': ClassLoaderToJSON(value.parent),
        'registeredAsParallelCapable': value.registeredAsParallelCapable,
        'unnamedModule': ModuleToJSON(value.unnamedModule),
    };
}

