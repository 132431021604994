/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Package
 */
export interface Package {
    /**
     * 
     * @type {Array<object>}
     * @memberof Package
     */
    annotations?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Package
     */
    declaredAnnotations?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    implementationTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    implementationVendor?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    implementationVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Package
     */
    sealed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    specificationTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    specificationVendor?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    specificationVersion?: string;
}

export function PackageFromJSON(json: any): Package {
    return PackageFromJSONTyped(json, false);
}

export function PackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Package {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'declaredAnnotations': !exists(json, 'declaredAnnotations') ? undefined : json['declaredAnnotations'],
        'implementationTitle': !exists(json, 'implementationTitle') ? undefined : json['implementationTitle'],
        'implementationVendor': !exists(json, 'implementationVendor') ? undefined : json['implementationVendor'],
        'implementationVersion': !exists(json, 'implementationVersion') ? undefined : json['implementationVersion'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sealed': !exists(json, 'sealed') ? undefined : json['sealed'],
        'specificationTitle': !exists(json, 'specificationTitle') ? undefined : json['specificationTitle'],
        'specificationVendor': !exists(json, 'specificationVendor') ? undefined : json['specificationVendor'],
        'specificationVersion': !exists(json, 'specificationVersion') ? undefined : json['specificationVersion'],
    };
}

export function PackageToJSON(value?: Package | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'declaredAnnotations': value.declaredAnnotations,
        'implementationTitle': value.implementationTitle,
        'implementationVendor': value.implementationVendor,
        'implementationVersion': value.implementationVersion,
        'name': value.name,
        'sealed': value.sealed,
        'specificationTitle': value.specificationTitle,
        'specificationVendor': value.specificationVendor,
        'specificationVersion': value.specificationVersion,
    };
}

