import React, { useEffect, useMemo, useRef, useState } from "react";
import "./VideoReader.css";
import SampleImage from "./coverImage.PNG";
import previousIcon from "./asset/previous.svg";
import nextIcon from "./asset/next.svg";
import pauseIcon from "./asset/pause.svg";
import playIcon from "./asset/play.svg";
import backwardIcon from "./asset/backward.svg";
import forwardIcon from "./asset/forward.svg";
import waveIcon from "./asset/wave.svg";
import closeIcon from "./asset/close.svg";
import tocIcon from "./asset/menu.svg";
import backIcon from "./asset/left.svg";
// import video_highIcon from "./asset/video_high.svg";
// import video_middleIcon from "./asset/video_middle.svg";
// import video_lowIcon from "./asset/video_low.svg";
// import video_muteIcon from "./asset/video_mute.svg";
// import video1 from "./videos/1.wav";
// import video2 from "./videos/2.wav";
// import video3 from "./videos/3.wav";
// import video4 from "./videos/4.wav";
// #DB7940
// import videoBookConfig from "./videoBookConfig.json";
import localforage from "localforage";
import api from "../../../api/api";
import * as PATH from "../../../utils/Contants";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import { Trans } from "react-i18next";
import useWindowSize from "../../../utils/useWindowSize";
import parseSRT from 'parse-srt'
import wavePlayingGif from "../../../assets/svg/wave_playing.gif"
import LoadingView from "../../../layout/loading/LoadingView";
import {ProductControllerApi, ReaderLogControllerApi} from "../../../fetcher";
import { genApiConfig } from "../../../utils/fetch-caller";
import { getArgsQuery } from "../../../utils/GlobalFunction";
import {useParams} from "react-router-dom";


export const flattenChapters = (chaptersList, tocList, level) => {
  if (chaptersList?.length > 0) {
    chaptersList.map((chapter) => {
      tocList.push({
        title: chapter.title,
        videoFilePath: chapter.videoFilePath || null,
        srtFilePath: chapter.srtFilePath || null,
        covers:
          chapter.covers?.sort((a, b) => {
            return a.appearTime - b.appearTime;
          }) || null,
        level: level,
      });
      if (chapter?.sub?.length > 0) {
        flattenChapters(chapter.sub, tocList, level + 1);
      }
    });
  }
  return tocList;
};
// const sampleVideoFiles = [video1, video2, video3, video4];
const videoSkipSeconds = 15;
export const VideoReader = () => {
  const { width, height } = useWindowSize();
  const params = useParams();
  const [isShowTOC, setIsShowTOC] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [playingTOCIndex, setPlayingTOCIndex] = useState(0);
  const [nextCoverIndex, setNextCoverIndex] = useState(0);
  const [displayingImage, setDisplayingImage] = useState("");
  const [videoBookData, setVideoBookData] = useState({});
  const [updateProgressBarIntervalId, setUpdateProgressBarIntervalId] =
    useState(null);
  const [toc, setTOC] = useState([]);

  const [subTitle, setSubTitle] = useState([])

  const [isLoading, setIsLoading] = useState(true);

  const [isPreview, setIsPreview] = useState(true);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  const [readTime, setReadTime] = useState(0);
  const [progressControlling, setProgressControlling] = useState(false);

  const [progressHover, setProgressHover] = useState(false);

  const videoRef = useRef(null);

  const [sap, setSap] = useState("");

  const tocButtonOnClick = () => {
    setIsShowTOC(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      backward();
    } else if (e.key === "ArrowRight") {
      fastForward();
    } else if (e.key === "p" || e.key === " ") {
      if (videoRef.current.paused) {
        playOnClick()
      } else {
        pauseOnClick()
      }
    }
    /* else if (e.key === "ArrowUp") {
      if (videoRef.current.muted) videoRef.current.muted = false
      videoRef.current.volume = Math.min(1, videoRef.current.volume + 0.1);
      console.log("videoRef.current.volume", videoRef.current.volume)
    } else if (e.key === "ArrowDown") {
      videoRef.current.volume = Math.max(0, videoRef.current.volume - 0.1);
      console.log("videoRef.current.volume", videoRef.current.volume)
    } else if (e.key === "m") {
      if (videoRef.current.muted) {
        videoRef.current.muted = false
      } else {
        videoRef.current.muted = true
      }
    } else {
      console.log("UnKnown Key Pressed", e.key)
    }*/
  }

  useEffect(() => {
    if (document.getElementById("progressTrigger") && document.getElementById("progressBar")) {
      document.getElementById("progressTrigger").style.display = "block";
      document.getElementById("progressTrigger").style.top = (document.getElementById('progressBar').getBoundingClientRect().top - 5) + 'px';
      document.getElementById("progressTrigger").style.left = (document.getElementById('progressBar').getBoundingClientRect().left - 10) + 'px';
    }

    if (document.getElementById("progressTrigger")) {
      document.getElementById("progressTrigger").style.left = ((document.getElementById('progressBar').getBoundingClientRect().left + document.getElementById("progressBar").offsetWidth * (progressBarValue / 100)) - 10) + 'px';
    }

    if (document.getElementById("progressHoverTime") && document.getElementById("progressTrigger") && document.getElementById("progressBar")) {
      document.getElementById("progressHoverTime").style.top = (document.getElementById('progressTrigger').getBoundingClientRect().top - 25) + 'px';
    }

    document.addEventListener("keydown", handleKeyDown)
    return (() => {
      document.removeEventListener("keydown", handleKeyDown)
    })
  })

  useEffect(() => {
    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    let getSapArticleNumber = async () => {
      let res = await new ProductControllerApi(genApiConfig())
        .getProductByProductIdUsingGET({ productId: parseInt(getArgsQuery().bookId) })

      return res.sapArticleNumber
    }

    getSapArticleNumber().then(sap => {
      setSap(sap)
    })

    if (readerConfigObj.rm === "p") {
      setIsPreview(true)
    } else {
      setIsPreview(false)
      let userInfo = localStorage.getItem("userInfo");
      let userInfoObj = JSON.parse(userInfo);
      let bookId = getArgsQuery().bookId
      if (bookId && bookId !== undefined && bookId.length > 0) {
        new ReaderLogControllerApi(genApiConfig())
          .getLogUsingGET({
            memberId: userInfoObj.member.memberId,
            productId: bookId
          }).then(res => {
            let readerlog = JSON.parse(res.content)
            console.log("readerlog", readerlog)
            if (readerlog.currentChapter) {
              console.log("setPlayingTOCIndex(readerlog.currentChapter - 1)")
              setPlayingTOCIndex(readerlog.currentChapter - 1)
              videoRef.current.currentTime = readerlog.currentTime
            }
          })
      }
    }
    /*let videoData = */
    localforage.getItem("video").then((videoData) => {
      if (window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] !== videoData['bookIsbn']) {
        alert("系統出錯，請再重新開啟書本");
        window.close();
      }
      setVideoBookData(videoData)
      // setIsLoading(false)
    })

    const interval = setInterval(() => setReadTime((t) => t + 1), 1000);
    return () => clearInterval(interval);

  }, [])


  useEffect(() => {
    let userInfo = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfo);
    let bookId = getArgsQuery().bookId
    if (!isPreview && readTime % 10 === 0 && bookId && bookId !== undefined && bookId.length > 0 && true) {
      new ReaderLogControllerApi(genApiConfig())
        .saveLogUsingPOST({
          memberId: userInfoObj.member.memberId,
          productId: bookId,
          body: {
            percentage: (playingTOCIndex + 1) / toc.length,
            currentTime: videoRef.current.currentTime,
            currentChapter: playingTOCIndex + 1
          },
        })
      // .then(res => { console.log("saveLogUsingPOST", res) })
    }
  }, [readTime])

  const tocCancelButtonOnClick = () => {
    setIsShowTOC(false);
  };

  const playOnClick = () => {
    videoRef.current.play();
    setIsPlaying(!videoRef.current.paused);
    const intervalId = setInterval(() => {
      setProgressBarValue(
        (videoRef.current.currentTime / videoRef.current.duration) * 100
      );
    }, 1000);
    setUpdateProgressBarIntervalId(intervalId);
  };

  const pauseOnClick = () => {
    console.log(videoRef.current.currentTime);
    setIsPlaying(false);
    videoRef.current.pause();
    clearInterval(updateProgressBarIntervalId);
  };

  const handleVideoEnded = () => {
    setProgressBarValue(100);
    clearInterval(updateProgressBarIntervalId);
    setIsPlaying(false);
  };

  const fastForward = () => {
    if (
      videoRef.current.currentTime + videoSkipSeconds <=
      videoRef.current.duration
    ) {
      videoRef.current.currentTime =
        videoRef.current.currentTime + videoSkipSeconds;
    } else {
      videoRef.current.currentTime = videoRef.current.duration;
    }
    setProgressBarValue(
      (videoRef.current.currentTime / videoRef.current.duration) * 100
    );
  };

  const backward = () => {
    if (videoRef.current.currentTime - videoSkipSeconds >= 0) {
      videoRef.current.currentTime =
        videoRef.current.currentTime - videoSkipSeconds;
    } else {
      videoRef.current.currentTime = 0;
    }
    setProgressBarValue(
      (videoRef.current.currentTime / videoRef.current.duration) * 100
    );
  };

  const getNextTOCVideoIndex = () => {
    if (playingTOCIndex < toc.length - 1) {
      const restOfTOC = toc.slice(playingTOCIndex + 1);
      let nextVideoTOCIndex = restOfTOC.findIndex(
        (chapter) => chapter.videoFilePath
      );
      if (nextVideoTOCIndex >= 0) {
        nextVideoTOCIndex = nextVideoTOCIndex + playingTOCIndex + 1;
        return nextVideoTOCIndex;
      } else {
        return playingTOCIndex;
      }
    }
    return playingTOCIndex;
  };

  const getPrevTOCVideoIndex = () => {
    if (playingTOCIndex > 0) {
      const prevPortionOfTOC = toc.slice(0, playingTOCIndex);
      let prevVideoTOCIndex = playingTOCIndex;
      for (let i = prevPortionOfTOC.length - 1; i >= 0; i--) {
        if (prevPortionOfTOC[i].videoFilePath) {
          prevVideoTOCIndex = i;
          break;
        }
      }
      return prevVideoTOCIndex;
    }
    return playingTOCIndex;
  };

  useEffect(() => {
    pauseOnClick()
    if(sap){
      if (toc[playingTOCIndex] !== undefined) {
        if (toc[playingTOCIndex].srtFilePath && toc[playingTOCIndex].srtFilePath !== undefined && toc[playingTOCIndex].srtFilePath !== "") {
          // api.getFileByStringWithType(toc[playingTOCIndex].srtFilePath, "srt").then(async (blob) => {
          api.getEncryptionFileByString(sap, "srt", toc[playingTOCIndex].srtFilePath).then(async (blob) => {
            blob.text().then((result) => {
              setSubTitle(parseSRT(result))
            })
          })
        } else {
          setSubTitle([])
        }
        console.log("toc[playingTOCIndex].videoFilePath", toc[playingTOCIndex].videoFilePath)
        if (toc[playingTOCIndex].videoFilePath && toc[playingTOCIndex].videoFilePath !== undefined && toc[playingTOCIndex].videoFilePath !== "") {
          api.getEncryptionFileByString(sap, "multimedia", toc[playingTOCIndex].videoFilePath).then(async (blob) => {
            videoRef.current.src = URL.createObjectURL(blob)
            resetVideo();
          })
          // videoRef.current.src = `${process.env.REACT_APP_BACKEND}/file/${toc[playingTOCIndex].videoFilePath}`
          // resetVideo();
        } else {
        }
      } else {
        setSubTitle([])
      }
    }
  }, [playingTOCIndex, toc, sap])

  const nextVideo = () => {
    setIsLoading(true)
    // if (!isPlaying) {
    const nextTOCVideoIndex = getNextTOCVideoIndex();
    setPlayingTOCIndex(nextTOCVideoIndex);
    // resetVideo();
    // }
  };

  const prevVideo = () => {
    setIsLoading(true)
    // if (!isPlaying) {
    const prevTOCVideoIndex = getPrevTOCVideoIndex();
    setPlayingTOCIndex(prevTOCVideoIndex);
    // resetVideo();
    // }
  };

  const resetVideo = () => {
    if (updateProgressBarIntervalId) {
      clearInterval(updateProgressBarIntervalId);
    }
    setProgressBarValue(0);
    if (isPlaying) {
      setIsPlaying(false);
    }
    videoRef.current.load();
    playOnClick();
    setIsLoading(false);
  };

  const flatChapters = useMemo(() => {
    return flattenChapters(videoBookData.list, [], 0);
  }, [videoBookData]);

  const updateCoverImages = () => {
    const currentTime = videoRef.current.currentTime;
    const covers = toc[playingTOCIndex].covers;
    if (
      covers &&
      covers.length > 0 &&
      nextCoverIndex < covers.length &&
      currentTime > covers[nextCoverIndex].appearTime &&
      covers[nextCoverIndex].coverFilePath
    ) {
      const imagePath = api.getFileUrl(sap, "cover", covers[nextCoverIndex].coverFilePath, "x-oss-process=image/resize,h_500")
      setDisplayingImage(imagePath);
      setNextCoverIndex(nextCoverIndex + 1);
    }
  };
  const chapterNameOnClick = (index) => {
    setIsLoading(true)
    // if (!isPlaying) {
    setPlayingTOCIndex(index);
    // }
    // resetVideo();
    tocCancelButtonOnClick();
  };

  useEffect(() => {
    if (flatChapters.length > 0) {
      setTOC(flatChapters);

      const firstVideoIndex = flatChapters.findIndex(
        (chapter) => !!chapter.videoFilePath
      );
      const defaultCoverImagePath = api.getFileUrl(sap, "cover", videoBookData.cover, "x-oss-process=image/resize,h_500")
      // process.env.REACT_APP_BACKEND +
      // "/file/" + videoBookData.cover;
      setDisplayingImage(defaultCoverImagePath);
      // console.log("setPlayingTOCIndex(firstVideoIndex);", firstVideoIndex)
      // setPlayingTOCIndex(firstVideoIndex);
    }
  }, [flatChapters]);

  const progressMouseHandler = (e) => {
    videoRef.current.currentTime = ((e.clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById('progressBar').offsetWidth) * videoRef.current.duration

    if (e.clientX < document.getElementById('progressBar').getBoundingClientRect().left) {
      setProgressBarValue(0)
    } else if (e.clientX > document.getElementById('progressBar').getBoundingClientRect().right) {
      setProgressBarValue(100)
    } else {
      setProgressBarValue(((e.clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById("progressBar").offsetWidth) * 100)
    }
  }

  const progressTouchHandler = (e) => {
    videoRef.current.currentTime = ((e.touches[e.touches.length - 1].clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById("progressBar").offsetWidth) * videoRef.current.duration

    if (e.touches[e.touches.length - 1].clientX < document.getElementById('progressBar').getBoundingClientRect().left) {
      setProgressBarValue(0)
    } else if (e.touches[e.touches.length - 1].clientX > document.getElementById('progressBar').getBoundingClientRect().right) {
      setProgressBarValue(100)
    } else {
      setProgressBarValue(((e.touches[e.touches.length - 1].clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById("progressBar").offsetWidth) * 100)
    }
  }

  const handleProgressTouchMove = (e) => {
    if (progressControlling) {
      progressTouchHandler(e)
    }
  }

  const handleProgressMouseMove = (e) => {
    setHovenTime(e)
    if (progressControlling || e.clientX <= document.getElementById('progressBar').getBoundingClientRect().left || e.clientX >= document.getElementById('progressBar').getBoundingClientRect().right) {
      document.getElementById("progressHoverTime").style.left = (document.getElementById('progressTrigger').getBoundingClientRect().left - 10) + 'px';
    } else {
      document.getElementById("progressHoverTime").style.left = (e.clientX - 20) + 'px';
    }
    if (progressControlling) {
      progressMouseHandler(e)
    }
  }

  const setHovenTime = (e) => {
    if (progressHover || progressControlling) {
      // console.log("setHovenTime", e.clientX)
      if (e.clientX <= document.getElementById('progressBar').getBoundingClientRect().left) {
        document.getElementById("progressHoverTime").innerHTML = "00:00"
      } else if (e.clientX >= document.getElementById('progressBar').getBoundingClientRect().right) {
        document.getElementById("progressHoverTime").innerHTML = Math.floor(videoRef.current.duration / 60).toString().padStart(2, '0') + ":" + Math.floor(videoRef.current.duration % 60).toString().padStart(2, '0')
      } else {
        let hoverTime = ((e.clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById("progressBar").offsetWidth) * videoRef.current.duration
        document.getElementById("progressHoverTime").innerHTML = Math.floor(hoverTime / 60).toString().padStart(2, '0') + ":" + Math.floor(hoverTime % 60).toString().padStart(2, '0')
      }
    }
  }

  return (
    <div className="videoBookContainer" id="videoBookContainerBase"
      onMouseUp={(e) => setProgressControlling(false)}
      onTouchEnd={(e) => setProgressControlling(false)}
      onMouseMove={(e) => handleProgressMouseMove(e)}
      onTouchMove={(e) => { handleProgressTouchMove(e) }}
    // onKeyDown={(e) => console.log("e", e)}
    >
      <div className="videoBookTopBar" onClick={() => window.confirm("是否關閉閱讀器？") ? window.close() : ""}>
        <button className="videoBookBackButton">
          <img
            src={backIcon}
            alt="Back"
          // className="videoBookControlSmallIcon"
          />
        </button>
        <span>關閉閱讀器</span>
      </div>
      <div className="videoBookPlayerContainer">
        {
          isShowTOC || width >= 1024 ? (
            <div className={width >= 1024 ? "topicController" : "topicControllerPhone"} onClick={() => width >= 1024 ? isShowTOC : setIsShowTOC(false)}>
              <div className={width >= 1024 ? "videoBookChapterList" : "videoBookChapterListPhone"}>
                <div className="videoBookTOCTopSection">
                  <h5 style={{ color: "#000000" }}>目錄</h5>
                  {
                    width >= 1024 ? <></> : (
                      <button
                        onClick={tocCancelButtonOnClick}
                        className="videoBookControlButton"
                      >
                        <img src={closeIcon} alt="Close" className="" />
                      </button>
                    )
                  }
                </div>
                <div className="videoBookChapterItemsContainer">
                  {toc?.map((chapter, index) => {
                    return (
                      <div
                        className={
                          chapter.level === 0
                            ? "videoBookChapterGroupTitle"
                            : "videoBookChapterItem"
                        }
                        style={isPreview && index > 0 ? {
                          marginLeft: `${chapter.level * 8}px`,
                          cursor: chapter.videoFilePath ? "pointer" : "",
                          padding: "0 10px",
                          color: "#ccc",
                        } : {
                          marginLeft: `${chapter.level * 8}px`,
                          cursor: chapter.videoFilePath ? "pointer" : "",
                          padding: "0 10px",
                        }
                        }
                        onClick={
                          chapter.videoFilePath &&
                          (() => isPreview && index > 0 ? setShowPreviewDialog(true) : playingTOCIndex !== index ? chapterNameOnClick(index) : tocCancelButtonOnClick())
                        }
                        key={chapter.title}
                      >
                        <span className="videoBookChapterTitle">
                          {chapter.title}
                        </span>
                        {chapter.title === toc[playingTOCIndex]?.title && (
                          isPlaying ?
                            <img src={wavePlayingGif} alt="wave" />
                            :
                            <img src={waveIcon} alt="wave" />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : <></>
        }


        <div className="videoBookController">
          <div
            className="VideoReaderBox"
          >
            <div
              className="VideoReaderBoxVideo"
            >
              <div className="videoBookControllerTopSection">
                <div className="videoBookDetail">
                  <div className="videoBookName">
                    {videoBookData.bookname}
                  </div>
                  <div className="videoBookAuthor">
                    {videoBookData.author}
                  </div>
                </div>
                {
                  width >= 1024 ? <></> : (
                    <button
                      onClick={tocButtonOnClick}
                      className="videoBookControlButton"
                    >
                      <img src={tocIcon} alt="TOC" />
                    </button>
                  )
                }
              </div>
              <div className="videoBookChapter">
                {toc[playingTOCIndex]?.title}
              </div>
              <div className="videoBookCoverImageContainer">
                <div className="videoBookCoverImage">
                  {/* <img
                        className={"coverimg"}
                        src={displayingImage ? displayingImage : ""}
                        alt="Cover Image"
                        style={{ width: "100%", height: "100%" }}
                      /> */}
                  <video
                    playsInline
                    ref={videoRef}
                    onEnded={handleVideoEnded}
                    onTimeUpdate={updateCoverImages}
                    style={{ height: "100%", width: "100%" }}
                    poster={displayingImage}
                    type="video/mp4"
                  />
                </div>

              </div>
              {
                subTitle.length > 0 ? (
                  //<div className={"videoBookSubTitleContainer"}>
                  //{
                  videoRef.current !== undefined ? (
                    subTitle.filter((value) => videoRef.current.currentTime < value.end).map((st, idx) =>
                      idx < 1 ? (
                        <div className="videoBookSrt" style={{ "flexGrow": "1" }}>
                          {
                            <h1 style={videoRef.current.currentTime >= st.start ? { "color": "#DB7940" } : {}} dangerouslySetInnerHTML={{ __html: st.text }}></h1>
                          }
                        </div>
                      ) : <></>
                    )
                  ) : <></>
                  //     }
                  //   </div>
                ) : <></>
              }
              <div className="videoBookProgressBarContainer">
                {/* {
                    videoRef && videoRef !== undefined && videoRef.current !== null && videoRef.current !== undefined ? (
                      <button
                        onClick={
                          videoRef.current.muted || videoRef.current.volume <= 0 ? () => {
                            if (videoRef.current.muted) {
                              videoRef.current.muted = false
                            } else {
                              videoRef.current.volume = 0.1
                            }
                            setUnUsedState(unUsedState)
                          } : () => {
                            videoRef.current.muted = true
                            setUnUsedState(unUsedState)
                          }}
                        className="videoBookControlButton"
                      >
                        <img src={videoRef.current.muted || videoRef.current.volume <= 0 ? video_muteIcon : videoRef.current.volume <= 0.33 ? video_lowIcon : videoRef.current.volume <= 0.66 ? video_middleIcon : video_highIcon} alt="video_muteIcon" className="videoBookControlSmallIcon" />
                      </button>
                    ) : <></>
                  } */}
                <progress value={progressBarValue} max="100" id="progressBar"
                  onMouseDown={(e) => {
                    progressMouseHandler(e)
                    setProgressControlling(true)
                  }}
                  onTouchStart={(e) => {
                    progressTouchHandler(e)
                    setProgressControlling(true)
                  }}
                  onMouseUp={(e) => setProgressControlling(false)} onTouchEnd={(e) => setProgressControlling(false)}
                  onMouseOver={(e) => {
                    setProgressHover(true)
                    setHovenTime(e)
                  }}
                  onMouseOut={(e) => setProgressHover(false)}
                />
                {
                  videoRef && videoRef !== undefined && videoRef.current !== null && videoRef.current !== undefined ?
                    <div>
                      {
                        Math.floor(videoRef.current.currentTime / 60).toString().padStart(2, '0')
                      }:{
                        Math.floor(videoRef.current.currentTime % 60).toString().padStart(2, '0')
                      }/{
                        videoRef.current.duration ? Math.floor(videoRef.current.duration / 60).toString().padStart(2, '0') : "00"
                      }:{
                        videoRef.current.duration ? Math.floor(videoRef.current.duration % 60).toString().padStart(2, '0') : "00"
                      }
                    </div>
                    : <></>
                }

              </div>
            </div>
            <div className="videoBookControlButtonsContainer">
              {
                isPreview ? <></> : (
                  <button className="videoBookControlButton" onClick={prevVideo}>
                    <img
                      src={previousIcon}
                      alt="Previous"
                      className="videoBookControlSmallIcon"
                    />
                  </button>
                )
              }
              <button className="videoBookControlButton" onClick={backward}>
                <img
                  src={backwardIcon}
                  alt="Backward"
                  className="videoBookControlSmallIcon"
                />
              </button>
              <button
                className="videoBookControlButton"
                onClick={isPlaying ? pauseOnClick : playOnClick}
              >
                <img
                  src={isPlaying ? pauseIcon : playIcon}
                  alt={isPlaying ? "Pause" : "Play"}
                  className="videoBookControlLargeIcon"
                />
              </button>
              <button className="videoBookControlButton" onClick={fastForward}>
                <img
                  src={forwardIcon}
                  alt="Forward"
                  className="videoBookControlSmallIcon"
                />
              </button>
              {
                isPreview ? <></> : (
                  <button className="videoBookControlButton" onClick={nextVideo}>
                    <img
                      src={nextIcon}
                      alt="Next"
                      className="videoBookControlSmallIcon"
                    />
                  </button>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title="只能試閱第一集"
        showDialog={showPreviewDialog}
        children={
          <div className="dialogButtonContainer">
            <Button
              styleType={"submitButton"}
              callBack={() => {
                setShowPreviewDialog(false)
              }}
            >
              <Trans>好</Trans>
            </Button>
          </div>
        }
      />
      {
        isLoading ? <LoadingView /> : <></>
      }
      <div /*style={{ backgroundColor: "#DB7940", height: "20px", width: "20px", borderRadius: "50%", display: "none", position: "absolute", border: "solid grey" }}*/ id="progressTrigger" className="progressTrigger"
        onMouseDown={(e) => {
          progressMouseHandler(e)
          setProgressControlling(true)
        }}
        onTouchStart={(e) => {
          progressTouchHandler(e)
          setProgressControlling(true)
        }}
        onMouseUp={(e) => setProgressControlling(false)} onTouchEnd={(e) => setProgressControlling(false)}
        onMouseOver={(e) => {
          setProgressHover(true)
          setHovenTime(e)
        }}
        onMouseOut={(e) => setProgressHover(false)}
      />
      <div style={{ display: progressHover || progressControlling ? "block" : "none", /*backgroundColor: "#535353", height: "20px", width: "40px", borderRadius: "10px", position: "absolute", color: "white", fontSize: "13px", textAlign: "center", paddingTop: "2px"*/ }} id="progressHoverTime" className="progressHoverTime" >00:00</div>
    </div >
  );
};
