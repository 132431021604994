/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CategoryNameConflict,
    CategoryNameConflictFromJSON,
    CategoryNameConflictToJSON,
    CategoryNameConflictPayloadList,
    CategoryNameConflictPayloadListFromJSON,
    CategoryNameConflictPayloadListToJSON,
    CategoryNameConflictResponse,
    CategoryNameConflictResponseFromJSON,
    CategoryNameConflictResponseToJSON,
    CategoryParentConflict,
    CategoryParentConflictFromJSON,
    CategoryParentConflictToJSON,
    CategoryParentConflictPayloadList,
    CategoryParentConflictPayloadListFromJSON,
    CategoryParentConflictPayloadListToJSON,
    CategoryParentConflictResponse,
    CategoryParentConflictResponseFromJSON,
    CategoryParentConflictResponseToJSON,
    SuccessResponseModel,
    SuccessResponseModelFromJSON,
    SuccessResponseModelToJSON,
} from '../models';

export interface AddCategoryNameConflictUsingPOSTRequest {
    mongoId?: string;
    cmsName?: string;
    libName?: string;
}

export interface AddCategoryParentConflictUsingPOSTRequest {
    mongoId?: string;
    cmsParentMongoId?: string;
    libParentMongoId?: string;
}

export interface SelectCategoryNameUsingPOSTRequest {
    categoryNameConflictPayloadList?: CategoryNameConflictPayloadList;
}

export interface SelectCategoryParentUsingPOSTRequest {
    categoryParentConflictPayloadList?: CategoryParentConflictPayloadList;
}

/**
 * 
 */
export class CategoryConflictControllerApi extends runtime.BaseAPI {

    /**
     * addCategoryNameConflict
     */
    async addCategoryNameConflictUsingPOSTRaw(requestParameters: AddCategoryNameConflictUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CategoryNameConflict>> {
        const queryParameters: any = {};

        if (requestParameters.mongoId !== undefined) {
            queryParameters['mongoId'] = requestParameters.mongoId;
        }

        if (requestParameters.cmsName !== undefined) {
            queryParameters['cmsName'] = requestParameters.cmsName;
        }

        if (requestParameters.libName !== undefined) {
            queryParameters['libName'] = requestParameters.libName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/category_conflict/addCategoryNameConflict`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryNameConflictFromJSON(jsonValue));
    }

    /**
     * addCategoryNameConflict
     */
    async addCategoryNameConflictUsingPOST(requestParameters: AddCategoryNameConflictUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CategoryNameConflict> {
        const response = await this.addCategoryNameConflictUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * addCategoryParentConflict
     */
    async addCategoryParentConflictUsingPOSTRaw(requestParameters: AddCategoryParentConflictUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CategoryParentConflict>> {
        const queryParameters: any = {};

        if (requestParameters.mongoId !== undefined) {
            queryParameters['mongoId'] = requestParameters.mongoId;
        }

        if (requestParameters.cmsParentMongoId !== undefined) {
            queryParameters['cmsParentMongoId'] = requestParameters.cmsParentMongoId;
        }

        if (requestParameters.libParentMongoId !== undefined) {
            queryParameters['libParentMongoId'] = requestParameters.libParentMongoId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/category_conflict/addCategoryParentConflict`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryParentConflictFromJSON(jsonValue));
    }

    /**
     * addCategoryParentConflict
     */
    async addCategoryParentConflictUsingPOST(requestParameters: AddCategoryParentConflictUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CategoryParentConflict> {
        const response = await this.addCategoryParentConflictUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllCategoryNameConflict
     */
    async getAllCategoryNameConflictUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CategoryNameConflictResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/category_conflict/getAllCategoryNameConflict`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryNameConflictResponseFromJSON));
    }

    /**
     * getAllCategoryNameConflict
     */
    async getAllCategoryNameConflictUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CategoryNameConflictResponse>> {
        const response = await this.getAllCategoryNameConflictUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllCategoryParentConflict
     */
    async getAllCategoryParentConflictUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CategoryParentConflictResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/category_conflict/getAllCategoryParentConflict`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryParentConflictResponseFromJSON));
    }

    /**
     * getAllCategoryParentConflict
     */
    async getAllCategoryParentConflictUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CategoryParentConflictResponse>> {
        const response = await this.getAllCategoryParentConflictUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * selectCategoryName
     */
    async selectCategoryNameUsingPOSTRaw(requestParameters: SelectCategoryNameUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/category_conflict/selectCategoryName`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryNameConflictPayloadListToJSON(requestParameters.categoryNameConflictPayloadList),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * selectCategoryName
     */
    async selectCategoryNameUsingPOST(requestParameters: SelectCategoryNameUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.selectCategoryNameUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * selectCategoryParent
     */
    async selectCategoryParentUsingPOSTRaw(requestParameters: SelectCategoryParentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/category_conflict/selectCategoryParent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryParentConflictPayloadListToJSON(requestParameters.categoryParentConflictPayloadList),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * selectCategoryParent
     */
    async selectCategoryParentUsingPOST(requestParameters: SelectCategoryParentUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.selectCategoryParentUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
