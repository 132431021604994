/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BookBorrowStatusDto,
    BookBorrowStatusDtoFromJSON,
    BookBorrowStatusDtoToJSON,
    BorrowDto,
    BorrowDtoFromJSON,
    BorrowDtoToJSON,
    IntegerListRequestModel,
    IntegerListRequestModelFromJSON,
    IntegerListRequestModelToJSON,
    MyBookshelfDto,
    MyBookshelfDtoFromJSON,
    MyBookshelfDtoToJSON,
    MyBorrowStatus,
    MyBorrowStatusFromJSON,
    MyBorrowStatusToJSON,
    PageBorrowDto,
    PageBorrowDtoFromJSON,
    PageBorrowDtoToJSON,
    ReserveBorrow,
    ReserveBorrowFromJSON,
    ReserveBorrowToJSON,
    ReserveBorrowDto,
    ReserveBorrowDtoFromJSON,
    ReserveBorrowDtoToJSON,
    ResumeBorrowMissionResponseModel,
    ResumeBorrowMissionResponseModelFromJSON,
    ResumeBorrowMissionResponseModelToJSON,
    SuccessResponseModel,
    SuccessResponseModelFromJSON,
    SuccessResponseModelToJSON,
} from '../models';

export interface BorrowBookUsingPOSTRequest {
    memberId: number;
    productId: number;
}

export interface BorrowedBookMoreThanMaxUsingPOSTRequest {
    memberId: number;
}

export interface CanResumeBorrowDateCheckUsingPOSTRequest {
    memberId: number;
    productId: number;
}

export interface CanResumeBorrowUsingPOSTRequest {
    memberId: number;
    productId: number;
}

export interface CancelReservedBookUsingDELETERequest {
    memberId: number;
    productId: number;
}

export interface CheckNoReserveUsingPOSTRequest {
    memberId: number;
    productId: number;
}

export interface GetMyBookshelfUsingPOSTRequest {
    memberId: number;
}

export interface ListAllMyBorrowUsingPOSTRequest {
    memberId: number;
    pageNo: number;
    pageSize: number;
}

export interface ListAllMyReserveBookUsingPOSTRequest {
    memberId: number;
}

export interface ListMyBorrowUsingPOSTRequest {
    memberId: number;
}

export interface ListReserveByBookUsingGETRequest {
    productId: number;
}

export interface ProductBorrowMyStatusUsingPOSTRequest {
    productId: number;
    memberId: number;
}

export interface ProductBorrowStatusListUsingPOSTRequest {
    integerListRequestModel?: IntegerListRequestModel;
}

export interface ProductBorrowStatusUsingPOSTRequest {
    productId: number;
}

export interface ReserveBookUsingPOSTRequest {
    memberId: number;
    productId: number;
    email: string;
}

export interface ReservedBookMoreThanMaxUsingPOSTRequest {
    memberId: number;
}

export interface ResumeBorrowUsingPOSTRequest {
    memberId: number;
    productId: number;
}

export interface ReturnBookUsingPOSTRequest {
    memberId: number;
    productId: number;
}

/**
 * 
 */
export class BorrowControllerApi extends runtime.BaseAPI {

    /**
     * 借書
     */
    async borrowBookUsingPOSTRaw(requestParameters: BorrowBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BorrowDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling borrowBookUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling borrowBookUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/borrowBook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BorrowDtoFromJSON(jsonValue));
    }

    /**
     * 借書
     */
    async borrowBookUsingPOST(requestParameters: BorrowBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BorrowDto> {
        const response = await this.borrowBookUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 以借書次數是否多於上限
     */
    async borrowedBookMoreThanMaxUsingPOSTRaw(requestParameters: BorrowedBookMoreThanMaxUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling borrowedBookMoreThanMaxUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/borrowedBookMoreThanMax`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * 以借書次數是否多於上限
     */
    async borrowedBookMoreThanMaxUsingPOST(requestParameters: BorrowedBookMoreThanMaxUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.borrowedBookMoreThanMaxUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 可否續借（是否兩天前）
     */
    async canResumeBorrowDateCheckUsingPOSTRaw(requestParameters: CanResumeBorrowDateCheckUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling canResumeBorrowDateCheckUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling canResumeBorrowDateCheckUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/canResumeBorrowDateCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * 可否續借（是否兩天前）
     */
    async canResumeBorrowDateCheckUsingPOST(requestParameters: CanResumeBorrowDateCheckUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.canResumeBorrowDateCheckUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 可否續借
     */
    async canResumeBorrowUsingPOSTRaw(requestParameters: CanResumeBorrowUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling canResumeBorrowUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling canResumeBorrowUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/canResumeBorrow`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * 可否續借
     */
    async canResumeBorrowUsingPOST(requestParameters: CanResumeBorrowUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.canResumeBorrowUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 取消預約
     */
    async cancelReservedBookUsingDELETERaw(requestParameters: CancelReservedBookUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReserveBorrowDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling cancelReservedBookUsingDELETE.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling cancelReservedBookUsingDELETE.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/cancel/reservedBook`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReserveBorrowDtoFromJSON(jsonValue));
    }

    /**
     * 取消預約
     */
    async cancelReservedBookUsingDELETE(requestParameters: CancelReservedBookUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReserveBorrowDto> {
        const response = await this.cancelReservedBookUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 檢查預約
     */
    async checkNoReserveUsingPOSTRaw(requestParameters: CheckNoReserveUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling checkNoReserveUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling checkNoReserveUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/checkNoReserve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * 檢查預約
     */
    async checkNoReserveUsingPOST(requestParameters: CheckNoReserveUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.checkNoReserveUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 我的書
     */
    async getMyBookshelfUsingPOSTRaw(requestParameters: GetMyBookshelfUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MyBookshelfDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling getMyBookshelfUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/listMyBookshelf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyBookshelfDtoFromJSON(jsonValue));
    }

    /**
     * 我的書
     */
    async getMyBookshelfUsingPOST(requestParameters: GetMyBookshelfUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MyBookshelfDto> {
        const response = await this.getMyBookshelfUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 我的書架 with page
     */
    async listAllMyBorrowUsingPOSTRaw(requestParameters: ListAllMyBorrowUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PageBorrowDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling listAllMyBorrowUsingPOST.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling listAllMyBorrowUsingPOST.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling listAllMyBorrowUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/listAllMyBorrowBook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBorrowDtoFromJSON(jsonValue));
    }

    /**
     * 我的書架 with page
     */
    async listAllMyBorrowUsingPOST(requestParameters: ListAllMyBorrowUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PageBorrowDto> {
        const response = await this.listAllMyBorrowUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 我的預約書籍
     */
    async listAllMyReserveBookUsingPOSTRaw(requestParameters: ListAllMyReserveBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ReserveBorrowDto>>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling listAllMyReserveBookUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/listAllMyReserveBook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReserveBorrowDtoFromJSON));
    }

    /**
     * 我的預約書籍
     */
    async listAllMyReserveBookUsingPOST(requestParameters: ListAllMyReserveBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ReserveBorrowDto>> {
        const response = await this.listAllMyReserveBookUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 我的書架
     */
    async listMyBorrowUsingPOSTRaw(requestParameters: ListMyBorrowUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BorrowDto>>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling listMyBorrowUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/listMyBorrowBook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BorrowDtoFromJSON));
    }

    /**
     * 我的書架
     */
    async listMyBorrowUsingPOST(requestParameters: ListMyBorrowUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BorrowDto>> {
        const response = await this.listMyBorrowUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 預約書籍記錄
     */
    async listReserveByBookUsingGETRaw(requestParameters: ListReserveByBookUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ReserveBorrow>>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling listReserveByBookUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/listReserveByBook`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReserveBorrowFromJSON));
    }

    /**
     * 預約書籍記錄
     */
    async listReserveByBookUsingGET(requestParameters: ListReserveByBookUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ReserveBorrow>> {
        const response = await this.listReserveByBookUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 我可否借書, if (user != null) then /productBorrowMyStatus?productId&memberId else /productBorrowStatus?productId endif
     */
    async productBorrowMyStatusUsingPOSTRaw(requestParameters: ProductBorrowMyStatusUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MyBorrowStatus>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling productBorrowMyStatusUsingPOST.');
        }

        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling productBorrowMyStatusUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/productBorrowMyStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyBorrowStatusFromJSON(jsonValue));
    }

    /**
     * 我可否借書, if (user != null) then /productBorrowMyStatus?productId&memberId else /productBorrowStatus?productId endif
     */
    async productBorrowMyStatusUsingPOST(requestParameters: ProductBorrowMyStatusUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MyBorrowStatus> {
        const response = await this.productBorrowMyStatusUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 可否借書, if (user != null) then /productBorrowMyStatus?productId&memberId else /productBorrowStatus?productId endif
     */
    async productBorrowStatusListUsingPOSTRaw(requestParameters: ProductBorrowStatusListUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BookBorrowStatusDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/productBorrowStatusList`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegerListRequestModelToJSON(requestParameters.integerListRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookBorrowStatusDtoFromJSON));
    }

    /**
     * 可否借書, if (user != null) then /productBorrowMyStatus?productId&memberId else /productBorrowStatus?productId endif
     */
    async productBorrowStatusListUsingPOST(requestParameters: ProductBorrowStatusListUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BookBorrowStatusDto>> {
        const response = await this.productBorrowStatusListUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 可否借書, if (user != null) then /productBorrowMyStatus?productId&memberId else /productBorrowStatus?productId endif
     */
    async productBorrowStatusUsingPOSTRaw(requestParameters: ProductBorrowStatusUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BookBorrowStatusDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling productBorrowStatusUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/productBorrowStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookBorrowStatusDtoFromJSON(jsonValue));
    }

    /**
     * 可否借書, if (user != null) then /productBorrowMyStatus?productId&memberId else /productBorrowStatus?productId endif
     */
    async productBorrowStatusUsingPOST(requestParameters: ProductBorrowStatusUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BookBorrowStatusDto> {
        const response = await this.productBorrowStatusUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 預約書籍
     */
    async reserveBookUsingPOSTRaw(requestParameters: ReserveBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReserveBorrowDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling reserveBookUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling reserveBookUsingPOST.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling reserveBookUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/reserveBook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReserveBorrowDtoFromJSON(jsonValue));
    }

    /**
     * 預約書籍
     */
    async reserveBookUsingPOST(requestParameters: ReserveBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReserveBorrowDto> {
        const response = await this.reserveBookUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 以預約次數是否多於上限
     */
    async reservedBookMoreThanMaxUsingPOSTRaw(requestParameters: ReservedBookMoreThanMaxUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling reservedBookMoreThanMaxUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/reservedBookMoreThanMax`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * 以預約次數是否多於上限
     */
    async reservedBookMoreThanMaxUsingPOST(requestParameters: ReservedBookMoreThanMaxUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.reservedBookMoreThanMaxUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 續借書籍
     */
    async resumeBorrowUsingPOSTRaw(requestParameters: ResumeBorrowUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ResumeBorrowMissionResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling resumeBorrowUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling resumeBorrowUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/resumeBorrowBook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResumeBorrowMissionResponseModelFromJSON(jsonValue));
    }

    /**
     * 續借書籍
     */
    async resumeBorrowUsingPOST(requestParameters: ResumeBorrowUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ResumeBorrowMissionResponseModel> {
        const response = await this.resumeBorrowUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 還書
     */
    async returnBookUsingPOSTRaw(requestParameters: ReturnBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BorrowDto>>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling returnBookUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling returnBookUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/borrow/returnBook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BorrowDtoFromJSON));
    }

    /**
     * 還書
     */
    async returnBookUsingPOST(requestParameters: ReturnBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BorrowDto>> {
        const response = await this.returnBookUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
