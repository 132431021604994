import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Configuration,
  MemberControllerApi,
  NewRefreshTokenUsingGETRequest,
  RefreshTokenUsingPOSTRequest,
  TokenModel,
} from "../fetcher";
import { ACCESS_TOKEN, HOME_PATH, ERROR_PAGE_PATH } from "./Contants";

//let baseUrl = process.env.REACT_APP_BACKEND;

export const genApiConfig = (
  noCache: boolean | null = null,
  header: { [key: string]: any } = {
    credentials: "include",
  },
  mode: string = "cors"
): Configuration => {
  // let header: { [key: string]: any } = {
  //   credentials: "include",
  // };

  if (localStorage.getItem(ACCESS_TOKEN) !== null) {
    header["Authorization"] = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  header["Access-Control-Allow-Origin"] = "*";
  header["Access-Control-Allow-Credentials"] = "true";

  //headers.append('Access-Control-Allow-Credentials', 'true');

  // "Accept-Language": Storage.language,

  var conf = new Configuration({
    basePath: process.env.REACT_APP_BACKEND,
    headers: header,

    fetchApi: (input: any, init: any) => {
      //init["mode"] = "no-cors";
      return fetch(input, init)
        .then(async (res) => {
          // console.log("res", res);
          if (res.status && res.status === 401) {
            await new MemberControllerApi(
              new Configuration({
                basePath: process.env.REACT_APP_BACKEND,
              })
            )
              .newRefreshTokenUsingGET({
                refreshToken: localStorage.getItem("refreshToken")?.toString(),
              } as NewRefreshTokenUsingGETRequest)
              .then(async (token: TokenModel) => {
                //console.log("token", token);
                if (Object.keys(token).length > 0) {
                  if (token.accessToken !== "" && token.refreshToken !== "") {
                    console.log("token1", token);
                    localStorage.setItem("accessToken", token.accessToken!!);
                    localStorage.setItem("refreshToken", token.refreshToken!!);
                    init.headers[
                      "Authorization"
                    ] = `Bearer ${token.accessToken}`;

                    return await fetch(input, init);
                  } else {
                    //console.log("token2", token);
                    let isPhone = localStorage.getItem("isPhone");
                    let savedSchool = localStorage.getItem("savedSchool");
                    localStorage.clear();
                    localStorage.setItem("isPhone", isPhone!.toString());
                    if (savedSchool !== null)
                      localStorage.setItem("savedSchool", savedSchool!);
                    window.location.pathname = HOME_PATH;
                  }
                } else {
                  //console.log("token3", token);
                  let isPhone = localStorage.getItem("isPhone");
                  let savedSchool = localStorage.getItem("savedSchool");
                  localStorage.clear();
                  localStorage.setItem("isPhone", isPhone!.toString());
                  if (savedSchool !== null)
                    localStorage.setItem("savedSchool", savedSchool!);
                  window.location.pathname = HOME_PATH;
                }
              })
              .catch(async (err) => {
                console.log("err1", err);
                // let isPhone = localStorage.getItem("isPhone");
                // let savedSchool = localStorage.getItem("savedSchool");
                // localStorage.clear();
                // localStorage.setItem("isPhone", isPhone!.toString());
                // if (savedSchool !== null)
                //   localStorage.setItem("savedSchool", savedSchool!);
                // window.location.pathname = HOME_PATH;
                return Promise.reject(err);
              });
          }

          if (res.status && res.status === 500) {
            console.log("status", 500);
            //TODO Server Error GOTO 404?
            // let isPhone = localStorage.getItem("isPhone");
            // let savedSchool = localStorage.getItem("savedSchool");
            // localStorage.clear();
            // localStorage.setItem("isPhone", isPhone!.toString());
            // if (savedSchool !== null)
            //   localStorage.setItem("savedSchool", savedSchool!);
            // window.location.pathname = HOME_PATH;
          }

          if (!res.ok) {
            return Promise.reject(res);
          } else {
            return Promise.resolve(res);
          }
        })
        .catch(async (err) => {
          console.log("err1", err.url);
          //if (err.url !== undefined) {
          // let isPhone = localStorage.getItem("isPhone");
          // let savedSchool = localStorage.getItem("savedSchool");
          // localStorage.clear();
          // localStorage.setItem("isPhone", isPhone!.toString());
          // if (savedSchool !== null)
          //   localStorage.setItem("savedSchool", savedSchool!);
          //window.location.pathname = HOME_PATH;
          //}
          //  else {
          //   window.location.href = ERROR_PAGE_PATH;
          // }

          return Promise.reject(err);
          //TODO Server Error GOTO 404?
          //window.location.href = ERROR_PAGE_PATH + `?code=${err.status}`;
          //window.location.href = ERROR_PAGE_PATH;
        });
    },
  });

  return conf;
};

//export const sharedBaseUrl = baseUrl;
