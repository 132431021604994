import { Component } from "react";
import loadingGif from "../../assets/images/loading.gif";
import {
  STYLETYPE_FOUR,
  STYLETYPE_IMG,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_THIRD,
} from "../../utils/Contants";
import style from "./Button.module.css";

interface IProps {
  id?: string;
  styleType?: string;
  disabled?: boolean;
  hidden?: boolean;
  buttonType?: "button" | "reset" | "submit";
  customStyle?: object;
  callBack: (value: any) => void;
  onBlur?: (value: any) => void;
  children?: any;
  code?: string;
  isLoading?: boolean;
  loadingStyle?: any;
}

export default class Button extends Component<IProps> {
  static defaultProps = {
    id: null,
    buttonType: "button",
    disabled: false,
    hidden: false,
    // styleType: STYLETYPE_DEFAULT,
    // code: CODE_NO_NEED_AUTH,
    customStyle: {},
    loadingStyle: {},
    isLoading: false,
    selected: false,
  };
  handleClick = () => {
    this.props.callBack(true);
  };

  render() {
    return (
      <button
        id={this.props.id}
        className={
          this.props.styleType === "style1"
            ? style.btnPrimary
            : this.props.styleType === STYLETYPE_MAIN
            ? style.MainButton
            : this.props.styleType === STYLETYPE_SECOND
            ? style.SecondButton
            : this.props.styleType === STYLETYPE_THIRD
            ? style.ThirdButton
            : this.props.styleType === STYLETYPE_FOUR
            ? style.ThirdButton
            : this.props.styleType === STYLETYPE_IMG
            ? style.btnImg
            : this.props.styleType === "searchButtom"
            ? style.searchButtom
            : this.props.styleType === "upperNavbarButton"
            ? style.upperNavbarButton
            : this.props.styleType === "lowerNavbarButton"
            ? style.lowerNavbarButton
            : this.props.styleType === "upperSideNavButton"
            ? style.upperSideNavButton
            : this.props.styleType === "lowerSideNavButton"
            ? style.lowerSideNavButton
            : this.props.styleType === "buttonGroupButton"
            ? style.buttonGroupButton
            : this.props.styleType === "cardButton"
            ? style.cardButton
            : this.props.styleType === "toggleButton"
            ? style.toggleButton
            : this.props.styleType === "toggleButtonCurrent"
            ? style.toggleButtonCurrent
            : this.props.styleType === "forgetButton"
            ? style.forgetButton
            : this.props.styleType === "googleButton"
            ? style.googleButton
            : this.props.styleType === "facebookButton"
            ? style.facebookButton
            : this.props.styleType === "tabButton"
            ? style.tabButton
            : this.props.styleType === "tabButtonCurrent"
            ? style.tabButtonCurrent
            : this.props.styleType === "verifyButton"
            ? style.verifyButton
            : this.props.styleType === "bookIntroButton"
            ? style.bookIntroButton
            : this.props.styleType === "submitButton"
            ? style.submitButton
            : this.props.styleType === "cancelButton"
            ? style.cancelButton
            : ""
        }
        //TODO Check permission logic
        disabled={this.props.disabled}
        type={this.props.buttonType}
        hidden={this.props.hidden}
        onClick={this.handleClick}
        onBlur={this.props.onBlur}
        style={this.props.customStyle}
      >
        {this.props.isLoading ? (
          <>
            <img
              src={loadingGif}
              style={
                Object.keys(this.props.loadingStyle).length > 0
                  ? this.props.loadingStyle
                  : { width: "20px", height: "20px" }
              }
              hidden={!this.props.isLoading}
              alt={loadingGif}
            />
          </>
        ) : (
          this.props.children
        )}
      </button>
    );
  }
}
