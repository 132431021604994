/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookReportData
 */
export interface BookReportData {
    /**
     * 
     * @type {string}
     * @memberof BookReportData
     */
    bookName?: string;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    borrowTime?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    brn?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    cantBorrow?: number;
    /**
     * 
     * @type {string}
     * @memberof BookReportData
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    chapter?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    contentCount?: number;
    /**
     * 
     * @type {string}
     * @memberof BookReportData
     */
    isbn?: string;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    mobileBorrow?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    preview?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    readOnline?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    readTimeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    seq?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportData
     */
    webBorrow?: number;
}

export function BookReportDataFromJSON(json: any): BookReportData {
    return BookReportDataFromJSONTyped(json, false);
}

export function BookReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookName': !exists(json, 'bookName') ? undefined : json['bookName'],
        'borrowTime': !exists(json, 'borrowTime') ? undefined : json['borrowTime'],
        'brn': !exists(json, 'brn') ? undefined : json['brn'],
        'cantBorrow': !exists(json, 'cantBorrow') ? undefined : json['cantBorrow'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'chapter': !exists(json, 'chapter') ? undefined : json['chapter'],
        'contentCount': !exists(json, 'contentCount') ? undefined : json['contentCount'],
        'isbn': !exists(json, 'isbn') ? undefined : json['isbn'],
        'mobileBorrow': !exists(json, 'mobileBorrow') ? undefined : json['mobileBorrow'],
        'preview': !exists(json, 'preview') ? undefined : json['preview'],
        'readOnline': !exists(json, 'readOnline') ? undefined : json['readOnline'],
        'readTimeCount': !exists(json, 'readTimeCount') ? undefined : json['readTimeCount'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'webBorrow': !exists(json, 'webBorrow') ? undefined : json['webBorrow'],
    };
}

export function BookReportDataToJSON(value?: BookReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookName': value.bookName,
        'borrowTime': value.borrowTime,
        'brn': value.brn,
        'cantBorrow': value.cantBorrow,
        'category': value.category,
        'chapter': value.chapter,
        'contentCount': value.contentCount,
        'isbn': value.isbn,
        'mobileBorrow': value.mobileBorrow,
        'preview': value.preview,
        'readOnline': value.readOnline,
        'readTimeCount': value.readTimeCount,
        'seq': value.seq,
        'webBorrow': value.webBorrow,
    };
}

