import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CheckSSoUserUsingPOSTRequest,
  MemberControllerApi,
  RoleDto,
  SsoUserDto,
  SsoUserResponse,
} from "../../fetcher";
import { handleUserInfo } from "../../redux/actions/userInfo";
import * as PATH from "../../utils/Contants"; //"../../global/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import { getHashQuery } from "../../utils/GlobalFunction";

const SsoRedirectView = (props: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("hashRouter", getHashQuery());

    let openIdToken = getHashQuery();

    if (openIdToken !== null) {
      fetch(`${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/register/openid`, {
        method: "POST",
        body: JSON.stringify({
          token: openIdToken.id_token,
          roleName: "sueplibrary",
        }),
      })
        .then((reso) => {
          reso.json().then(async (res) => {
            console.log("openId", res);
            localStorage.setItem(
              PATH.ACCESS_TOKEN,
              res.responseToken.access_token
            );
            if (res.hasOwnProperty("error")) {
              alert(res.error_description);
              return;
            }

            if (
              res.role[0].name !== "admin" &&
              res.role[0].name !== process.env.REACT_APP_WEB_NAME
            ) {
              alert("存取被拒");
              return;
            }

            let newObj = {};

            console.log("adfas", res.role[0]);

            new MemberControllerApi(genApiConfig())
              .checkSSoUserUsingPOST({
                username: res.name,
                ssoUserDto: {
                  age: res.age,
                  className: res.className,
                  classNumber: res.classNumber,
                  email: res.email,
                  gender: res.gender,
                  grade: res.grade,
                  hashPassword: res.hashPassword,
                  role: {
                    description: res.role[0].description,
                    name: res.role[0].name,
                    roleId: res.role[0].id,
                    isAdmin: res.role[0].isAdmin,
                  } as RoleDto,
                  userId: res.userId,
                  chtName: res.chtName,
                  engName: res.engName
                } as SsoUserDto,
              } as CheckSSoUserUsingPOSTRequest)
              .then((resLibrary: SsoUserResponse) => {
                if (resLibrary.adminMember !== undefined) {
                  newObj = Object.assign({
                    ...res,
                    isAdmin: res.role[0].isAdmin,
                    member: resLibrary.adminMember,
                  });
                }

                if (resLibrary.member !== undefined) {
                  console.log("resLib", resLibrary.member);
                  newObj = Object.assign({
                    ...res,
                    isAdmin: res.role[0].isAdmin,
                    member: resLibrary.member,
                  });
                }

                // console.log("userInfo aaaaa", newObj);
                // console.log("userInfo bbbb", PATH.USER_INFO);

                localStorage.setItem(PATH.USER_INFO, JSON.stringify(newObj));
                props.handleUserInfo(newObj);
                navigate(PATH.HOME_PATH);
              });
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, []);

  return <div>Redirect...</div>;
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.userInfo.userInfo,
  };
};
const actionCreator = {
  handleUserInfo,
};

export default connect(mapStateToProps, actionCreator)(SsoRedirectView);
