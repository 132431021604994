import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo1 from "../../assets/library_logo_i_w.svg";
import suepLogo from "../../assets/logo/suep.jpg";
import loginedSvg from "../../assets/svg/logged_in.svg";
import notSignInSvg from "../../assets/svg/not_sign_in.svg";
import searchSvg from "../../assets/svg/search@1x.svg";
import ticksvg from "../../assets/svg/tick.svg";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import SideMenu from "../../components/SideMenu/SideMenu";
import {
  CategoryControllerApi,
  CheckSSoUserUsingPOSTRequest,
  DashboardControllerApi,
  MemberControllerApi,
  PageSetting,
  PageSettingControllerApi,
  RoleDto,
  SsoUserDto,
  SsoUserResponse,
} from "../../fetcher";
import { getHeaderItems } from "../../redux/actions/headerItems/getHeaderItems";
import { handleLogin } from "../../redux/actions/login/login";
import { myBookMarkList } from "../../redux/actions/myBookMark/myBookMark";
import { myBorrowBookList } from "../../redux/actions/myBorrowBook/myBorrowBookList";
import { myReserveBookList } from "../../redux/actions/myReserverBook/myReserveBook";
import { handleSearchText } from "../../redux/actions/searchText";
import { handleUserInfo } from "../../redux/actions/userInfo";
import * as PATH from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import { getHashQuery } from "../../utils/GlobalFunction";
import * as Storage from "../../utils/local-storage";
import StorageUtil from "../../utils/reader/serviceUtils/storageUtil";
import useWindowSize from "../../utils/useWindowSize";
import style from "./NavBar.module.css";
import WebMenu from "../../components/webMenu/WebMenu";
import CollectionMenu from "../../components/CollectionMenu/CollectionMenu";

function LowerNavbar(props: any) {
  const { pathname } = useLocation();
  const { width, height } = useWindowSize();
  const [isLogined, setIsLogined] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const [ishide, setIshide] = useState(true);
  const { t, i18n } = useTranslation();
  const [showSide, setShowSide] = useState(false);
  const [pageData, setPageData] = useState<PageSetting[]>();
  const [path, setPath] = useState("");
  const [isLoading_Category, setIsLoading_Category] = useState(true);
  const [displayCateData, setDisplayCateData] = useState<any>([]);
  const [displayCollectData, setDisplayCollectData] = useState<any>([]);

  const [showCate, setShowCate] = useState(false);
  const [showCollect, setShowCollect] = useState(false);

  const [logoPath, setLogoPath] = useState("");

  useEffect(() => {
    fetchLogo();
  }, []);

  const fetchLogo = () => {
    const logoUrl = `https://image.nblib.com/js/custom_js/logo/${
      process.env.REACT_APP_WEB_NAME?.replace(/(.+)library$/, "$1")
    }.png?x-oss-process=image/resize,h_100`;

    return new Promise(function (resolve, reject) {
      var ImgObj = new Image();
      ImgObj.src = logoUrl;
      ImgObj.onload = function (res) {
        setLogoPath(logoUrl);
        //resolve(res)
      };
      ImgObj.onerror = function (err) {
        //reject(err)
      };
    });
  };

  const handleSubMenu = (str: any) => {
    //console.log("handleSubMenu", str);
    if (str === "cate") {
      setShowCollect(false);
    } else if (str === "coll") {
      setShowCate(false);
    }
  };

  const handleBlur = (event: any) => {
    console.log("event", event);
    if (
      event._reactName === "onBlur" &&
      !event.relatedTarget?.indexOf("header_box")
    ) {
      setTimeout(() => {
        setIshide(true);
      }, 100);
    }

    if (
      event._reactName === "onBlur" &&
      !event.relatedTarget?.indexOf("megamenu")
    ) {
      setTimeout(() => {
        setShowCate(false);
      }, 100);
    }

    if (
      event._reactName === "onBlur" &&
      !event.relatedTarget?.indexOf("collectmenu")
    ) {
      setTimeout(() => {
        setShowCollect(false);
      }, 100);
    }
  };

  useEffect(() => {
    // console.log("isLogined : ", isLogined);
    new PageSettingControllerApi(genApiConfig())
      .getAllPageSettingUsingGET()
      .then((res: PageSetting[]) => {
        if (res !== null && res !== undefined) {
          setPageData(res);
          props.getHeaderItems(res);
        }
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  }, [isLogined]);

  useEffect(() => {
    setPath(window.location.search);
    //check sso
    //    console.log("hashRouter", getHashQuery());

    let openIdToken = getHashQuery();

    if (Object.keys(openIdToken).length > 0) {
      fetch(
        `${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/register/openid?roleName=${process.env.REACT_APP_WEB_NAME}`,
        {
          method: "POST",
          body: JSON.stringify({
            token: openIdToken.id_token,
          }),
        }
      )
        .then((reso) => {
          reso.json().then(async (res) => {
            //console.log("openId", res);
            //localStorage.setItem(PATH.ACCESS_TOKEN, res.responseToken.access_token);
            if (res.hasOwnProperty("error")) {
              alert(res.error_description);
              return;
            }

            if (
              res.role[0].name !== "admin" &&
              res.role[0].name !== process.env.REACT_APP_WEB_NAME
            ) {
              alert("存取被拒");
              return;
            }

            let newObj = {};

            //console.log("adfas", res.role[0]);

            new MemberControllerApi(genApiConfig())
              .checkSSoUserUsingPOST({
                username: res.name,
                ssoUserDto: {
                  age: res.age,
                  className: res.className,
                  classNumber: res.classNumber,
                  email: res.email,
                  gender: res.gender,
                  grade: res.grade,
                  hashPassword: res.hashPassword,
                  role: {
                    description: res.role[0].description,
                    name: res.role[0].name,
                    roleId: res.role[0].id,
                    isAdmin: res.role[0].isAdmin,
                  } as RoleDto,
                  userId: res.userId,
                  chtName: res.chtName,
                  engName: res.engName
                } as SsoUserDto,
              } as CheckSSoUserUsingPOSTRequest)
              .then((resLibrary: SsoUserResponse) => {
                if (resLibrary.adminMember !== undefined) {
                  newObj = Object.assign({
                    ...res,
                    isAdmin: res.role[0].isAdmin,
                    member: resLibrary.adminMember,
                  });
                }

                if (resLibrary.member !== undefined) {
                  console.log("resLib", resLibrary.member);
                  newObj = Object.assign({
                    ...res,
                    isAdmin: res.role[0].isAdmin,
                    member: resLibrary.member,
                  });
                }

                localStorage.setItem(PATH.USER_INFO, JSON.stringify(newObj));
                localStorage.setItem(PATH.ACCESS_TOKEN, resLibrary.token!!);
                localStorage.setItem(
                  PATH.REFRESH_TOKEN,
                  resLibrary.refreshToken!!
                );
                props.handleUserInfo(newObj);
                setIsLogined(true);
                navigate(PATH.HOME_PATH);
              });
          });
        })
        .catch((err) => {
          console.log("err", err);
          setIsLogined(false);
        });
    } else {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER_URL}/user/showPersonalDetail`,
        {
          method: "GET",
          headers: {
            Authorization: `bearer ${Storage.instance.accessToken}`,
          },
        }
      )
        .then(async (reso) => {
          await reso.json().then(async (res) => {
            if (res.hasOwnProperty("error")) {
              //props.handleUserInfo();
              //localStorage.clear();
              let isPhone = localStorage.getItem("isPhone");
              localStorage.setItem("isPhone", isPhone!.toString());
              setIsLogined(false);
            } else {
              console.log("else", res);

              let newObj = {};

              new MemberControllerApi(genApiConfig())
                .checkSSoUserUsingPOST({
                  username: res.name,
                  ssoUserDto: {
                    age: res.age,
                    className: res.className,
                    classNumber: res.classNumber,
                    email: res.email,
                    gender: res.gender,
                    grade: res.grade,
                    hashPassword: res.hashPassword,
                    role: {
                      description: res.role[0].description,
                      name: res.role[0].name,
                      roleId: res.role[0].id,
                      isAdmin: res.role[0].isAdmin,
                    } as RoleDto,
                    userId: res.userId,
                    chtName: res.chtName,
                    engName: res.engName
                  } as SsoUserDto,
                } as CheckSSoUserUsingPOSTRequest)
                .then((resLibrary: SsoUserResponse) => {
                  if (resLibrary.adminMember !== undefined) {
                    newObj = Object.assign({
                      ...Object.assign({ ...res, isAdmin: true }),
                      member: resLibrary.adminMember,
                    });
                  }

                  if (resLibrary.member !== undefined) {
                    newObj = Object.assign({
                      ...Object.assign({ ...res, isAdmin: false }),
                      member: resLibrary.member,
                    });
                  }

                  localStorage.setItem(
                    PATH.USER_INFO,
                    JSON.stringify({ ...newObj })
                  );
                  props.handleUserInfo({ ...newObj });
                });

              setIsLogined(true);
            }
          });
        })
        .catch((err) => {
          console.log("res catch", err);
          setIsLogined(false);
        });
    }

    const handleKeyup = (e: any) => {
      console.log(e);
      if (e.keyCode === 13) {
        console.log("navigate");
        handleNavigate();
      }
    };

    document
      .getElementById("search_input")
      ?.addEventListener("keyup", handleKeyup);
    return () =>
      document
        .getElementById("search_input")
        ?.removeEventListener("keyup", handleKeyup);
  }, []);

  const setLogout = () => {
    const lastMsg = localStorage.getItem(PATH.LAST_MSG);

    fetch(`${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/logout`, {
      method: "GET",
      headers: {
        Authorization: `bearer ${Storage.instance.accessToken}`,
      },
    })
      .then((res) => {
        res.text().then((resText) => {
          props.handleUserInfo();
          let isPhone = localStorage.getItem("isPhone");
          let savedSchool = localStorage.getItem("savedSchool");
          localStorage.clear();
          if (lastMsg !== null) {
            localStorage.setItem(PATH.LAST_MSG, lastMsg);
          }
          localStorage.setItem("isPhone", isPhone!.toString());
          if (savedSchool !== null)
            localStorage.setItem("savedSchool", savedSchool!);
          window.location.pathname = PATH.HOME_PATH;
          navigate(PATH.HOME_PATH);
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log("setLogout", err);
        window.location.pathname = PATH.HOME_PATH;
        navigate(PATH.HOME_PATH);
        window.location.reload();
      });
    localStorage.clear();
    if (lastMsg !== null) {
      localStorage.setItem(PATH.LAST_MSG, lastMsg);
    }
    window.location.pathname = PATH.HOME_PATH;
    navigate(PATH.HOME_PATH);
    window.location.reload();
  };

  const checkLoginedUser = () => {
    if (Storage.instance.userInfo === null) {
      process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com" ||
      window.location.host.includes("hkpl") ||
      process.env.REACT_APP_CLIENT_ID === "hkpl" ||
      process.env.REACT_APP_CLIENT_ID === "uatlib" ? (
        (window.parent.parent.location = PATH.HKPL_SSO_PATH)
      ) : (
        <>{navigate(PATH.LOGIN_PATH)}</>
      );
    }

    if (Storage.instance.userInfo.isAdmin) {
      navigate(PATH.ADMIN);
    } else {
      navigate(PATH.STUDENT);
    }
  };

  const handleNavigate = () => {
    //navigate(ToParams());
    document.getElementById("search_btn")?.click();
  };

  useEffect(() => {
    if (isLoading_Category) {
      new DashboardControllerApi(genApiConfig())
        .getDashboardSortUsingGET()
        //.getDashboardCategoryUsingGET()
        .then((res) => {
          // console.log("category", res);
          setDisplayCateData(res);
        });
      new CategoryControllerApi(genApiConfig())
        .getAllCollectionUsingGET()
        .then((res) => {
          // console.log("setDisplayCollectData", res);
          setDisplayCollectData(res);
        });
    }
    setIsLoading_Category(false);
  }, [isLoading_Category]);

  const isImage = (url: string) => {
    const img = console.log("isImage", url);
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  return (
    <div className={style.Navbar}>
      <>
        <nav className={style.upperNavbar}>
          <div className={style.innerUpperNavbar}>
            <div
              className={
                process.env.REACT_APP_BACKEND ===
                  "https://suepsso.octopus-tech.com" ||
                window.location.host.includes("hkpl") ||
                process.env.REACT_APP_CLIENT_ID === "hkpl"
                  ? style.logoContainerHkpl
                  : style.logoContainer
              }
            >
              {/* <Link to={PATH.HOME_PATH}>
                {process.env.REACT_APP_BACKEND ===
                  "https://suepsso.octopus-tech.com" ||
                window.location.host.includes("hkpl") ? (
                  <>
                    <img
                      className={style.secondLogo}
                      // style={{
                      //   maxWidth: "160px",
                      // }}
                      src={logo1}
                      alt="main-logo"
                    />
                  </>
                ) : (
                  <>
                    <img src={logo1} alt="main-logo" />
                  </>
                )}
              </Link> */}

              {process.env.REACT_APP_BACKEND ===
                "https://suepsso.octopus-tech.com" ||
              window.location.host.includes("hkpl") ||
              process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
                <>
                  <Link to={PATH.HOME_PATH}>
                    <img
                      className={style.mainLogo}
                      // style={{
                      //   maxWidth: "160px",
                      //   marginTop: "8px",
                      //   marginBottom: "8px",
                      // }}
                      src={suepLogo}
                      alt="main-logo"
                    />
                  </Link>
                </>
              ) : (
                <>
                  <Link to={PATH.HOME_PATH}>
                    <img src={logo1} alt="main-logo" />
                  </Link>
                </>
              )}

              {logoPath !== "" ? (
                <img
                  src={logoPath}
                  alt="sub-logo"
                  style={{
                    height: "60px",
                    objectFit: "contain",
                    width: "auto",
                    margin: "8px",
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <ul>
              {width <= 768 ? (
                <></>
              ) : (
                <>
                  {props.headerItems.headerItems &&
                    props.headerItems.headerItems
                      .filter(
                        (it: any) =>
                          it.url == "/thematic" ||
                          it.url === "/news" ||
                          it.url === "/activity"
                      )
                      .map((headerItem: any) =>
                        headerItem.enable === true ? (
                          <>
                            <li>
                              <Link to={"info" + headerItem.url}>
                                <Button
                                  styleType={"upperNavbarButton"}
                                  callBack={() => console.log(1)}
                                >
                                  {headerItem.chtName}
                                </Button>
                              </Link>
                            </li>
                            <li> &nbsp; &nbsp; | &nbsp; &nbsp;</li>
                          </>
                        ) : (
                          <></>
                        )
                      )}
                </>
              )}

              <div>
                <img src={notSignInSvg} />
              </div>
              <div className={style.logo}>
                <img
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                  src={loginedSvg}
                  onClick={() => checkLoginedUser()}
                />
              </div>
            </ul>
          </div>
        </nav>
      </>
      {/* UUUUUUUUPPPPPP */}
      <nav className={style.lowerNavbar}>
        <div className={style.lowerInnerNavbar}>
          <ul className={style.lowNavBarButtonContainer}>
            {width <= 768 ? (
              <>
                <li>
                  <span
                    className={style.SideBtn}
                    onClick={() => setShowSide(!showSide)}
                  >
                    {showSide ? "X" : "☰"}
                  </span>
                </li>
                <li> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; </li>
              </>
            ) : (
              <>
                {localStorage.getItem(PATH.USER_INFO) !== null ? (
                  <>
                    <li>
                      <Button
                        styleType={"upperNavbarButton"}
                        callBack={() => setLogout()}
                      >
                        <Trans>logout</Trans>
                      </Button>
                    </li>
                    <li> &nbsp; &nbsp; | &nbsp; &nbsp;</li>
                  </>
                ) : (
                  <></>
                )}

                {/* {localStorage.getItem(PATH.SSO_INFO) !== null ? (
                  <li> &nbsp; &nbsp; | &nbsp; &nbsp;</li>
                ) : (
                  <></>
                )} */}
                {process.env.REACT_APP_BACKEND ==
                  "https://suepsso.octopus-tech.com" ||
                window.location.host.includes("hkpl") ||
                process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
                  <></>
                ) : (
                  <>
                    <li>
                      <Link to={PATH.APPDOWNLOAD}>
                        <Button
                          styleType={"upperNavbarButton"}
                          callBack={() => console.log(1)}
                        >
                          <Trans>app</Trans>
                        </Button>
                      </Link>
                    </li>
                    <li> &nbsp; &nbsp; | &nbsp; &nbsp;</li>
                  </>
                )}

                <li>
                  <Link to={PATH.MANUAL}>
                    <Button styleType={"upperNavbarButton"} callBack={() => {}}>
                      <Trans>manual</Trans>
                    </Button>
                  </Link>
                </li>
                <li> &nbsp; &nbsp; | &nbsp; &nbsp;</li>
                <li>
                  <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => setIshide(!ishide)}
                    onBlur={(e) => handleBlur(e)}
                  >
                    <Trans>LanguageSetting</Trans>
                  </Button>
                  <div
                    className={style.header_box}
                    style={{
                      display: ishide ? "none" : "block",
                    }}
                    onBlur={(e) => handleBlur(e)}
                    tabIndex={0}
                  >
                    <div className={style.header_contain}>
                      <li
                        className={style.nav_item}
                        onClick={() => {
                          i18n.changeLanguage("cht");
                          StorageUtil.setReaderConfig("lang", "cht");
                        }}
                        style={{
                          color: i18n.language === "cht" ? "#E37B3C" : "",
                        }}
                      >
                        繁
                        {i18n.language === "cht" ? (
                          <img src={ticksvg}></img>
                        ) : (
                          ""
                        )}
                      </li>

                      <li
                        className={style.nav_item}
                        onClick={() => {
                          i18n.changeLanguage("cn");
                          StorageUtil.setReaderConfig("lang", "cn");
                        }}
                        style={{
                          color: i18n.language === "cn" ? "#E37B3C" : "",
                        }}
                      >
                        简
                        {i18n.language === "cn" ? (
                          <img src={ticksvg}></img>
                        ) : (
                          ""
                        )}
                      </li>

                      <li
                        className={style.nav_item}
                        onClick={() => {
                          i18n.changeLanguage("en");
                          StorageUtil.setReaderConfig("lang", "en");
                        }}
                        style={{
                          color: i18n.language === "en" ? "#E37B3C" : "",
                        }}
                      >
                        ENG
                        {i18n.language === "en" ? (
                          <img src={ticksvg}></img>
                        ) : (
                          ""
                        )}
                      </li>
                    </div>
                  </div>
                </li>

                {process.env.REACT_APP_BACKEND ==
                  "https://suepsso.octopus-tech.com" ||
                window.location.host.includes("hkpl") ||
                process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
                  <></>
                ) : (
                  <>
                    <li> &nbsp; &nbsp; | &nbsp; &nbsp;</li>
                    <li>
                      <div>
                        <Button
                          styleType={"upperNavbarButton"}
                          callBack={() =>
                            (window.location.href =
                              "https://www.nblib.com/selectOrg")
                          }
                        >
                          <Trans>ChangeSchool</Trans>
                        </Button>
                      </div>
                    </li>
                  </>
                )}
                <li> &nbsp; &nbsp; | &nbsp; &nbsp;</li>
                <li className={style.categoryBtn}>
                  <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => {
                      setShowCate(!showCate);
                      handleSubMenu("cate");
                    }}
                    onBlur={(e) => handleBlur(e)}
                  >
                    <Trans>Category</Trans>
                  </Button>
                </li>
                {displayCollectData && displayCollectData.length > 0 ? (
                  <>
                    <li> &nbsp; &nbsp; | &nbsp; &nbsp;</li>
                    <li className={style.collectionBtn}>
                      <Button
                        styleType={"upperNavbarButton"}
                        callBack={() => {
                          setShowCollect(!showCollect);
                          handleSubMenu("coll");
                        }}
                        onBlur={(e) => handleBlur(e)}
                      >
                        <Trans>Collection</Trans>
                      </Button>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </ul>

          <div className={style.searchContainer}>
            {width <= 768 ? (
              <Link to={PATH.SEARCH_PATH}>
                <img
                  id={"search_btn"}
                  src={searchSvg}
                  // onClick={() => {
                  //     navigate(`/search/result?keyword=${searchValue}`);
                  //     setSearchValue("");
                  // }}
                />
              </Link>
            ) : (
              <>
                <Input
                  id={"search_input"}
                  type={"text"}
                  placeholder={t("Search")}
                  styleType={"searchInput"}
                  value={props.searchText.searchText}
                  callBack={(value: any) => props.handleSearchText(value)}
                >
                  <img
                    id={"search_btn"}
                    src={searchSvg}
                    onClick={() => {
                      props.handleSearchText("");
                      props.searchText.searchText === ""
                        ? alert("請輸入關鍵字")
                        : navigate(
                            `/search/result?inputOption=all&keyword=${props.searchText.searchText}`
                          );
                    }}
                  />
                </Input>
                <Link to={PATH.SEARCH_PATH}>
                  <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => console.log(1)}
                  >
                    <Trans>AdvancedSearch</Trans>
                  </Button>
                </Link>
              </>
            )}
            {/* <Input type={"text"} placeholder={t("Search")} styleType={"searchInput"} callBack={(value) => setSearchValue(value)}>
                            <img
                                src={searchSvg}
                                onClick={() => {
                                    navigate(`/search/result?keyword=${searchValue}`);
                                    setSearchValue("");
                                }}
                            />
                        </Input>
                        <Link to={PATH.SEARCH_PATH}>
                            <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
                                <Trans>AdvancedSearch</Trans>
                            </Button>
                        </Link> */}
          </div>
        </div>
      </nav>

      <SideMenu
        show={showSide}
        callBack={(value: any) => setShowSide(value)}
        headerItems={props.headerItems}
      />
      <WebMenu
        onBlur={(e: any) => handleBlur(e)}
        content={displayCateData}
        show={showCate}
        callBack={(value: any) => setShowCate(value)}
      />
      <CollectionMenu
        onBlur={(e: any) => handleBlur(e)}
        content={displayCollectData}
        show={showCollect}
        callBack={(value: any) => setShowCollect(value)}
      />
    </div>
    //   // </nav>

    //   // <SideMenu show={showSide} callBack={(value: any) => setShowSide(value)} />
    //   // <WebMenu content={displayCateData} show={showCate} callBack={(value: any) => setShowCate(value)} />
    // </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    borrowBookList: state.myBorrowBookList,
    reserveBookList: state.myReserveBookList,
    auth: state.login.auth,
    headerItems: state.headerItems,
    userInfo: state.userInfo,
    searchText: state.searchText,
  };
};
const actionCreator = {
  myBorrowBookList,
  handleLogin,
  myReserveBookList,
  myBookMarkList,
  getHeaderItems,
  handleUserInfo,
  handleSearchText,
};
export default connect(mapStateToProps, actionCreator)(LowerNavbar);
