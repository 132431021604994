import BookModel from "../../../model/Book";

export const addEpub = (file, md5, filePath = "Unknown Path", bookId) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async (e) => {
      if (!e.target) {
        reject(false);
        throw new Error();
      }
      let cover = "";
      let charset = "";

      const epub = window.ePub(e.target.result);
      epub.loaded.metadata
        .then((metadata) => {
          if (!e.target) {
            reject(false);
            throw new Error();
          }
          epub
            .coverUrl()
            .then(async (url) => {
              if (url) {
                var reader = new FileReader();
                let blob = await fetch(url).then((r) => r.blob());
                reader.readAsDataURL(blob);
                reader.onloadend = async () => {
                  cover = reader.result;
                  let key, name, author, description, publisher;
                  [name, author, description, publisher] = [
                    metadata.title,
                    metadata.creator,
                    metadata.description,
                    metadata.publisher,
                  ];
                  let format =
                    publisher === "mobi"
                      ? "MOBI"
                      : publisher === "azw3"
                      ? "AZW3"
                      : publisher === "txt"
                      ? "TXT"
                      : "EPUB";
                  key = file.name.split(".")[0].split("_")[1]; // new Date().getTime() + "";
                  let book = new BookModel(
                    key,
                    name,
                    author,
                    description,
                    md5,
                    cover,
                    format,
                    publisher,
                    file.size,
                    file.path || filePath,
                    charset
                  );
                  resolve(book);
                };
              } else {
                cover = "";
                let key, name, author, publisher, description;
                [name, author, description, publisher] = [
                  metadata.title,
                  metadata.creator,
                  metadata.description,
                  metadata.publisher,
                ];
                let format = "EPUB";
                key = file.name.split(".")[0].split("_")[1]; // new Date().getTime() + "";
                let book = new BookModel(
                  key,
                  name,
                  author,
                  description,
                  md5,
                  cover,
                  format,
                  publisher,
                  file.size,
                  file.path || filePath,
                  charset
                );

                resolve(book);
              }
            })
            .catch((err) => {
              reject(false);
            });
        })
        .catch(() => {
          reject(false);
        });
    };
  });
};
