/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ArticleDto,
    ArticleDtoFromJSON,
    ArticleDtoToJSON,
} from '../models';

export interface AddArticleUsingPOSTRequest {
    articleTitle: string;
    articleContent: string;
    name?: string;
    categoryId?: number;
    articleCoverImage?: string;
    relatedDisplayProductIds?: number;
    hashtag?: string;
    marketingEventId?: number;
    status?: string;
    body?: Date;
}

export interface DeleteArticleUsingPOSTRequest {
    articleId: number;
    name?: string;
}

export interface GetArticleByArticleIdAdminUsingGETRequest {
    articleId: number;
    name?: string;
}

export interface GetArticleByArticleIdUsingGETRequest {
    articleId: number;
}

export interface GetArticleByHashtagNameAdminUsingGETRequest {
    hashtagName: string;
    name?: string;
}

export interface GetPagedArticleAdminUsingGETRequest {
    page: string;
    size: string;
    name?: string;
}

export interface GetPagedArticleByCategoryIdAdminUsingGETRequest {
    page: string;
    size: string;
    categoryId: number;
    name?: string;
}

export interface GetPagedArticleByCategoryIdUsingGETRequest {
    categoryId: number;
    page: string;
    size: string;
}

export interface GetPagedArticleByHashtagNameUsingGETRequest {
    hashtagName: string;
    page: string;
    size: string;
}

export interface UpdateArticleUsingPOSTRequest {
    name?: string;
    articleId?: number;
    articleTitle?: string;
    categoryId?: number;
    articleContent?: string;
    articleCoverImage?: string;
    relatedDisplayProductIds?: number;
    hashtag?: string;
    marketingEventId?: number;
    status?: string;
    body?: Date;
}

/**
 * 
 */
export class ArticleControllerApi extends runtime.BaseAPI {

    /**
     * addArticle
     */
    async addArticleUsingPOSTRaw(requestParameters: AddArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters.articleTitle === null || requestParameters.articleTitle === undefined) {
            throw new runtime.RequiredError('articleTitle','Required parameter requestParameters.articleTitle was null or undefined when calling addArticleUsingPOST.');
        }

        if (requestParameters.articleContent === null || requestParameters.articleContent === undefined) {
            throw new runtime.RequiredError('articleContent','Required parameter requestParameters.articleContent was null or undefined when calling addArticleUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.articleTitle !== undefined) {
            queryParameters['articleTitle'] = requestParameters.articleTitle;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.articleContent !== undefined) {
            queryParameters['articleContent'] = requestParameters.articleContent;
        }

        if (requestParameters.articleCoverImage !== undefined) {
            queryParameters['articleCoverImage'] = requestParameters.articleCoverImage;
        }

        if (requestParameters.relatedDisplayProductIds !== undefined) {
            queryParameters['relatedDisplayProductIds'] = requestParameters.relatedDisplayProductIds;
        }

        if (requestParameters.hashtag !== undefined) {
            queryParameters['hashtag'] = requestParameters.hashtag;
        }

        if (requestParameters.marketingEventId !== undefined) {
            queryParameters['marketingEventId'] = requestParameters.marketingEventId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * addArticle
     */
    async addArticleUsingPOST(requestParameters: AddArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ArticleDto> {
        const response = await this.addArticleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOSTRaw(requestParameters: DeleteArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling deleteArticleUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/delete/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOST(requestParameters: DeleteArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ArticleDto> {
        const response = await this.deleteArticleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllArticle
     */
    async getAllArticleUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ArticleDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleDtoFromJSON));
    }

    /**
     * getAllArticle
     */
    async getAllArticleUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ArticleDto>> {
        const response = await this.getAllArticleUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getArticleByArticleIdAdmin
     */
    async getArticleByArticleIdAdminUsingGETRaw(requestParameters: GetArticleByArticleIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getArticleByArticleIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/admin/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * getArticleByArticleIdAdmin
     */
    async getArticleByArticleIdAdminUsingGET(requestParameters: GetArticleByArticleIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ArticleDto> {
        const response = await this.getArticleByArticleIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleByArticleId
     */
    async getArticleByArticleIdUsingGETRaw(requestParameters: GetArticleByArticleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getArticleByArticleIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * getArticleByArticleId
     */
    async getArticleByArticleIdUsingGET(requestParameters: GetArticleByArticleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ArticleDto> {
        const response = await this.getArticleByArticleIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleByHashtagNameAdmin
     */
    async getArticleByHashtagNameAdminUsingGETRaw(requestParameters: GetArticleByHashtagNameAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ArticleDto>>> {
        if (requestParameters.hashtagName === null || requestParameters.hashtagName === undefined) {
            throw new runtime.RequiredError('hashtagName','Required parameter requestParameters.hashtagName was null or undefined when calling getArticleByHashtagNameAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/admin/hashtag/{hashtagName}`.replace(`{${"hashtagName"}}`, encodeURIComponent(String(requestParameters.hashtagName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleDtoFromJSON));
    }

    /**
     * getArticleByHashtagNameAdmin
     */
    async getArticleByHashtagNameAdminUsingGET(requestParameters: GetArticleByHashtagNameAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ArticleDto>> {
        const response = await this.getArticleByHashtagNameAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedArticleAdmin
     */
    async getPagedArticleAdminUsingGETRaw(requestParameters: GetPagedArticleAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedArticleAdminUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedArticleAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedArticleAdmin
     */
    async getPagedArticleAdminUsingGET(requestParameters: GetPagedArticleAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedArticleAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedArticleByCategoryIdAdmin
     */
    async getPagedArticleByCategoryIdAdminUsingGETRaw(requestParameters: GetPagedArticleByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedArticleByCategoryIdAdminUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedArticleByCategoryIdAdminUsingGET.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getPagedArticleByCategoryIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/admin/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedArticleByCategoryIdAdmin
     */
    async getPagedArticleByCategoryIdAdminUsingGET(requestParameters: GetPagedArticleByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedArticleByCategoryIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedArticleByCategoryId
     */
    async getPagedArticleByCategoryIdUsingGETRaw(requestParameters: GetPagedArticleByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getPagedArticleByCategoryIdUsingGET.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedArticleByCategoryIdUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedArticleByCategoryIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedArticleByCategoryId
     */
    async getPagedArticleByCategoryIdUsingGET(requestParameters: GetPagedArticleByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedArticleByCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedArticleByHashtagName
     */
    async getPagedArticleByHashtagNameUsingGETRaw(requestParameters: GetPagedArticleByHashtagNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.hashtagName === null || requestParameters.hashtagName === undefined) {
            throw new runtime.RequiredError('hashtagName','Required parameter requestParameters.hashtagName was null or undefined when calling getPagedArticleByHashtagNameUsingGET.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedArticleByHashtagNameUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedArticleByHashtagNameUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/hashtag/{hashtagName}`.replace(`{${"hashtagName"}}`, encodeURIComponent(String(requestParameters.hashtagName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedArticleByHashtagName
     */
    async getPagedArticleByHashtagNameUsingGET(requestParameters: GetPagedArticleByHashtagNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedArticleByHashtagNameUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateArticle
     */
    async updateArticleUsingPOSTRaw(requestParameters: UpdateArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.articleId !== undefined) {
            queryParameters['articleId'] = requestParameters.articleId;
        }

        if (requestParameters.articleTitle !== undefined) {
            queryParameters['articleTitle'] = requestParameters.articleTitle;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.articleContent !== undefined) {
            queryParameters['articleContent'] = requestParameters.articleContent;
        }

        if (requestParameters.articleCoverImage !== undefined) {
            queryParameters['articleCoverImage'] = requestParameters.articleCoverImage;
        }

        if (requestParameters.relatedDisplayProductIds !== undefined) {
            queryParameters['relatedDisplayProductIds'] = requestParameters.relatedDisplayProductIds;
        }

        if (requestParameters.hashtag !== undefined) {
            queryParameters['hashtag'] = requestParameters.hashtag;
        }

        if (requestParameters.marketingEventId !== undefined) {
            queryParameters['marketingEventId'] = requestParameters.marketingEventId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/article/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * updateArticle
     */
    async updateArticleUsingPOST(requestParameters: UpdateArticleUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ArticleDto> {
        const response = await this.updateArticleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
