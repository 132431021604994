import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import * as PATH from "../../../utils/Contants";
import { DIALOG_CANCEL, DIALOG_COMPLETE } from "../../../utils/Contants";
import { dateToString } from "../../../utils/GlobalFunction";
import style from "./PersonalRecord.module.css";
import { Trans, useTranslation } from "react-i18next";

interface IProps {
    title?: string;
    content?: string;
    value?: string;
    callBack: (value: any, value2?: any) => void;
    styleType?: string;
    children?: any;
    item?: any;
    isMobile?: boolean;
    actionLog: any;
}

class PersonalRecord extends Component<IProps> {
    state = {
        content: {},
        title: "",
        showError: false,
        loading: false,
    };

    static defaultProps = {
        isMobile: false,
    };

    handleCancel = () => {
        this.props.callBack({ action: DIALOG_CANCEL });
    };

    handleSubmit = () => {
        this.props.callBack({ action: DIALOG_COMPLETE });
    };

    render() {
        const { content, showError, loading } = this.state;
        const { isMobile } = this.props;
        //console.log('this.props.actionLog',this.props.actionLog)
        return (
            <Fragment>
                <div style={{ marginTop: "2em" }} className={style.tableItemListContainer}>
                    <div className={style.tableListItemGridView}>
                        <div className={style.topRow}></div>

                        <div className={style.content}>
                            <div className={style.padding}>
                                <div className={style.recordTopRow}>
                                    <h5>
                                        <Trans>personalORecord</Trans>
                                    </h5>
                                    {/* <Link to={PATH.ACTIVITYRECORD}>
                                        <div className={style.btn}>
                                            <Trans>SeeMore</Trans>
                                        </div>
                                    </Link> */}
                                </div>

                                <div className={isMobile ? style.padding5px : style.padding}>
                                    {this.props.actionLog.map((it: any) =>
                                        it.actionCode === "BORROW_BOOK" ? (
                                            <div className={isMobile ? style.recordArea2 : style.recordArea}>
                                                <div className={isMobile ? style.recordRow2 : style.recordRow}>
                                                    <div style={{ display: "inline", fontSize: "12px" }}>
                                                        <Trans>successBorrow</Trans>
                                                        <span className={style.actionLogHighlightText}>{it.data.product.productNameChi}</span>
                                                    </div>
                                                    <div style={{ fontSize: "12px" }}>{dateToString(it.createAt, "DD/MM/yyyy")}</div>
                                                </div>
                                            </div>
                                        ) : it.actionCode === "RETURN_BOOK" ? (
                                            <div className={isMobile ? style.recordArea2 : style.recordArea}>
                                                <div className={isMobile ? style.recordRow2 : style.recordRow}>
                                                    <div style={{ display: "inline", fontSize: "12px" }}>
                                                        <Trans>Booksuccessfullyreturned</Trans>
                                                        <span className={style.actionLogHighlightText}>{it && it.data.product.productNameChi}</span>
                                                    </div>

                                                    <div style={{ fontSize: "12px" }}>{dateToString(it.createAt, "DD/MM/yyyy")}</div>
                                                </div>
                                            </div>
                                        ) : it.actionCode === "RESUME_BOOK" ? (
                                            <div className={isMobile ? style.recordArea2 : style.recordArea}>
                                                <div className={isMobile ? style.recordRow2 : style.recordRow}>
                                                    <div style={{ display: "inline", fontSize: "12px" }}>
                                                        <Trans>renewBorrowed</Trans>
                                                        <span className={style.actionLogHighlightText}>{it.data.borrow.bookname}</span>， <Trans>returndate</Trans>
                                                        <span className={style.actionLogHighlightText}>{dateToString(it.data.borrow.returnDate, "DD/MM/yyyy")}</span>
                                                    </div>

                                                    <div style={{ fontSize: "12px" }}>{dateToString(it.createAt, "DD/MM/yyyy")}</div>
                                                </div>
                                            </div>
                                        ) : (
                                            (it.actionCode = "RESERVE_BOOK" ? (
                                                <div className={isMobile ? style.recordArea2 : style.recordArea}>
                                                    <div className={isMobile ? style.recordRow2 : style.recordRow}>
                                                        <div style={{ display: "inline", fontSize: "12px" }}>
                                                            <Trans>AppointmentSuccessful</Trans>
                                                            <span className={style.actionLogHighlightText}>{it.data.reserveBorrow && it.data.reserveBorrow.bookname}</span>，{" "}
                                                            <Trans>Estimating</Trans>
                                                            <span className={style.actionLogHighlightText}>
                                                                {it.data.reserveBorrow && dateToString(it.data.reserveBorrow.estimateBorrowDate, "DD/MM/yyyy")}
                                                            </span>
                                                            <Trans>AddedtoShelf</Trans>
                                                        </div>
                                                        <div style={{ fontSize: "12px" }}>{dateToString(it.createAt, "DD/MM/yyyy")}</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            ))
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default PersonalRecord;
