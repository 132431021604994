/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddProductInventory,
    AddProductInventoryFromJSON,
    AddProductInventoryToJSON,
    SetProductOnlineReadSameTime,
    SetProductOnlineReadSameTimeFromJSON,
    SetProductOnlineReadSameTimeToJSON,
    Sync,
    SyncFromJSON,
    SyncToJSON,
} from '../models';

export interface AddProductInventoryUsingPOST1Request {
    addProductInventory?: AddProductInventory;
}

export interface SetProductOnlineReadSametimeUsingPOSTRequest {
    setProductOnlineReadSameTime?: SetProductOnlineReadSameTime;
}

export interface SyncMissionDataUsingPOSTRequest {
    sync?: Sync;
}

export interface SyncResetCategoryUsingPOSTRequest {
    sync?: Sync;
}

/**
 * 
 */
export class SyncControllerApi extends runtime.BaseAPI {

    /**
     * addProductInventory
     */
    async addProductInventoryUsingPOST1Raw(requestParameters: AddProductInventoryUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sync/addProduct-inv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddProductInventoryToJSON(requestParameters.addProductInventory),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * addProductInventory
     */
    async addProductInventoryUsingPOST1(requestParameters: AddProductInventoryUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.addProductInventoryUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * resetMp3Cover
     */
    async resetMp3CoverUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<string>; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sync/rest-mp3-cover`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * resetMp3Cover
     */
    async resetMp3CoverUsingPOST(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<{ [key: string]: Array<string>; }> {
        const response = await this.resetMp3CoverUsingPOSTRaw(initOverrides);
        return await response.value();
    }

    /**
     * setProductOnlineReadSametime
     */
    async setProductOnlineReadSametimeUsingPOSTRaw(requestParameters: SetProductOnlineReadSametimeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sync/setProduct-onlineReadSameTime`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetProductOnlineReadSameTimeToJSON(requestParameters.setProductOnlineReadSameTime),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * setProductOnlineReadSametime
     */
    async setProductOnlineReadSametimeUsingPOST(requestParameters: SetProductOnlineReadSametimeUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.setProductOnlineReadSametimeUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * syncMissionData
     */
    async syncMissionDataUsingPOSTRaw(requestParameters: SyncMissionDataUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sync/adds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncToJSON(requestParameters.sync),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * syncMissionData
     */
    async syncMissionDataUsingPOST(requestParameters: SyncMissionDataUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.syncMissionDataUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * syncResetCategory
     */
    async syncResetCategoryUsingPOSTRaw(requestParameters: SyncResetCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sync/reset-category`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncToJSON(requestParameters.sync),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * syncResetCategory
     */
    async syncResetCategoryUsingPOST(requestParameters: SyncResetCategoryUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.syncResetCategoryUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
