/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModuleDescriptor
 */
export interface ModuleDescriptor {
    /**
     * 
     * @type {boolean}
     * @memberof ModuleDescriptor
     */
    automatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleDescriptor
     */
    open?: boolean;
}

export function ModuleDescriptorFromJSON(json: any): ModuleDescriptor {
    return ModuleDescriptorFromJSONTyped(json, false);
}

export function ModuleDescriptorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleDescriptor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'automatic': !exists(json, 'automatic') ? undefined : json['automatic'],
        'open': !exists(json, 'open') ? undefined : json['open'],
    };
}

export function ModuleDescriptorToJSON(value?: ModuleDescriptor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'automatic': value.automatic,
        'open': value.open,
    };
}

