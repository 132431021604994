import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import Input from "../../../components/Input/Input";
import { CategoryControllerApi, IntegerListRequestModel } from "../../../fetcher";
import { BookCountModel } from "../../../fetcher/models/BookCountModel";
import * as PATH from "../../../utils/Contants";
import { STYLETYPE_SECOND } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { dateToString } from "../../../utils/GlobalFunction";
import { fetchFileFromURL, fetchFileFromURLToFile } from "../../../utils/reader/fileUtils/fileUtil";
import useWindowSize from "../../../utils/useWindowSize";
import PageLoading from "../../loading/PageLoading";
import { DndItem } from "../../Sorting/DndItem";
import { DndList } from "../../Sorting/DndList";
import { DndTrigger } from "../../Sorting/DndTrigger";
import style from "./BookListView.module.css";
import TreeMeunCategory from "./TreeMeunCategory/TreeMeunCategory";
import puller from "../../../assets/svg/menu.svg";
import CategoryConflicts from "./CategoryConflicts";
import {
    AddCategoryNameConflictUsingPOSTRequest,
    AddCategoryParentConflictUsingPOSTRequest,
    CategoryConflictControllerApi,
} from "../../../fetcher/apis/CategoryConflictControllerApi";
import { CategoryNameConflictResponse } from "../../../fetcher/models/CategoryNameConflictResponse";
import { CategoryParentConflictResponse } from "../../../fetcher/models/CategoryParentConflictResponse";
import { CategoryNameConflict } from "../../../fetcher/models/CategoryNameConflict";
import { CategoryParentConflict } from "../../../fetcher/models/CategoryParentConflict";
import * as Storage from "../../../utils/local-storage";
export default function BookListView() {
    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const navigate = useNavigate();

    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showAddSubDialog, setShowAddSubDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showRecommedDialog, setShowRecommedDialog] = useState(false);
    const [showAllDialog, setShowAllDialog] = useState(false);
    const [clearAllRecommendDialog, setClearAllRecommendDialog] = useState(false);
    const [recommendStatus, setIsRecommendStatus] = useState(false);
    const [subCategorySeq, setSubCategorySeq] = useState([]);

    const [searchText, setSearchText] = useState("");
    const [toggleList, setToggleList] = useState(true);
    const [toggleComment, setToggleComment] = useState(false);
    const [displayData, setDisplayData] = useState<any>([]);
    const [productListName, setProductListName] = useState<string>("");
    const [seq, setSeq] = useState<number>(0);
    const [selectedId, setSelectedId] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isbn, setIsbn] = useState("");
    const [isSort, setIsSort] = useState(false);
    const [sortingArray, setSortingArray] = useState<any>([]);
    const [parentId, setParentId] = useState(-1);
    const [iscollect, setIscollect] = useState(false);
    const [showNameConflictDialog, setShowNameConflictDialog] = useState(false);
    const [showParentConflictDialog, setShowParentConflictDialog] = useState(false);
    const [categoryNameConflicts, setCategoryNameConflicts] = useState(Array<CategoryNameConflictResponse>);
    const [categoryParentConflicts, setCategoryParentConflicts] = useState(Array<CategoryParentConflictResponse>);

    const genApi = () => {
        new CategoryControllerApi(genApiConfig())
            .getAllCategoryByOrderUsingGET()
            // .then((res) => {
            //     setDisplayData(res);
            // })
            .then((res) => {
                var allCategory = res;
                var displayCate: Array<any> = [];
                // console.log("allCategory", allCategory)
                new CategoryControllerApi(genApiConfig()).getBookCountUsingGET().then((res: Array<BookCountModel>) => {
                    allCategory.map((cate) => {
                        let temp = res.find((it) => it.categoryId === cate.categoryId);
                        let bookCountTemp = 0;
                        {
                            if (temp !== undefined) {
                                bookCountTemp = temp!.bookCount!;
                            }
                        }
                        displayCate.push({ ...cate, bookCount: bookCountTemp });
                    });
                    console.log("displayCate", displayCate);
                    // setDisplayData(res);
                    setDisplayData(displayCate);
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (categoryNameConflicts.length > 0) {
            setShowNameConflictDialog(true);
        } else {
            setShowNameConflictDialog(false);
        }
    }, [categoryNameConflicts]);

    useEffect(() => {
        console.log("useEffect: categoryParentConflicts", showNameConflictDialog);
        if (categoryParentConflicts.length > 0 && !showNameConflictDialog) {
            setShowParentConflictDialog(true);
        }
    }, [categoryParentConflicts]);

    const handleDialogCallBack = (value: any) => {
        console.log("handleDialogCallBack", value);
        if (value.action === PATH.ADD) {
            setShowAddDialog(true);
        }
        if (value.action === "addSub") {
            setShowAddSubDialog(true);
            setProductListName(value.category.categoryName);
            setSeq(value.category.seq);
            setSelectedId(value.category.categoryId);
        }
        if (value.action === PATH.EDIT) {
            setShowEditDialog(true);
            setProductListName(value.category.categoryName);
            setSeq(value.category.seq);
            setSelectedId(value.category.categoryId);
        }
        if (value.action === PATH.DELETE) {
            setShowDeleteDialog(true);
            setProductListName(value.category.categoryName);
            //setSeq(value.content.seq);
            setSelectedId(value.category.categoryId);
        }
        if (value.action === PATH.UPDATE) {
            setShowRecommedDialog(true);
            setProductListName(value.category.categoryName);
            setSelectedId(value.category.categoryId);
            setIsRecommendStatus(value.category.isRecommend);
        }
        if (value.action === "moveUp") {
            let oldCategoryIdx = displayData.map((x: any) => x.categoryId).indexOf(value.category.categoryId);
            if (oldCategoryIdx === 0) return;
            console.log("oldCategoryIdx", oldCategoryIdx);

            setDisplayData((prevState: any) => {
                let data = [...prevState];
                console.log("data", data);

                let temp = data[oldCategoryIdx - 1];
                data[oldCategoryIdx - 1] = data[oldCategoryIdx];
                data[oldCategoryIdx] = temp;

                return data;
            });
        }
        if (value.action === "moveDown") {
            let oldCategoryIdx = displayData.map((x: any) => x.categoryId).indexOf(value.category.categoryId);
            if (oldCategoryIdx === displayData.length - 1) return;
            console.log("oldCategoryIdx", oldCategoryIdx);

            setDisplayData((prevState: any) => {
                let data = [...prevState];
                console.log("data", data);

                let temp = data[oldCategoryIdx + 1];
                data[oldCategoryIdx + 1] = data[oldCategoryIdx];
                data[oldCategoryIdx] = temp;

                return data;
            });
        }
        if (value.action === "updateSubCategorySeq") {
            setParentId(value.parentId);
            setSubCategorySeq(value.seq);
            return;
        }
        if (value.action === PATH.ALL) {
            setShowAllDialog(true);
            return;
        }
        if (value.action === "clearAll") {
            setClearAllRecommendDialog(true);
            return;
        }
    };

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        genApi();
        new CategoryConflictControllerApi(genApiConfig()).getAllCategoryNameConflictUsingGET().then((res: Array<CategoryNameConflictResponse>) => {
            setCategoryNameConflicts(res);
            new CategoryConflictControllerApi(genApiConfig()).getAllCategoryParentConflictUsingGET().then((res: Array<CategoryParentConflictResponse>) => {
                setCategoryParentConflicts(res);
            });
        });
    }, []);

    const handleSeqConfrim = () => {
        console.log("subCategorySeq", subCategorySeq);
        const x = displayData.map((x: any) => x.categoryId);
        new CategoryControllerApi(genApiConfig())
            .updateParentCategorySeqUsingPOST({
                integerListRequestModel: {
                    integers: displayData.map((x: any) => x.categoryId),
                } as IntegerListRequestModel,
            })
            .then(() => {
                if (parentId > 0) {
                    new CategoryControllerApi(genApiConfig()).updateSubCategorySeqUsingPOST({
                        parentCategoryId: parentId,
                        integerListRequestModel: {
                            integers: subCategorySeq,
                        } as IntegerListRequestModel,
                    });
                }
            })
            .then(() => genApi())
            .finally(() => {
                setIsSort(false);
                setParentId(-1);
            });
    };

    const updateCategoryStatus = () => {
        new CategoryControllerApi(genApiConfig())
            .updateStatusCategoryUsingPOST({ id: selectedId })
            .then((res: any) => {
                setIsLoading(true);
                //console.log(res);
            })
            .finally(() => {
                setShowRecommedDialog(false);
                genApi();
            });
    };
    const handleAddCategory = () => {
        let collect;
        if (iscollect) {
            collect = 1;
        } else {
            collect = 0;
        }

        console.log(productListName, seq);
        new CategoryControllerApi(genApiConfig())
            .addCategoryUsingPOST({
                categoryName: productListName,
                seq: 0,
                isCollection: collect,
            })
            .then((res) => {
                if (res !== undefined) {
                    setProductListName("");
                    setShowAddDialog(false);
                    setIsLoading(true);
                    setIscollect(false);
                }
            })
            .then(() => {
                genApi();
            });
    };

    const handleAddSubCategory = () => {
        console.log(productListName, seq);
        new CategoryControllerApi(genApiConfig())
            .addSubCategoryUsingPOST({
                categoryName: productListName,
                parentCategoryId: selectedId,
                seq: 0,
            })
            .then((res) => {
                if (res !== undefined) {
                    setProductListName("");
                    setShowAddSubDialog(false);
                    setIsLoading(true);
                }
            })
            .then(() => {
                genApi();
            });
    };

    const handleUpdateClick = () => {
        new CategoryControllerApi(genApiConfig())
            .updateCategoryUsingPOST({
                id: selectedId,
                categoryName: productListName,
                seq: seq,
            })
            .then((res) => {
                setIsLoading(true);
                //console.log(res);
            })
            .finally(() => {
                setShowEditDialog(false);
                genApi();
            });
    };

    const handleDeleteClick = (value: any) => {
        new CategoryControllerApi(genApiConfig())
            .deleteCategoryUsingPOST({ categoryId: selectedId })
            .then((res) => setIsLoading(true))
            .finally(() => {
                setShowDeleteDialog(false);
                genApi();
            });
    };

    const updateAllCategoryStatus = (boolean: boolean) => {
        let newArray = [] as number[];
        displayData.map((x: any) => {
            newArray.push(x.categoryId!!);
            x.subCategories?.map((it: any) => {
                newArray.push(it.categoryId!!);
            });
        });

        console.log("newArray", newArray);

        new CategoryControllerApi(genApiConfig())
            .updateAllStatusCategoryUsingPOST({
                integerListRequestModel: { integers: newArray },
                isAll: boolean,
            } as any)
            .then((res) => setIsLoading(true))
            .finally(() => {
                setShowAllDialog(false);
                setClearAllRecommendDialog(false);
                genApi();
            });
    };

    const handleMarc = () => {
        fetchFileFromURLToFile(`${process.env.REACT_APP_BACKEND}/marcreport/downloadMarc`, `Marc_${dateToString(new Date(), "YYYYMMDDHHMMSS")}.mrc`);
    };

    const handleMarcExcel = () => {
        fetchFileFromURLToFile(`${process.env.REACT_APP_BACKEND}/excel/downloadMarcExcel`, `MarcExcel_${dateToString(new Date(), "YYYYMMDDHHMMSS")}.xlsx`);
    };

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
        setDisplayData((prevState: any) => {
            let data = [...prevState];
            const element = data.splice(oldIndex, 1)[0];
            data.splice(newIndex, 0, element);
            return data;
        });
    };

    const handleCateforyConflictCallback = (returnValue: any) => {
        console.log("handleCateforyConflictCallback", returnValue);
        if (returnValue.action === PATH.CANCEL || PATH.SAVE) {
            if (returnValue.conflictType === "name") {
                setShowNameConflictDialog(false);
                if (returnValue.conflictType === "name") {
                    new CategoryConflictControllerApi(genApiConfig()).getAllCategoryParentConflictUsingGET().then((res: Array<CategoryParentConflictResponse>) => {
                        setCategoryParentConflicts(res);
                    });
                }
            } else if (returnValue.conflictType === "parent") {
                setShowParentConflictDialog(false);
            }
        }
        genApi();
    };

    const trigger = (
        <DndTrigger>
            <img src={puller} />
        </DndTrigger>
    );

    return (
        <>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>

                <div className={style.pageNav}>
                    <div className={style.ButtonContainer}>
                        <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
                            平台書目管理
                        </Button>
                    </div>
                    <div> &nbsp; &nbsp;/&nbsp; &nbsp;</div>
                    <div className={style.ButtonContainer}>
                        <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
                            書目清單
                        </Button>
                    </div>
                </div>
            </div>

            <div className={style.PlatformContainer}>
                <div className={style.toggleGroupContainer}>
                    <div className={style.toggleGroup}>
                        {/* <div className={style.toggleSilder}>123</div> */}
                        <Button buttonType={"button"} styleType={"toggleButtonCurrent"} callBack={(value) => console.log(value)}>
                            <Trans>CateList</Trans>
                        </Button>
                        <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.PURCHASED)}>
                            <Trans>PurchasedBook</Trans>
                        </Button>
                    </div>
                </div>

                <>
                    <div className={style.container}>
                        <div className={style.header}>
                            <div className={style.rowSpanItem}>
                                <h1>
                                    <Trans>CateList</Trans>
                                </h1>
                            </div>
                            <div className={style.rowSpancontainer}>
                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleMarc()}>
                                        Marc
                                    </Button>
                                </div>

                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleMarcExcel()}>
                                        MarcExcel
                                    </Button>
                                </div>

                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleDialogCallBack({ action: PATH.ADD })}>
                                        <Trans>AddList</Trans>
                                    </Button>
                                </div>
                                <div className={style.rowSpanItem}>
                                    {isSort ? (
                                        <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleSeqConfrim()}>
                                            確定
                                        </Button>
                                    ) : (
                                        <Button styleType={STYLETYPE_SECOND} callBack={(value) => setIsSort(true)}>
                                            編輯排序
                                        </Button>
                                    )}
                                </div>
                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleDialogCallBack({ action: PATH.ALL })}>
                                        全選
                                    </Button>
                                </div>
                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleDialogCallBack({ action: "clearAll" })}>
                                        清空
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: "8px" }}>
                            <div className={style.tableHeader}>
                                <div className={style.tableHeader2}>
                                    <div className={style.tableHeaderItem}>
                                        <h4>
                                            <Trans>ListName</Trans>
                                        </h4>
                                    </div>

                                    <div style={{ fontSize: "16px", marginLeft: "10px" }} className={style.tableHeaderItem}>
                                        數量
                                    </div>
                                    {process.env.REACT_APP_BACKEND === "https://suepsso.octopus-tech.com" || window.location.host.includes("hkpl") ? (
                                        <div style={{ fontSize: "16px", marginLeft: "10px" }} className={style.tableHeaderItem}>
                                            Collection
                                        </div>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                                {/* <div className={style.tableHeaderItem}></div> */}
                                <div style={{ fontSize: "16px" }} className={style.tableHeaderItem}>
                                    顯示書單
                                </div>
                            </div>
                            {isLoading === false ? (
                                isSort ? (
                                    <>
                                        <DndList lockAxis="y" lockToContainerEdges={true} useDragHandle onSortEnd={onSortEnd}>
                                            {displayData.map((obj: any, idx: number) => (
                                                <DndItem key={`item-${idx}`} index={idx}>
                                                    <TreeMeunCategory
                                                        Collection={true}
                                                        props={obj}
                                                        key={idx}
                                                        callBack={(e: any) => handleDialogCallBack(e)}
                                                        isSorting={isSort}
                                                        trigger={trigger}
                                                    />
                                                </DndItem>
                                            ))}
                                        </DndList>
                                    </>
                                ) : (
                                    <>
                                        {displayData
                                            .sort((a: any, b: any) => {
                                                return a.seq - b.seq;
                                            })
                                            .map((obj: any, idx: number) => (
                                                <TreeMeunCategory Collection={true} props={obj} key={idx} callBack={(e: any) => handleDialogCallBack(e)} isSorting={isSort} />
                                            ))}
                                    </>
                                )
                            ) : (
                                <>
                                    <PageLoading />
                                </>
                            )}
                        </div>
                    </div>
                </>
            </div>

            {/* Add */}
            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showAddDialog} title={"新增書單"}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                    }}
                >
                    <h5>書單名稱</h5>
                    <Input type="text" styleType={"commentInput"} callBack={(value) => setProductListName(value)} />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                    }}
                >
                    <h5>位置</h5>
                    <Input type="text" styleType={"commentInput"} value={seq} callBack={(value) => setSeq(value)} />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                    }}
                >
                    {process.env.REACT_APP_BACKEND === "https://suepsso.octopus-tech.com" ? (
                        <>
                            <h5
                                style={{
                                    width: "60%",
                                }}
                            >
                                <Trans>Collection</Trans>
                            </h5>
                            <div
                                style={{
                                    width: "40%",
                                }}
                            >
                                <Input type="checkbox" styleType={"checkbox"} checked={iscollect} callBack={(value) => setIscollect(value)} />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowAddDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => handleAddCategory()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            {/* AddSub */}
            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showAddSubDialog} title={"新增書單"}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                    }}
                >
                    <h5>書單名稱</h5>
                    <Input type="text" styleType={"commentInput"} callBack={(value) => setProductListName(value)} />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                    }}
                >
                    <h5>位置</h5>
                    <Input type="text" styleType={"commentInput"} value={seq} callBack={(value) => setSeq(value)} />
                </div>

                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowAddSubDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => handleAddSubCategory()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            {/* Edit */}
            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showEditDialog} title={"更新書單"}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                    }}
                >
                    <h5>書單名稱</h5>
                    <Input value={productListName} type="text" styleType={"commentInput"} callBack={(value) => setProductListName(value)} />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                    }}
                >
                    <h5>位置</h5>
                    <Input type="text" styleType={"commentInput"} value={seq} callBack={(value) => setSeq(value)} />
                </div>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowEditDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => handleUpdateClick()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            {/* Delete */}

            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showDeleteDialog} title={"刪除書本資料"}>
                <h5>書單名稱</h5>
                <p>{productListName}</p>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowDeleteDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={(value) => handleDeleteClick(value)}>
                        儲存
                    </Button>
                </div>
            </DialogBox>
            {/* 顯示書單 */}

            <DialogBox
                styleType={STYLETYPE_SECOND}
                showDialog={showRecommedDialog}
                title={recommendStatus ? `${productListName}從推薦書單中移除` : `設定${productListName}為推薦書單`}
            >
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowRecommedDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => updateCategoryStatus()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>
            {/* 顯示書單 */}

            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showRecommedDialog} title={`設定${productListName}為推薦書單`}>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowRecommedDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => updateCategoryStatus()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showAllDialog} title={`設定所有為推薦書單`}>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowAllDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => updateAllCategoryStatus(true)}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            <DialogBox styleType={STYLETYPE_SECOND} showDialog={clearAllRecommendDialog} title={`清空推薦書單`}>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setClearAllRecommendDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => updateAllCategoryStatus(false)}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            <DialogBox styleType={"conflictCategory"} showDialog={showNameConflictDialog} title={`分類名稱衝突`}>
                <CategoryConflicts data={categoryNameConflicts} conflictType="name" dialogCallback={(e: any) => handleCateforyConflictCallback(e)} />
            </DialogBox>
            <DialogBox styleType={"conflictCategory"} showDialog={showParentConflictDialog} title={`主分類衝突`}>
                <CategoryConflicts data={categoryParentConflicts} conflictType="parent" dialogCallback={(e: any) => handleCateforyConflictCallback(e)} />
            </DialogBox>
        </>
    );
}
